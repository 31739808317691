import { getPathBySearchObj } from '../../../../widget/spec/searchHelper';
import {getFilterByPath} from '../../../../widget/spec/filterhelper';
import { getGeoByData } from '../../../../widget/spec/geohelper';
import { absolutePath } from '../../../../widget/spec/pathHelper';

const _propertyTypes = ['single-family', 'condos', 'multi-family', 'land'];
function doSearch({ commit, getters, dispatch, state, rootState }, data) {
    if (!data || !data.path) {
        throw new Error('parameter path is missing.');
    }
    const url = `${rootState.glb.appUrl}api/v/search/`;
    commit('glb/updateRentalInfo', { rentals: getters.msp.filter.rentals }, { root: true });
    commit('glb/updateProgressStatus', 1, { root: true });
    commit('updateIsSearching', true);
    return dispatch('glb/sendRequest', { url: url, data: data, method: 'GET', requestName: 'search', keepSingle: 'search' }, { root: true }).then((res) => {
        if (res && res.data) {
            if(res.data.compliance) {
                commit('glb/updateCompliance', res.data.compliance, { root: true });
            }
            if(res.data.seotitle){
                commit('glb/updateSeoInfo', res.data.seotitle, { root: true });
            }
            if (res.data.metaObj) {
                commit('glb/updateMetaObj', res.data.metaObj, { root: true });
            }

            commit('glb/updateListingCount', res.data.listingCountObj, { root: true });
            commit('updateMapSearchGeo', { data: res.data, glb: rootState.glb });
            commit('updateMapSearchState', { data: res.data, glb: rootState.glb });
            if (res.data.marketSummary) {
                commit('glb/updateMarketSummary', { key: state.geo.cityPath, value: { midPrice: res.data.marketSummary.medianPricePerSqft } }, { root: true });
            }
            commit('updateIsSearching', false);
            commit('glb/updateMlsIds', res.data.mlsIds || [], { root: true });
            commit('glb/updateMenu', { glb: rootState.glb, molang: rootState.molang, geo: state.geo }, { root: true });
            commit('glb/updateSitePhoneNumber', res.data.geoPhone, { root: true });
            return res;
        }
        return Promise.reject(res);
    });
}
function doSearchMapView({ commit, getters, dispatch, rootState }, data) {
    if (!data || !data.path) {
        throw new Error('parameter path is missing.');
    }
    const url = `${rootState.glb.appUrl}api/v/mapview/`;
    return dispatch('glb/sendRequest', { url: url, data: data, method: 'GET', requestName: 'search', keepSingle: 'search' }, { root: true }).then((res) => {
        if (res && res.data) {
            commit('updateMapListings', { data: res.data, glb: rootState.glb });
            return res;
        }
    });
}

function getListingByUrl({ commit, getters, dispatch, rootState }, searchUrl) {
    const url = `${rootState.glb.appUrl}api/v/search/`;
    const data = {
        path: searchUrl,
    };
    return dispatch('glb/sendRequest', { url: url, data: data, method: 'GET', requestName: 'search' }, { root: true }).then((res) => {
        if (res && res.data) {
            commit('updateNearbyData', res.data.listings);
            return res.data;
        }
        return Promise.reject(res);
    });
}

function _getSearchUrl(state, options) {
    let geo = state.geo;
    let filter = state.filter;

    if (options.cleanCoords === undefined) {
        options.cleanCoords = state.currentSearchMode !== state.searchMode.coords;
    }

    if (options.geo) {
        geo = options.geo;
    }

    if (options.filter) {
        filter = options.filter;
    }

    if (options.searchTerm && options.searchTerm.searchSuggestion) {
        geo = getGeoByData(options.searchTerm.searchSuggestion);
    }

    if (options.searchTerm && options.searchTerm.extraPath) {
        let newFilter = getFilterByPath(options.searchTerm.extraPath);
        Object.assign(filter, newFilter);
    }

    let coords = options.coords;
    if (coords && coords.pointNE && coords.pointSW) {
        if (coords.boundaryFilter !== undefined) {
            filter.boundary = !!coords.boundaryFilter;
        }
        filter.coordinates = {
            ne: coords.pointNE,
            sw: coords.pointSW,
        };
        if (coords.zoom) {
            filter.coordinates.zoom = coords.zoom;
        }
    } else if (options.cleanCoords) {
        filter.boundary = false;
        filter.coordinates = null;
    }

    if (!filter.coordinates && geo.lat && geo.lng) {
        filter.center = {
            lat: geo.lat,
            lng: geo.lng,
        };
    }

    let path = getPathBySearchObj(geo, filter);
    return absolutePath(path);
}

function applySearchStateByUrl({ state, commit }, passUrl) {
    var url = passUrl;
    commit('updateIsNewSearch', true);
    state.paginationMode = 0;
    state.filter.pageIndex = 1;
    commit('updateSearchTrigger', state.searchTriggerMode.MapSearchPageLoad);
    return url;
}

function applyPagination({ commit, state }, { pageIndex, paginationMode }) {
    if (pageIndex < 1 || pageIndex > state.pageCount) {
        return '';
    }
    commit('updateIsNewSearch', paginationMode !== 1);
    commit('updateFilterData', { pageIndex: pageIndex });

    if (paginationMode !== undefined) {
        state.paginationMode = paginationMode;
    } else {
        state.paginationMode = pageIndex - state.displayPageIndex;
    }
    commit('updateSearchTrigger', state.searchTriggerMode.MapSearchPagination);
    return _getSearchUrl(state, { cleanCoords: false });
}

function applyFilterData({ commit, state }, filters) {
    commit('updateIsNewSearch', true);
    if (filters) {
        filters.pageIndex = filters.pageIndex || 1;
        commit('updateFilterData', filters);
    }
    state.paginationMode = 0;
    commit('updateSearchTrigger', state.searchTriggerMode.MapSearchByFilter);
    var cleanCoords = false;
    if (filters.schoolId) {
        cleanCoords = true;
    }
    return _getSearchUrl(state, { cleanCoords: cleanCoords });
}

function applyTextSearch({ commit, state }, searchTerm) {
    commit('updateIsNewSearch', true);
    let schoolId = '';
    let school;
    if (searchTerm && searchTerm.searchSuggestion && searchTerm.searchSuggestion.schoolId) {
        schoolId = searchTerm.searchSuggestion.schoolId;
        if (searchTerm.searchSuggestion.schoolBoundaryId) {
            school = {
                boundaryId: searchTerm.searchSuggestion.schoolBoundaryId,
            };
            if (searchTerm.searchSuggestion.schoolName) {
                school.name = searchTerm.searchSuggestion.schoolName;
            }
            if (searchTerm.searchSuggestion.lat && searchTerm.searchSuggestion.lng) {
                school.location = {
                    lat: searchTerm.searchSuggestion.lat,
                    lng: searchTerm.searchSuggestion.lng,
                };
            }
        }
    }
    commit('updateFilterData', {
        pageIndex: 1,
        schoolId: schoolId,
        school: school,
        propertyType: searchTerm.extraPath ? [searchTerm.extraPath.replace(/\/$/, '')] : state.filterType && _propertyTypes.includes(state.filterType.url) ? [state.filterType.url] : [],
    });
    state.paginationMode = 0;
    commit('updateSearchMode', state.searchMode.default);
    commit('updateSearchTrigger', state.searchTriggerMode.MapSearchByTextbox);
    return _getSearchUrl(state, { searchTerm: searchTerm, cleanCoords: true });
}
function applyGeo({ commit, state, getters }, latlng) {
    if (!latlng) {
        return '';
    }
    commit('updateIsNewSearch', true);
    commit('updateFilterData', { pageIndex: 1 });
    state.paginationMode = 0;
    commit('updateSearchMode', state.searchMode.forsale);
    commit('updateGeoLocationState', latlng);
    commit('updateSearchTrigger', state.searchTriggerMode.MapSearchByTextbox);
    return _getSearchUrl(state, { geo: latlng, cleanCoords: true });
}
function applyCoordinates({ commit, state }, { newSearch, ...coords }) {
    commit('updateIsNewSearch', newSearch || false);
    commit('updateFilterData', { pageIndex: 1 });
    state.paginationMode = 0;
    if (state.currentSearchMode === state.searchMode.default) {
        commit('updateSearchMode', state.searchMode.coords);
    }
    commit('updateSearchTrigger', state.searchTriggerMode.MapSearchByCoordinates);
    return _getSearchUrl(state, { coords: coords, cleanCoords: true });
}

function schoolsNearProperty({ commit, dispatch, state, rootState }, data) {
    const url = `${rootState.glb.appUrl}api/v/property/schoolsNearProperty/`;
    return dispatch('glb/sendRequest', { url: url, data: data, method: 'GET' }, { root: true }).then((res) => {
        if (res) {
            commit('updateSchoolData', res.data || {});
        }
    });
}

function getPOIByBoundaryIndexId({ commit, dispatch, rootState }, data) {
    if (!data || !data.boundaryIndexId) {
        return Promise.resolve(null);
    }
    const url = `${rootState.glb.appUrl}api/v/property/msppoi/`;
    return dispatch('glb/sendRequest', { url: url, data: data, method: 'GET' }, { root: true }).then((res) => {
        if (res) {
            commit('updatePOIByBoundaryIndexId', (res.data && res.data.list) || res.data || []);
            return res;
        }
        return Promise.reject(res);
    });
}

function getPOIByCoordinates({ commit, dispatch, rootState }, data) {
    if (!data || !data.ne || !data.sw) {
        return Promise.resolve(null);
    }
    let coordinates = `${data.ne.lat},${data.ne.lng},${data.sw.lat},${data.sw.lng}`;
    const url = `${rootState.glb.appUrl}api/v/property/msppoi/bbox/`;
    return dispatch('glb/sendRequest', { url: url, data: { coordinates }, method: 'GET' }, { root: true }).then((res) => {
        if (res) {
            commit('updatePOIByCoordinates', (res.data && res.data.list) || res.data || []);
            return res;
        }
        return Promise.reject(res);
    });
}

function getPropertyInfoById({ commit, dispatch, rootState }, data) {
    if (!(data.propertyIds && data.propertyIds.length)) {
        return null;
    }
    const url = `${rootState.glb.appUrl}api/v/property/listing/`;
    return dispatch('glb/sendRequest', { url: url, data: data, method: 'GET' }, { root: true }).then((res) => {
        if (res.data) {
            return res.data;
        }
    });
}

function getWeatherSummaryData({ commit, dispatch, rootState }, data) {
    if (!data.cityId) {
        return null;
    }
    const url = `${rootState.glb.appUrl}api/v/property/weathersummary/`;
    return dispatch('glb/sendRequest', { url: url, data: data, method: 'GET' }, { root: true }).then((res) => {
        if (res.data) {
            return res.data;
        }
    });
}

function getHottestArticles({ commit, dispatch, rootState }, data) {
    if (!data.cityId) {
        return null;
    }
    const url = `${rootState.glb.appUrl}api/v/property/hottestarticle/`;
    return dispatch('glb/sendRequest', { url: url, data: data, method: 'GET' }, { root: true }).then((res) => {
        if (res.data) {
            return res.data;
        }
    });
}

function changeViewStatus({ commit, dispatch, rootState }, view) {

    commit('updateViewStatus', view);
}

export default {
    getListingByUrl,
    applySearchStateByUrl,
    doSearch,
    applyFilterData,
    applyPagination,
    applyTextSearch,
    applyCoordinates,
    applyGeo,
    schoolsNearProperty,
    getPOIByBoundaryIndexId,
    getPOIByCoordinates,
    doSearchMapView,
    getPropertyInfoById,
    getWeatherSummaryData,
    getHottestArticles,
    changeViewStatus,
};
