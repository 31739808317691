
var googleLoginAPIJSLoaded = 0;
var appleLoginAPIJSLoaded = 0;
var googleOneTapJSLoaded = 0;
export default{
    loadAppleAuth(){
        return new Promise((resolve) => {
            if(appleLoginAPIJSLoaded === 0){
                appleLoginAPIJSLoaded = 1;
                $.preload({files: ['https://appleid.cdn-apple.com/appleauth/static/jsapi/appleid/1/en_US/appleid.auth.js'], type: 'js'}).then(function() {
                    appleLoginAPIJSLoaded = 2;
                    $(document).trigger('loadappleLogin');
                    resolve();
                });
            }else if(appleLoginAPIJSLoaded === 1){
                $(document).one('loadappleLogin', function(){
                    resolve();
                });
            } else if(appleLoginAPIJSLoaded === 2){
                resolve();
            }
        });
    },
    loadGoogleOneTapSignin(){
        return new Promise((resolve) => {
            if(googleOneTapJSLoaded === 0){
                googleOneTapJSLoaded = 1;
                $.preload({files: ['https://accounts.google.com/gsi/client'], type: 'js'}).then(function() {
                    googleOneTapJSLoaded = 2;
                    $(document).trigger('loadappleLogin');
                    resolve();
                });
            }else if(googleOneTapJSLoaded === 1){
                $(document).one('loadappleLogin', function(){
                    resolve();
                });
            } else if(googleOneTapJSLoaded === 2){
                resolve();
            }
        });
    }
}