import getGeo from '../glb/geo';

export default function () {
    return {
        geo: getGeo(),
        type: 'agents',
        script: null,
        formData: null,
    };
}
