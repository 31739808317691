const prefix = 'm_';
function setItem(key, value) {
    let _key = prefix + key;
    if (value === undefined || value === null) {
        removeItem(key);
        return;
    }

    if (typeof value === 'object') {
        if (value instanceof Date) {
            window.localStorage.setItem(_key + '_s', value);
        } else {
            window.localStorage.setItem(_key, JSON.stringify(value));
        }
    } else {
        if (typeof value === 'string') {
            window.localStorage.setItem(_key + '_s', value);
        } else {
            window.localStorage.setItem(_key, value);
        }
    }
}
function getItem(key) {
    let _key = prefix + key;
    let value = window.localStorage.getItem(_key + '_s');
    if (typeof value === 'string') {
        return value;
    }
    value = window.localStorage.getItem(_key);
    if (value) {
        try {
            return JSON.parse(value);
        } catch (e) {
			sendNodeLog(`client-error-parse`, window.location.href, 0, e);
            return value;
        }
    }
    return value;
}

function removeItem(key) {
    let _key = prefix + key;
    window.localStorage.removeItem(_key + '_s');
    window.localStorage.removeItem(_key);
}
export default {
    localStorage: null,
    initalStorage: function () {
        if (window.localStorage) {
            var movotoLocalStorage = window.localStorage.getItem('movotoLocalStorage');
            if(movotoLocalStorage){
                try {
                    movotoLocalStorage = JSON.parse(movotoLocalStorage);
                    Object.keys(movotoLocalStorage).forEach((key)=> {
                        let value = movotoLocalStorage[key];
                        setItem(key, value);
                    });
                }catch(e){
                    movotoLocalStorage = null;
                }
                window.localStorage.removeItem('movotoLocalStorage')
            }
        }else{
            sendNodeLog(`client-error-storage`, window.location.href, 0, e);
        }
    },
    setStorage: function (key, value) {
        if (window.localStorage) {
            setItem(key, value);
        } else {
            if (!$.localStorage) {
                $.localStorage = {};
            }
            $.localStorage[key] = value;
        }
    },
    getStorage: function (key) {
        if (window.localStorage) {
            return getItem(key);
        } else {
            if (!$.localStorage) {
                $.localStorage = {};
            }
            return $.localStorage[key];
        }
    },
    removeStorage: function (key) {
        if (window.localStorage) {
            removeItem(key);
        } else {
            if (!$.localStorage) {
                $.localStorage = {};
                return;
            }
            delete $.localStorage[key];
        }
    },
    setSessionStorage: function(key, value) {
        if (window.sessionStorage) {
            window.sessionStorage.setItem(key, value);
        }
    },
    getSessionStorage: function (key) {
        if (window.sessionStorage) {
            return window.sessionStorage.getItem(key);
        }
    }
};
