export default (app) => {
    app.directive('scroll', {
        beforeMount(el, binding) {
            el.addEventListener('click', (e) => {
                e.preventDefault();
                if (binding.value) {
                    $.scrollTo(binding.value, binding.arg);
                } else {
                    const hash = e.target.getAttribute('href');
                    $.scrollTo(hash.replace('#', ''));
                    if ('replaceState' in history) {
                        history.replaceState(undefined, undefined, hash);
                    }
                }
            });
        },
    });
};
