export default {
  "ContactUs": "Contact Us",
  "send": "Send",
  "neighborhoodguide": "Neighborhood Guide",
  "markettrend": "Market Trends",
  "schools": "Schools",
  "realestatetips": "Real Estate Tips",
  "aprTip": "APR takes the interest rate of the loan and adds it to certain fees paid by the borrower at closing, including the upfront cost Bankrate displays on each offer. APR shows which loan is less expensive over the full term of the loan. The APR shown here does not include primary mortgage insurance(if applicable) and other fees charged by your lender and may be different that the APR disclosed by your lender on the loan estimate and closing disclosure.",
  "rateTip": "The Rate is the amount you'll pay each year to borrow the money, expressed as a percentage",
  "repaymentTip": "Monthly payment shown includes both principal and interest payments. It does not include private mortgage insurance(PMI), which your lender may require if your down payment is less than 20 percent of your purchase price. PMI usually costs between 0.5 and 1 percent of the original loan amount.",
  "showAll": "Show All",
  "showLess": "Show Less",
  "breadcrumb": {
    "tooltipTitle": "$value Homes for Sale",
    "tooltipTitleRent": "$value Homes for Rent"
  },
  "enquiryType": {
    "placeholder": "How can we best serve you?",
    "one": "Contact My Assigned Agent",
    "two": "Connect with an Agent",
    "three": "Rental Support",
    "four": "General Inquiry(sales/jobs/etc.)",
    "five": "Report Incorrect Data",
    "six": "Remove Property Photos",
    "seven": "Accessibility Feedback",
    "eight": "Other"
  },
  "interestOptions": {
    "placeholder": "Select an option",
    "one": "Just Browsing",
    "two": "Selling and Buying",
    "three": "Buying",
    "four": "Selling",
    "five": "Renting",
    "six": "Other",
    "notsure": "I’m not sure",
  },
  "sitemap": {
    "h1": {
      "state": "$state Real Estate and Homes for Sale",
      "letter": "$state Real Estate - Cities Beginning with $letter"
    },
    "mainContent": {
      "relatedArticles": "Related Articles",
      "topCity": "Popular Cities in $state",
      "topCounty": "Popular Counties in $state",
      "cityLink": "$city Real Estate",
      "stateLinks": "$state Real Estate Links",
      "homesLink": "View Homes For Sale in $state",
      "homesLinkRent": "View Homes For Rent in $state",
      "SFHLink": "Houses",
      "condoLink": "Condo/Townhouse",
      "apartmentLink": "Apartments",
      "houseLink": "Houses",
      "rentLink" : "Rentals",
      "foreclosedLink": "Foreclosure/Shortsale",
      "openHouseLink": "Open Houses",
      "reducedLink": "Reduced Price",
      "soldLink": "Recently Sold",
      "newListing": "$state New Listings",
      "county": "$countyName County",
      "countyForRent": "$countyName County Rentals"
    }
  },
  "homeOwner": {
    "l1": "KNOW WHAT YOUR HOME IS WORTH",
    "h1": "Monitor Your Home's Market Value",
    "desc1": "Receive a free, data-driven valuation of your home based on up-to-date market insights and comparable home sales.",
    "l2": "MORTGAGE AND EQUITY TRACKING",
    "h2": "Track Equity and Mortgage Balances",
    "desc2": "Track your home equity, optimize your mortgage, and learn how to build long term wealth.",
    "l3": "TOOLS FOR SUCCESSFUL HOMEOWNERSHIP",
    "h3": "Get Personalized Maintenance Alerts",
    "desc3": "Receive personalized maintenance checklists and get connected with top-rated home professionals.",
    "l4": "PLAN FOR THE FUTURE",
    "h4": "Unlock Expert Guidance for Every Step",
    "desc4": "Personalized suggestions to help you save on property taxes, insurance, and mortgages."
  }
}