import mapper from '../mapper/index.js';
function updateAgents(state, data) {
    state.agents = data || [];
}

function updateAgentSearch(state, {data, glb}) {
    state.geo = mapper.geo(data.geo, glb.rentals);
    state.pageImage = data.pageImage;
    state.snapshot = data.snapshot;
    if(data.neighborhoods && data.neighborhoods.length > 0) {
        state.neighborhoods = data.neighborhoods;
    }
    if(data.zipcodes && data.zipcodes.length > 0) {
        state.zipcodes = data.zipcodes;
    }
    if(data.cities && data.cities.length > 0) {
        state.cities = data.cities;
    }
    if(data.counties && data.counties.length > 0) {
        state.counties = data.counties;
    }
}

function updateBrokerage(state, {data, glb}) {
    state.geo = mapper.geo(data.geo, glb.rentals);
    state.officeImage = data.officeImage;
    state.displayName = data.displayName;
    state.phone = data.phone;
    state.url = data.url;
    state.googleMapUrl = data.googleMapUrl;
    state.agents = data.agents || [];
    if(data.neighborhoods && data.neighborhoods.length > 0) {
        state.neighborhoods = data.neighborhoods;
    }
    if(data.zipcodes && data.zipcodes.length > 0) {
        state.zipcodes = data.zipcodes;
    }
    if(data.cities && data.cities.length > 0) {
        state.cities = data.cities;
    }
    if(data.counties && data.counties.length > 0) {
        state.counties = data.counties;
    }
}
export default {
    updateAgents,
    updateAgentSearch,
    updateBrokerage
};
