import glb from './glb/index.js';
import msp from './msp/index.js';
import dpp from './dpp/index.js';
import dsp from './dsp/index.js';
import agents from './agents/index.js';
import dap from './dap/index.js';
import home from './home/index.js';
import guide from './guide/index.js';
import sitemap from './sitemap/index.js';
import schools from './schools/index.js';
import about from './about/index.js';
import survey from './survey/index.js';
import surveyMortgage from './surveymortgage/index.js';
import mymovoto from './mymovoto/index.js';
import geolanding from './geolanding/index.js';
import sell from './sell/index.js';
import neighborhood from './neighborhood/index.js';

import { createStore } from 'vuex';

const isSSR = typeof window === 'undefined';

export default () => {
    return createStore({
        modules: getModules(),
        devtools: !isSSR,
    });
};

export function getModules() {
    return {
        glb,
        msp,
        dpp,
        home,
        about,
        survey,
        guide,
        sitemap,
        schools,
        dsp,
        agents,
        dap,
        mymovoto,
        geolanding,
        sell,
        surveyMortgage,
        neighborhood,
    };
}
