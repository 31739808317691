import getGeo from '../glb/geo';

export default function () {
    return {
        geo: getGeo(),
        homeId: '',
        fullUserInfo: {},
        settings: [],
        subscribeInfo: {},
        sortBy: '',
        favoriteList: {},
        savedSearch: {},
        savedSearchByIdData: {},
        isProfileUpdated: false,
    };
}
