import urlMixin from '../../mixins/url.js';
import officeInfo from '../glb/data.brokerage.js';

export default function ({ glb = {}, geo = {}, molang }) {
    //Buyer Menu
    var buyer = {
        text: molang('header.Buy'),
        list: [
            {
                text: molang('header.GetPreApproved'),
                href: glb.appUrl + 'preapproval/',
                ssr: true,
            },
            {
                text: molang('header.GetInsuranceQuotes'),
                href: glb.appUrl + 'insurance/' + (geo && geo.zipcode ? `?zip=${geo.zipcode}` : ''),
                ssr: false,
            },
            {
                text: molang('header.HomeBuyingChecklist'),
                href: glb.appUrl + 'blog/home-buyer-checklist-11-basic-steps-to-buying-a-home/',
                ssr: true,
            },
            {
                text: molang('header.veteranResources'),
                href: glb.appUrl + 'veterans/',
                ssr: true,
            },
        ],
    };

    //Rental Menu
    var rental = {
        text: molang('header.Rent'),
        class: 'rent',
    };

    if (geo.state) {
        const templateObj = {
            condo: {
                template: 'header.ApartmentsForSale',
            },
            singleFamily: {
                template: 'header.HousesForSale',
            },
            condoRent: {
                template: 'header.ApartmentsForRent',
            },
            singleFamilyRent: {
                template: 'header.HousesForRent',
            },
            multiFamily: {
                template: 'header.multiFamily',
            },
            land: {
                template: 'header.land',
            },
            rentalHomes: {
                template: 'header.RentalHomes',
            },
            forSale: {
                template: 'header.HomesforSale',
            },
            openHouse: {
                template: 'header.OpenHouses',
            },
            priceReduce: {
                template: 'header.RecentPriceReductions',
            },
        };
        const isTypeAddress = geo.geoType === 'ADDRESS';
        const baseName = (isTypeAddress ? geo.city || geo.zipcode : geo.zipcode || geo.city || geo.county) || geo.state;

        Object.keys(templateObj).forEach((item) => {
            templateObj[item].text = molang(templateObj[item].template, { baseName });
        });

        if (geo.zipcode || geo.city || geo.county) {
            let urlType = isTypeAddress ? (geo.city ? 'city' : 'zipcode') : geo.zipcode ? 'zipcode' : geo.county && !geo.city ? 'county' : 'city';
            let mapSearchBaseAndFilteredUrl = urlMixin.methods.generateMapSearchUrl(glb.appUrl, {
                    type: urlType,
                    cityName: geo.city,
                    stateCode: geo.stateCode,
                    zipcode: geo.zipcode,
                    countyCode: geo.countyCode,
                    filterArray: ['singleFamily', 'condo', 'multiFamily', 'land'],
                    listingCountObj: glb.listingCountObj,
                }),
                filterLinks = ['forSale', 'singleFamily', 'condo', 'multiFamily', 'land'],
                mapSearchBaseAndFilteredUrlForRent = urlMixin.methods.generateMapSearchUrl(glb.appUrl, {
                    type: urlType,
                    cityName: geo.city,
                    stateCode: geo.stateCode,
                    zipcode: geo.zipcode,
                    countyCode: geo.countyCode,
                    filterArray: ['singleFamily', 'condo'],
                    listingCountObj: glb.listingCountObj,
                    rentals: true,
                }),
                rentalFilterLinks = ['rentalHomes', 'singleFamily', 'condo'];

            buyer.list = [
                ...filterLinks.reduce((acc, item) => {
                    if (item === 'forSale' ? mapSearchBaseAndFilteredUrl.baseCount : mapSearchBaseAndFilteredUrl.filteredUrlObj[`${item}Count`]) {
                        acc.push({
                            text: templateObj[item].text,
                            href: item === 'forSale' ? mapSearchBaseAndFilteredUrl.base : mapSearchBaseAndFilteredUrl.filteredUrlObj[item],
                            ssr: false,
                        });
                    }
                    return acc;
                }, []),
                ...buyer.list,
            ];

            rental.list = [
                ...rentalFilterLinks.map((item) => {
                    return {
                        text: templateObj[item + (['singleFamily', 'condo'].includes(item) ? 'Rent' : '')].text,
                        href: mapSearchBaseAndFilteredUrlForRent.filteredUrlObj[item] || mapSearchBaseAndFilteredUrlForRent.base,
                        ssr: false,
                    };
                }),
            ];
        } else {
            buyer.list = [
                {
                    text: templateObj.forSale.text,
                    href: glb.appUrl + geo.statePath,
                    ssr: false,
                },
                {
                    text: templateObj.openHouse.text,
                    href: glb.appUrl + geo.statePath + 'open-houses/',
                    ssr: false,
                },
                {
                    text: templateObj.priceReduce.text,
                    href: glb.appUrl + geo.statePath + 'reduced-30/',
                    ssr: false,
                },
            ].concat(buyer.list);

            rental.list = [
                {
                    text: templateObj.rentalHomes.text,
                    href: glb.appUrl + geo.statePath + 'rentals/',
                    ssr: false,
                },
                {
                    text: templateObj.singleFamilyRent.text,
                    href: glb.appUrl + geo.statePath + 'rentals/single-family/',
                    ssr: false,
                },
                {
                    text: templateObj.condoRent.text,
                    href: glb.appUrl + geo.statePath + 'rentals/condos/',
                    ssr: false,
                },
            ];
        }
        buyer.list = [
            {
                text: molang('header.HomesForSaleNearMe'),
                href: glb.appUrl + 'for-sale/',
                ssr: true,
            },
        ].concat(buyer.list);

        rental.list = [
            {
                text: molang('header.HomesForRentNearMe'),
                href: glb.appUrl + 'rentals/',
                ssr: true,
            },
        ].concat(rental.list);
    } else {
        rental.list = [
            {
                text: molang('header.HomesForRentNearMe'),
                href: glb.appUrl + 'rentals/',
                ssr: true,
            },
            {
                text: molang('header.HousesForRentNearMe'),
                href: glb.appUrl + 'rentals/single-family/',
                ssr: true,
            },
            {
                text: molang('header.ApartmentsForRentNearMe'),
                href: glb.appUrl + 'rentals/condos/',
                ssr: true,
            },
        ];
    }
    // Mortgages Menu
    const mortgage = {
        text: molang('header.Mortgage'),
        href: glb.appUrl + 'mortgages/',
        list: [
            {
                text: molang('header.MortgageLoan'),
                href: glb.appUrl + 'mortgages/',
                ssr: true,
            },
            {
                text: molang('header.GetPreApproved'),
                href: glb.appUrl + 'preapproval/',
                ssr: true,
            },
            {
                text: molang('header.BuyingPower'),
                href: glb.appUrl + 'mortgages/buyingpower/',
                ssr: true,
            },
            {
                text: molang('header.MortgageCalculator'),
                href: glb.appUrl + 'mortgages/calculator/',
                ssr: true,
            },
            {
                text: molang('header.MortgageRates'),
                href: glb.appUrl + 'mortgages/rates/',
                ssr: true,
            },
            {
                text: molang('header.RefinanceRates'),
                href: glb.appUrl + 'mortgages/refinance/',
                ssr: true,
            },
            {
                text: molang('header.veteranMortgage'),
                href: glb.appUrl + 'veterans/',
                ssr: true,
            },
        ],
    };
    //Seller Menu
    var seller = {
        text: molang('header.Sell'),
        list: [
            {
                text: molang('header.HomeSaleCalculator'),
                href: glb.appUrl + 'home-sale-proceeds-calculator/',
                ssr: true,
            },
            {
                text: molang('header.GetaHomeValuation'),
                href: glb.appUrl + 'what-is-your-home-worth/',
                ssr: true,
            },
            {
                text: molang('header.ownerDashBoard'),
                href: glb.appUrl + 'homeowner/',
                ssr: true,
            },
            {
                text: molang('header.HomeRepairServiceProviders'),
                href: glb.appUrl + 'homeservices/',
                ssr: true,
            },
        ],
    };
    seller.list.unshift({
        text: molang('header.CashOffer'),
        href: glb.appUrl + 'sell-fast/',
        ssr: true,
    });
    if (geo.stateCode && geo.cityCode && geo.city) {
        seller.list.unshift({
            text: molang('header.sellHome') + ' in ' + geo.city,
            href: glb.appUrl + 'sell/' + geo.cityCode + '-' + geo.stateCode + '/',
            ssr: true,
        });
    }
    seller.list.unshift({
        text: molang('header.sellHome'),
        href: glb.appUrl + 'sell/',
        ssr: true,
    });
    //Agent Menu
    var agent = {
        text: glb.agent && glb.agent.id ? molang('header.myAgent') : molang('header.Agent'),
        href: glb.agent && glb.agent.id ? glb.appUrl + 'my-movoto/myagent/' : glb.appUrl + 'agents/',
        list: glb.agent && glb.agent.id ? null : [],
        class: 'agent',
    };
    if (!glb.agent || !glb.agent.id) {
        agent.list.push({
            text: molang('header.MeetanAgent'),
            href: glb.appUrl + 'agents/',
            ssr: true,
        });
        if (geo.state) {
            if (geo.city) {
                agent.list.push({
                    text: molang('header.AgentsSearch', { baseName: geo.city }),
                    href: glb.appUrl + `agents/${geo.cityCode}-${geo.stateCode}/`,
                    ssr: false,
                });
                /*agent.list.push({
                    text: molang('header.BecomeaPartnerAgent'),
                    href: glb.appUrl + 'usa/?utm_source=usatoday&utm_medium=newspaper&utm_campaign=moving_in'
                });*/
            } else if (geo.county) {
                agent.list.push({
                    text: molang('header.AgentsSearch', { baseName: geo.county }),
                    href: glb.appUrl + `agents/${geo.countyCode}-${geo.stateCode}/`,
                    ssr: false,
                });
            } else {
                agent.list.push({
                    text: molang('header.AgentsSearch', { baseName: geo.state }),
                    href: glb.appUrl + `sitemap/agents/${geo.state}/`,
                    ssr: false,
                });
            }
        }
        var localOffice = officeInfo[`${geo.cityCode}-${geo.stateCode}`];
        if (localOffice) {
            agent.list.push({
                text: molang('header.brokerageoffices', { baseName: geo.city }),
                href: glb.appUrl + `brokerage/${geo.cityCode}-${geo.stateCode}/`,
                ssr: false,
            });
        }
        agent.list.push({
            text: molang('header.CreateanAgentProfile'),
            href: 'https://agents.movoto.com/platforms/',
            ssr: true,
            rel: 'nofollow',
        });
    }
    return [buyer, seller, rental, mortgage, agent];
}
