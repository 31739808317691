<!-- SplitProvider.vue -->
<template>
  <slot />
</template>

<script>

import {defineComponent, ref, provide, onMounted, onUnmounted, watch, inject } from "vue";
import { SplitFactory, InLocalStorage } from "@splitsoftware/splitio-browserjs";
import {
  SPLIT_CACHE_PREFIX,
  memoizedFetchTreatment,
  fetchCachedClientTreatments,
  formatSplitsForGA4,
  getOverridenExperiments,
} from './utils.js';
import { useStore } from "vuex";

const isSSR = typeof window === 'undefined';

export default defineComponent({
  name: "SplitProvider",
  props: {
    apiKey: { type: String, required: true },
    userKey: { type: String, required: true },
    readyTimeout: { type: Number, default: 1.5 },
  },
  setup(props) {
    const $eventBus = inject('$eventBus');
    const store = useStore(); // Access the Vuex store
    const splits = store.getters['glb/getSplits']; // Access the splits getter

    // Refs provided to application
    const splitClient = ref(null);
    const splitIsReady = ref(false);
    const currentUserKey = ref(props.userKey);

    // Create Split instance and client
    const createSplitFactory = (userKey) => {
      return SplitFactory({
        core: {
          authorizationKey: props.apiKey,
          key: userKey,
        },
        startup: {
          readyTimeout: props.readyTimeout,
        },
        impressionListener: {
          logImpression: ({ impression: { feature, treatment } }) => {
            const eventLabel = `${feature}:${treatment}`;
            if(treatment !== "control" && treatment !== "default") {
              $eventBus.$emit(
                  'ga4',
                  { en: 'split_impression', el: eventLabel },
                  {pageData: { split_treatments: formatSplitsForGA4(splits, fetchCachedClientTreatments())}}
              );
            }
          },
        },
        storage: InLocalStorage({ prefix: SPLIT_CACHE_PREFIX }),
        sync: {
          splitFilters: [{ type: 'bySet', values: ['movoto_cw_frontend'] }],
        },
      });
    };

    const setupEventListeners = (client) => {
      const events = {
        [client.Event.SDK_READY]: () => (splitIsReady.value = true),
        [client.Event.SDK_READY_FROM_CACHE]: () => (splitIsReady.value = true),
        [client.Event.SDK_READY_TIMED_OUT]: () => console.error("Split SDK Ready Timed Out"),
        [client.Event.SDK_UPDATE]: () => console.error("Split SDK Updated"),
      };

      Object.entries(events).forEach(([event, handler]) => client.on(event, handler));
    };

    const initializeSplit = (userKey) => {

      if (isSSR) return; // Exit early if SSR

      const splitFactory = createSplitFactory(userKey);
      splitClient.value = splitFactory.client();

      setupEventListeners(splitClient.value);
    };

    // Recreate split whenever user key is changed
    const setUserKey = (newUserKey) => {
      if (newUserKey !== currentUserKey.value) {
        if (splitClient.value) {
          splitIsReady.value = false;
          splitClient.value.destroy(); // Clean up the old client
        }
        currentUserKey.value = newUserKey;
        initializeSplit(newUserKey); // Initialize a new client with the new user key
      }
    };

    const fetchTreatments = async (featureFlags, options = {}) => {
      const defaultOptions = { attributes: {}, withConfig: false, ...options };
      const results = await Promise.all(
          featureFlags.map((featureFlag) => {
            /* If the attributes object has individual attributes for each featureFlag, otherwise use them all for all feature flags */
            const attributes = defaultOptions.attributes.hasOwnProperty(featureFlag) ? defaultOptions.attributes[featureFlag] : defaultOptions.attributes;
            return  memoizedFetchTreatment(splitClient.value, splitIsReady.value, currentUserKey.value, featureFlag, { ...defaultOptions, attributes: attributes});
          })
      );
      const treatments = results.reduce((acc, curr) => ({ ...acc, ...curr }), {});
      const overridenTreatments = getOverridenExperiments(treatments)
      return { ...treatments, ...overridenTreatments };
    };

    const fetchServerTreatments = (featureFlags = [], withConfig = false) => {
      return featureFlags.map((featureFlag) => {
        const treatment = (splits && splits[featureFlag]) || 'control';
        const config = withConfig ? splits[`${featureFlag}_config`] || {} : undefined;
        return {
          [featureFlag]: withConfig ? { treatment, config } : treatment,
        };
      }).reduce((acc, curr) => ({ ...acc, ...curr }), {});
    };

    onUnmounted(() => splitClient.value?.destroy()); // Clean up SDK on component unmount

    watch(() => props.userKey, setUserKey);

    /* Initialize Split as early as possible */
    initializeSplit(props.userKey)

    provide("split", {
      client: splitClient,
      key: currentUserKey,
      ready: splitIsReady,
      fetchTreatments,
      fetchServerTreatments,
      fetchCachedClientTreatments
    });
  },
});
</script>