const mymovoto = (state) => state;

const getFullUserInfo = (state) => state.fullUserInfo;
const getSettings = (state) => state.settings;
const getSubscribeInfo = (state) => state.subscribeInfo;
const getSortBy = (state) => state.sortBy;
const getFavoriteList = (state) => state.favoriteList;
const getUserSavedSearchList = (state) => state.savedSearch;
const getProfileUpdated = (state) => state.isProfileUpdated;
const getHomeId = (state) => {
    return state.homeId;
};

export default {
    mymovoto,
    getFullUserInfo,
    getSettings,
    getSubscribeInfo,
    getSortBy,
    getFavoriteList,
    getUserSavedSearchList,
    getProfileUpdated,
    getHomeId,
};
