function updateUserSetting({ rootState, dispatch }, settings) {
    const url = `${rootState.glb.appUrl}api/user/settings/update/`;
    const data = { userId: rootState.glb.user.id, settings: settings };
    return dispatch('glb/sendRequest', { url: url, data: data, method: 'POST' }, { root: true });
}

function subscribeEmailNotification({ rootState, dispatch }) {
    const url = `${rootState.glb.appUrl}api/email/subscribe/`;
    return dispatch('glb/sendRequest', { url: url, data: { userId: rootState.glb.user.id }, method: 'POST' }, { root: true });
}

function unsubscribeEmailNotification({ rootState, dispatch }) {
    const url = `${rootState.glb.appUrl}api/email/unsubscribe/`;
    return dispatch('glb/sendRequest', { url: url, data: { userId: rootState.glb.user.id }, method: 'POST' }, { root: true });
}

function updateAccount({ dispatch, rootState }, data) {
    const url = `${rootState.glb.appUrl}api/user/update/`;
    return dispatch('glb/sendRequest', { url: url, data: data, method: 'POST' }, { root: true }).then((res) => {
        if (res) {
            return Promise.resolve(res);
        }
        return Promise.reject(res);
    });
}

function updatePassword({ dispatch, rootState }, data) {
    const url = `${rootState.glb.appUrl}api/user/password/change/`;
    return dispatch('glb/sendRequest', { url: url, data: data, method: 'POST' }, { root: true }).then((res) => {
        if (res) {
            return Promise.resolve(res);
        }
        return Promise.reject(res);
    });
}
function updateSavedSearchDataById({ commit, dispatch, rootState }, data) {
    if (!data) {
        return Promise.resolve(null);
    }
    const url = `${rootState.glb.appUrl}api/user/savedsearch/update`;
    return dispatch('glb/sendRequest', { url: url, method: 'POST', data: data }, { root: true }).then((res) => {
        if (res) {
            commit('updateSavedSearchById', res.data || []);
            return res;
        }
        return [];
    });
}
function getSavedSearchById({ commit, dispatch, rootState }, data) {
    let userId = rootState.glb.user.id;
    if (!userId) {
        return Promise.reject('login required');
    }
    const url = `${rootState.glb.appUrl}api/user/savedsearch/getsearchinfo/`;
    return dispatch(
        'glb/sendRequest',
        {
            url: url,
            method: 'POST',
            data: {
                userId: userId,
                searchId: data.searchId,
                savedSearchUrl: data.savedSearchUrl,
            },
        },
        { root: true }
    ).then((res) => {
        if (res && res.data) {
            commit('updateSavedSearchById', res.data);
        }
        return res;
    });
}
function deleteSavedSearch({ commit, dispatch, rootState }, data) {
    let userId = rootState.glb.user.id;
    if (!data) {
        return Promise.resolve(null);
    }
    const url = `${rootState.glb.appUrl}api/user/savedsearch/delete/`;
    return dispatch(
        'glb/sendRequest',
        {
            url: url,
            method: 'POST',
            data: {
                searchId: data.searchId,
                userId: userId,
            },
        },
        { root: true }
    ).then((res) => {
        if (res) {
            return res;
        }
        return [];
    });
}
function undoDeleteSavedSearch({ commit, dispatch, rootState }, data) {
    let userId = rootState.glb.user.id;
    if (!data) {
        return Promise.resolve(null);
    }
    const url = `${rootState.glb.appUrl}api/user/savedsearch/undo/`;
    return dispatch(
        'glb/sendRequest',
        {
            url: url,
            method: 'POST',
            data: {
                searchId: data.searchId,
                userId: userId,
            },
        },
        { root: true }
    ).then((res) => {
        if (res) {
            return res;
        }
        return [];
    });
}
function updateSavedSearchLastViewTime({ commit, dispatch, rootState }, data) {
    let userId = rootState.glb.user.id;
    if (!data) {
        return Promise.resolve(null);
    }
    const url = `${rootState.glb.appUrl}api/user/savedsearch/lastviewtime/update`;
    return dispatch(
        'glb/sendRequest',
        {
            url: url,
            method: 'POST',
            data: {
                searchId: data.searchId,
                userId: userId,
            },
        },
        { root: true }
    ).then((res) => {
        if (res) {
            return res;
        }
        return [];
    });
}

function updateListingNote({ commit, dispatch, rootState }, data) {
    let userId = rootState.glb.user.id;
    const url = `${rootState.glb.appUrl}api/favorites/note/updatebypropertyid/`;
    return dispatch(
        'glb/sendRequest',
        {
            url: url,
            method: 'POST',
            data: {
                propertyId: data.propertyId,
                listingId: data.listingId,
                note: data.noteData,
                userId: userId,
            },
        },
        { root: true }
    ).then((res) => {
        return res;
    });
}

function getnextFavListings({ commit, dispatch, rootState }, data) {
    const url = `${rootState.glb.appUrl}api/favorites/get/`;
    return dispatch('glb/sendRequest', { url: url, method: 'GET', data }, { root: true }).then((res) => {
        if (res.data && res.data.list && res.data.list.length > 0) {
            return res.data.list;
        }
        return [];
    });
}

function sendRequestAssistance({ commit, dispatch, rootState }, data) {
    const url = `${rootState.glb.appUrl}api/v/user/requestassistance/`;
    return dispatch('glb/sendRequest', { url: url, data }, { root: true }).then((res) => {
        if (res && res.data) {
            return true;
        }
        return false;
    });
}

function deleteAccount({ commit, dispatch, rootState }, id) {
    const url = `${rootState.glb.appUrl}api/user/delete/${id}`;
    return dispatch('glb/sendRequest', { url: url, method: 'DELETE' }, { root: true }).then((res) => {
        if (res.status && res.status.code == 0) {
            return true;
        }
        return res.status;
    });
}

export default {
    updateUserSetting,
    subscribeEmailNotification,
    unsubscribeEmailNotification,
    updateAccount,
    updatePassword,
    getSavedSearchById,
    updateSavedSearchDataById,
    deleteSavedSearch,
    undoDeleteSavedSearch,
    updateSavedSearchLastViewTime,
    updateListingNote,
    getnextFavListings,
    sendRequestAssistance,
    deleteAccount,
};
