import mapper from '../mapper/index.js';

const isSSR = typeof window === 'undefined';
const geo = mapper.geo;

function updateGeoLanding(state, pageData) {
    state.masthead = pageData.masthead;
    state.geo = pageData.geo;
    state.cityInfo = pageData.cityInfo;
    state.cityContent = pageData.cityContent;
    state.marketSummary = pageData.marketSummary;
    state.schoolSummary = pageData.schoolSummary;
    state.weatherSummary = pageData.weatherSummary;
    state.marketTrendsSummary = pageData.marketTrendsSummary;
    state.marketTrendsSnapshot = pageData.marketTrendsSnapshot;
    state.nearbySchools = pageData.nearbySchools;
    state.nearbyCities = pageData.nearbyCities;
    state.nearbyZipCodes = pageData.nearbyZipCodes;
    state.nearbyNeighborhoods = pageData.nearbyNeighborhoods;
    state.nearbyCounties = pageData.nearbyCounties;

    updateGeoInfo(state, pageData.geo);
}

function updateGeoInfo(state, geoInfo) {
    if (!isSSR) {
        state.geo = Object.assign(state.geo, geo(geoInfo, null));
        if (state.geo && state.geo.state) {
            $.setStorage('mapsearch.geo', state.geo);
        }
    }
}

export default {
    updateGeoLanding,
};
