export default function () {
    return {
        hardcode: false,
        hasGeoInfo: false,
        subPremise: '',
        address: '',
        formatAddress: '',
        zipcodeId: '',
        zipcode: '',
        zipcodePath: '',
        neighborhood: '',
        neighborhoodId: '',
        neighborhoodType: '',
        neighborhoodCode: '',
        neighborhoodPath: '',
        city: '',
        cityCode: '',
        cityPath: '',
        cityId: '',
        schoolPath: '',
        county: '',
        countyCode: '',
        countyPath: '',
        countyId: '',
        state: '',
        stateCode: '',
        statePath: '',
        stateId: '',
        lat: 0,
        lng: 0,
        gpsPath: '',
        geoPath: '',
        geoType: '',
        geoId: '',
        geoName: '',
    };
}
