const isSSR = typeof window === 'undefined';

export const SPLIT_CACHE_PREFIX = 'MCW';
export const SPLIT_TREATMENT_CACHE_VERSION = '1.0.0';
export const SPLIT_TREATMENT_CACHE_EXPIRY = 24 * 60 * 60 * 1000; // 24hrs
const SPLIT_EXPIRED_TREATMENT_CACHE_VERSIONS = ['0.0.1'];

const getSplitClientTreatmentCache = (cachePrefix, cacheVersion) => `${cachePrefix}.CLIENT_TREATMENT_CACHE_v${cacheVersion}`;
const getSplitServerClientTreatmentCache = (cachePrefix, cacheVersion) => `${cachePrefix}.SERVER_TREATMENT_CACHE_v${cacheVersion}`;

const SPLIT_CACHE_NAMING_GENERATOR = (cachePrefix, featureFlag) => {
    return `${cachePrefix}.SPLITIO.split.${featureFlag}`;
};

const getSplitEvaluationChangeNumber = (cachePrefix, featureFlag) => {
    const cacheKey = SPLIT_CACHE_NAMING_GENERATOR(cachePrefix, featureFlag);

    try {
        const cacheEntry = JSON.parse(window.localStorage.getItem(cacheKey) || '{}');
        return cacheEntry?.changeNumber ?? null;
    } catch {
        return null;
    }
};

const validateSplitTreatmentCache = ({ cachedEntry, latestChangeNumber, clientKey }) => {
    const { changeNumber, cachedAt, clientKey: cachedClientKey, value } = cachedEntry;
    const now = Date.now();

    const isChangeNumberValid = changeNumber && (!latestChangeNumber || changeNumber === latestChangeNumber);
    const isCacheRecent = cachedAt && now - cachedAt < SPLIT_TREATMENT_CACHE_EXPIRY;
    const isClientKeyValid = cachedClientKey && cachedClientKey === clientKey;
    return isChangeNumberValid && isCacheRecent && isClientKeyValid && !!value;
};

const upgradeServerSplitsFromCache = (serverSplits = {}) => {
    // Retrieve cache from local storage
    const cache = !isSSR ? JSON.parse(window.localStorage.getItem(getSplitServerClientTreatmentCache(SPLIT_CACHE_PREFIX, SPLIT_TREATMENT_CACHE_VERSION)) || '{}') : {};
    const now = Date.now();

    /*
        Because server treatments can turn 'on/off' if a user is suddenly eligible for an experiment, we use a cache to preserve
        the non-default status for GA tracking purposes. example: user eventually views a DPP in a market that is eligible for a
        test after browsing ineligible DPPs. however, when they inevitably visit an ineligible page, we want to track that they
        had been eligible at one point. See original implementation here:
        https://git.ojointernal.com/movoto/frontend/consumer-web/-/blob/deef016b083b9e369dff988f772f89743945ae33/utility/split/client.js
     */

    // Clear out any outdated cache items
    const filteredCache = Object.fromEntries(Object.entries(cache).filter(([, value]) => value.cachedAt && now - value.cachedAt <= SPLIT_TREATMENT_CACHE_EXPIRY));

    // Upgrade the cache with server splits, retaining previous 'non-default' treatments if applicable
    const upgradedCache = Object.entries(serverSplits).reduce((acc, [key, value]) => {
        if (!key.includes('_config') && value !== 'control') {
            acc[key] =
                filteredCache[key] && value === 'default'
                    ? filteredCache[key] // Use cached value if it exists and the current value is 'default'
                    : { cachedAt: now, value }; // Otherwise, set new value with current timestamp
        }
        return acc;
    }, {});

    // Update local storage with the upgraded cache
    window.localStorage.setItem(getSplitServerClientTreatmentCache(SPLIT_CACHE_PREFIX, SPLIT_TREATMENT_CACHE_VERSION), JSON.stringify(upgradedCache));

    // Simplify returned object after storing in cache
    return Object.entries(upgradedCache).reduce((acc, [key, value]) => ({ ...acc, [key]: value.value }), {});
};

export const asyncMemoize = (fn, options = {}) => {
    const {
        treatmentCacheKey = getSplitClientTreatmentCache(SPLIT_CACHE_PREFIX, SPLIT_TREATMENT_CACHE_VERSION),
        splitCacheKeyPrefix = '',
        getKey = (args) => JSON.stringify(args),
        isCacheValid = () => true,
    } = options;

    const pendingPromises = new Map();
    const cache = !isSSR && window.localStorage ? JSON.parse(window.localStorage.getItem(treatmentCacheKey) || '{}') : {};

    // Cache utility functions
    const getCache = (key) => cache[key];
    const setCacheAndLimitEntries = (argsKey, { flagName, value, cachedAt = Date.now(), clientKey, changeNumber }, limit = 10) => {
        // Add or update the new entry
        cache[argsKey] = { flagName, value, cachedAt, clientKey, changeNumber };

        // Group entries by flagName
        let flagGroups = {};
        for (const key in cache) {
            const entry = cache[key];
            if (!flagGroups[entry.flagName]) {
                flagGroups[entry.flagName] = [];
            }
            flagGroups[entry.flagName].push({
                key,
                ...entry,
            });
        }

        // Process each flag group to enforce the limit
        for (const flag in flagGroups) {
            let entries = flagGroups[flag];

            // Sort entries by cachedAt (oldest first)
            entries.sort((a, b) => a.cachedAt - b.cachedAt);

            // Identify non-control entries (assuming "control" and "default" are considered control values)
            let nonControlEntries = entries.filter((entry) => entry.value !== 'control' && entry.value !== 'default');

            // If there's exactly one non-control entry, we will ensure it is kept
            if (nonControlEntries.length === 1) {
                // Get the single non-control entry
                const singleNonControlEntry = nonControlEntries[0];

                // Filter out the non-control entry and keep up to (limit - 1) oldest entries
                let oldestEntries = entries.filter((entry) => entry.key !== singleNonControlEntry.key).slice(0, limit - 1);

                // Combine the non-control entry with the trimmed oldest entries
                entries = [singleNonControlEntry, ...oldestEntries];
            } else if (entries.length > limit) {
                // If there are more than 'limit' entries and no specific non-control logic applies, trim normally
                entries = entries.slice(-limit); // Keep only the newest 'limit' entries
            }

            // Update the flagGroup with the trimmed entries
            flagGroups[flag] = entries;
        }

        // Rebuild the cache object
        let newCache = {};
        for (const flagName in flagGroups) {
            for (const entry of flagGroups[flagName]) {
                newCache[entry.key] = {
                    flagName: entry.flagName,
                    value: entry.value,
                    cachedAt: entry.cachedAt,
                    clientKey: entry.clientKey,
                    changeNumber: entry.changeNumber,
                };
            }
        }

        // Save the updated cache back to localStorage
        window.localStorage.setItem(treatmentCacheKey, JSON.stringify(newCache));
    };
    const hasCache = (key) => Object.prototype.hasOwnProperty.call(cache, key);
    const deleteCache = (key) => {
        delete cache[key];
        window.localStorage.setItem(treatmentCacheKey, JSON.stringify(cache));
    };
    const cleanCache = (cacheToClean) => {
        /* Remove items from cache that have expired */
        const now = new Date();
        const cleanedCache = Object.entries(cacheToClean).filter(([key, value]) => {
            return value.cachedAt && now - value.cachedAt < SPLIT_TREATMENT_CACHE_EXPIRY;
        });
        window.localStorage.setItem(treatmentCacheKey, JSON.stringify(Object.fromEntries(cleanedCache)));
        /* Remove any old versions of cache */
        SPLIT_EXPIRED_TREATMENT_CACHE_VERSIONS.forEach((version) => {
            const expiredClientTreatmentCacheKey = getSplitClientTreatmentCache(SPLIT_CACHE_PREFIX, version);
            window.localStorage.removeItem(expiredClientTreatmentCacheKey);
            const expiredServerTreatmentCacheKey = getSplitServerClientTreatmentCache(SPLIT_CACHE_PREFIX, version);
            window.localStorage.removeItem(expiredServerTreatmentCacheKey);
        });
    };

    // Do an initial cleaning of the cache
    !isSSR && cleanCache(cache);

    // Memoized function
    return async function (...args) {
        const argsKey = getKey(...args);
        const [isReady, clientKey, featureFlag] = args.slice(1);

        // Get the latest evaluation change number for cache validation
        const latestChangeNumber = getSplitEvaluationChangeNumber(splitCacheKeyPrefix, featureFlag);

        if (hasCache(argsKey)) {
            // Return cached value if valid
            if (isCacheValid({ cachedEntry: getCache(argsKey), latestChangeNumber, clientKey })) {
                const cachedEntry = getCache(argsKey);
                return { [cachedEntry.flagName]: cachedEntry.value };
            } else {
                // Delete cached entry if its invalid
                deleteCache(argsKey);
            }
        }

        // Return pending promise if available
        if (pendingPromises.has(argsKey)) {
            return pendingPromises.get(argsKey);
        }

        // Create and store a new promise if client is ready
        if (isReady) {
            const resultPromise = fn(...args).then((result) => {
                const [flagName, value] = Object.entries(result)[0];
                // If no limit necessary, consider using this instead:
                // setCache(argsKey, { flagName: flagName, value, cachedAt: Date.now(), clientKey, changeNumber: latestChangeNumber });
                setCacheAndLimitEntries(argsKey, { flagName: flagName, value, cachedAt: Date.now(), clientKey, changeNumber: latestChangeNumber }, 10);
                pendingPromises.delete(argsKey); // Clean up after promise resolution
                return result;
            });

            pendingPromises.set(argsKey, resultPromise);
            return resultPromise;
        }

        // Return control if the client is not ready
        return options.withConfig ? { treatment: 'control', config: {} } : { [featureFlag]: 'control' };
    };
};

export const memoizedFetchTreatment = asyncMemoize(
    async (client, isReady, key, featureFlag, options) => {
        const { withConfig, attributes = {} } = options;
        const result = withConfig ? await client.getTreatmentWithConfig(featureFlag, attributes) : await client.getTreatment(featureFlag, attributes);

        let config = {};
        if (withConfig) {
            try {
                config = JSON.parse(result.config);
            } catch (e) {
                console.log('Unable to parse JSON from Split cache');
            }
        }

        return withConfig ? { [featureFlag]: { treatment: result.treatment, config } } : { [featureFlag]: result };
    },
    {
        treatmentCacheKey: getSplitClientTreatmentCache(SPLIT_CACHE_PREFIX, SPLIT_TREATMENT_CACHE_VERSION),
        splitCacheKeyPrefix: SPLIT_CACHE_PREFIX,
        getKey: (client, isReady, key, featureFlag, options) => JSON.stringify([featureFlag, options]),
        isCacheValid: validateSplitTreatmentCache,
    }
);

export const fetchCachedClientTreatments = (experimentNames = []) => {
    // Return an empty object for server-side rendering
    if (isSSR) return {};

    // Retrieve cache from local storage
    const cache = JSON.parse(window.localStorage.getItem(getSplitClientTreatmentCache(SPLIT_CACHE_PREFIX, SPLIT_TREATMENT_CACHE_VERSION)) || '{}');

    if (!cache) return null;

    // Construct the lookup object
    const lookup = Object.keys(cache).reduce((acc, key) => {
        if (cache?.[key]?.flagName && cache?.[key]?.value) {
            if (cache[key].value !== 'default' && cache[key].value !== 'control') {
                return { ...acc, [cache[key].flagName]: cache[key].value };
            }
        }
        return acc;
    }, {});

    // If no experiment names are provided or the array is empty, return the full lookup
    if (experimentNames.length === 0) {
        return lookup;
    }

    // Filter the lookup to include only the keys specified in experimentNames
    return experimentNames.reduce((filteredLookup, name) => {
        if (lookup[name]) {
            filteredLookup[name] = lookup[name];
        }
        return filteredLookup;
    }, {});
};

export const formatSplitsForGA4 = (serverSplits = {}, clientSplits = {}, asJson = false) => {
    const upgradedServerSplits = upgradeServerSplitsFromCache(serverSplits);

    // Filter SSR 'default' evaluation and unwanted '_config' keys
    const filteredServerSplits = Object.entries(upgradedServerSplits)
        .filter(([key, value]) => !key.includes('_config') && value !== 'control' && value !== 'default')
        .reduce((acc, [key, value]) => ({ ...acc, [key]: value }), {});

    // Filter lookup failures, i.e., 'control', and extract treatments
    const filteredClientSplits = Object.entries(clientSplits)
        .filter(([key, value]) => value !== 'control' && value !== 'default' && (typeof value === 'string' || value.treatment !== 'control' || value.treatment !== 'default'))
        .reduce((acc, [key, value]) => ({ ...acc, [key]: typeof value === 'string' ? value : value.treatment }), {});

    // Merge filtered results
    const mergedResults = { ...filteredServerSplits, ...filteredClientSplits };

    if (asJson) {
        return Object.entries(mergedResults)
            .map(([key, value]) => {
                const JIRAKey = key.match(/CW-\d{3,}/i);
                return JIRAKey ? { [JIRAKey[0].toUpperCase()]: value.toLowerCase() } : null;
            })
            .filter(Boolean)
            .reduce((acc, curr) => ({ ...acc, ...curr }), {});
    }

    // String result
    return Object.entries(mergedResults)
        .map(([key, value]) => {
            const JIRAKey = key.match(/\d{3,}/);
            return JIRAKey ? `${JIRAKey[0]}:${value.toLowerCase()}` : null;
        })
        .filter(Boolean)
        .join('|');
};

export const getOverridenExperiments = (originalExperiments, splitWhiteList = null) => {
    if (typeof window === 'undefined' && !splitWhiteList) {
        return;
    } else if (!splitWhiteList) {
        const testuser = new URLSearchParams(window?.location?.search)?.get('testuser');
        splitWhiteList = testuser?.split('.');
    }

    const experiments = {};

    if (splitWhiteList && splitWhiteList.length > 0) {
        Object.keys(originalExperiments).forEach((key) => {
            if (splitWhiteList.length === 1 && splitWhiteList[0] * 1 === 1) {
                experiments[key] = 'on';
            } else if (splitWhiteList.length === 1 && splitWhiteList[0] * 1 === 2) {
                experiments[key] = 'default';
            } else {
                const dict = splitWhiteList.reduce((obj, key) => {
                    if (key.split('_').length > 1) {
                        obj[key.split('_')[0]] = key.split('_')[1];
                    } else {
                        obj[key] = 'on';
                    }
                    return obj;
                }, {});

                const rex = new RegExp(`(${Object.keys(dict).join('|')})$`);
                if (rex.test(key)) {
                    experiments[key] = dict[key.match(/\d{3,}/)[0]];
                } else {
                    experiments[key] = 'default';
                }
            }
        });
    }
    return experiments;
};
