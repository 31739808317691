export default {
    "SignIn": "Registrarse",
    "Join": "Unirse",
    "SignOut": "Desconectar",
    "Home": "Inicio",
    "Notification": "Notificación",
    "Feed": "Explorar Propiedades",
    "savedHomes": "Casas Guardadas",
    "Buy": "Comprar",
    "Rent": "Rentar",
    "Homeowner": "Propietario",
    "ownerDashBoard": "Panel de control del propietario",
    "BuyingPower": "Calculadora de presupuesto",
    "Mortgage": "Hipoteca",
    "MortgageLoan": "Préstamo",
    "MortgageCalculator": "Calculadora",
    "MortgageRates": "Tasas Hipotecarias",
    "GetPreApproved": "Preaprobación",
    "GetInsuranceQuotes": "Cotizaciones de seguro",
    "HomeBuyingChecklist": "Pasos fundamentales",
    "HomesForSaleNearMe": "Casas en venta cerca de mi",
    "HomesForRentNearMe": "Casas en renta cerca de mi",
    "ApartmentsForRentNearMe": "Apartamentos en Alquiler Cerca De Mí",
    "HousesForRentNearMe": "Casas en Alquiler Cerca De Mí",
    "HomesforSale": "Casas en venta en $baseName",
    "ApartmentsForRent": "Apartamentos en renta en $baseName",
    "ApartmentsForSale": '$baseName condominios en venta',
    "HousesForRent": "Casas en renta en $baseName",
    "HousesForSale": '$baseName Unifamiliar en venta',
    "multiFamily": '$baseName multifamiliar/dúplex en venta',
    "land": '$baseName terrenos en venta',
    "RentalHomes": "Alquileres en $baseName",
    "OpenHouses": "Muestras de casas en $baseName",
    "RecentPriceReductions": "Casas en descuento en $baseName",
    "RefinanceRates": "Refinanciamiento hipotecario",
    "Sell": "Vender",
    "CashOffer": "Oferta en efectivo",
    "HomeSaleCalculator": "Calculadora de venta",
    "NoObligationCashOffer": "Oferta en efectivo sin obligación",
    "GetaHomeValuation": "Valor de la vivienda",
    "HomeRepairServiceProviders": "Proveedores de reparación y servicios",
    "MeetanAgent": "Encuentra un agente",
    "BecomeaPartnerAgent": "Únete a Movoto",
    "Agent": "Agentes",
    "AgentsSearch": "Agentes locales $baseName",
    "AgentDirectory": "Perfil de Agentes",
    "CreateanAgentProfile": "Únete a la red de agentes",
    "downloadApp": "Descargue app",
    "myAgent": "Mi agente",
    "savedSearches": "Búsquedas guardadas",
    "profile": "Perfil",
    "settings": "Ajustes",
    "AgentHelp": "Ayuda del Agente",
    "Keywords": "Casas en venta, Casas en venta, Agentes inmobiliarios, Listados inmobiliarios, Encuentre una casa, Listados MLS, Agentes inmobiliarios, Listados MLS, Movoto",
    "HotleadTitle": "Comience ahora, conéctese para una consulta experta gratuita",
    "HotleadButtionCap": "Conectarse",
    "brokerageoffices": "Oficina de bienes raíces de $baseName",
    "veteranMortgage": "Préstamos hipotecarios para veteranos y VA",
    "veteranResources": "Recursos para veteranos",
    "sellHome": "Vender mi casa",
}