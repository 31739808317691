const formatNumberByComma = (number) => {
    return !Number.isNaN(number * 1) ? number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') : '';
};
export default {
    getDateString(date) {
        if (!date || !date.toISOString) {
            return '';
        }
        return date.toISOString().split('T')[0];
    },
    capFirst(str) {
        if (typeof str !== 'string') {
            return '';
        }
        return str.charAt(0).toUpperCase() + str.slice(1);
    },
    capAll(str) {
        if (typeof str !== 'string') {
            return '';
        }
        return str
            .toLowerCase()
            .split(' ')
            .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
            .join(' ');
    },
    capAddress(str) {
        if (typeof str !== 'string') {
            return '';
        }
        return str
            .toLowerCase()
            .split(' ')
            .map((s) => (s.length == 2 ? s.toUpperCase() : s.charAt(0).toUpperCase() + s.substring(1)))
            .join(' ');
    },
    area(value, showAcre) {
        if (!value) {
            return '—';
        }
        if (showAcre) {
            var val = value / 43560;
            if (val % 1 === 0) {
                val = val.toFixed(0);
            } else {
                val = val.toFixed(2);
            }
            return val;
        }
        return formatNumberByComma(value);
    },
    phoneNumber(number, format) {
        var display = number + '';
        var rawNumber = display.replace(/[^0-9]/g, '');
        if (rawNumber.length > 10) {
            rawNumber = rawNumber.substring(rawNumber.length - 10, rawNumber.length);
        } else {
            rawNumber = (Array(10).join(0) + rawNumber).slice(-10);
        }
        rawNumber = rawNumber.replace(/(\d{3})(\d{3})(\d{4})/, format || '$1-$2-$3');
        return rawNumber;
    },
    number(number) {
        return formatNumberByComma(number);
    },
    price(number) {
        let n = formatNumberByComma(number);
        return n !== '' ? `$${n}` : '';
    },
    friendlyPrice(value) {
        var num = value * 1;
        var prefix = '';
        if (Number.isNaN(num)) {
            return '';
        }
        if (num < 0) {
            num = Math.abs(num);
        }

        var val = num.toFixed(0);
        var suffix = '';
        if (num < 10000) {
            val = val.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
        } else {
            if (val.length < 7) {
                suffix = 'K';
            } else if (val.length < 10) {
                suffix = 'M';
            } else if (val.length < 13) {
                suffix = 'B';
            } else {
                suffix = 'T';
            }
            val = val.replace(/\B(?=(\d{3})+(?!\d))/g, '.').substring(0, 4);
            val = parseFloat(val).toString();
        }
        return `$${prefix}${val}${suffix}`;
    },
};
