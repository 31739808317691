const geo = function(state){
    return state.geo;
};
const survey = function(state){
    return state;
};

const formData = function(state){
    return state.formData;
};

export default {
    geo,
    survey,
    formData
};