import { getSegments } from './pathHelper.js';
import format from '../../vweb/common/utilities/format.js';

const propertyTypes = {
    SINGLE_FAMILY_HOUSE: 'single-family',
    CONDO: 'condos',
    MULTI_FAMILY: 'multi-family',
    LAND: 'land',
    MOBILE: 'mobile',
    OTHER: 'other',
};
function _getKeyByValue(object, value) {
    return Object.keys(object).find((key) => object[key] === value);
}

export const filterKeyWords = {
    sold: 'sold',
    singleFamily: 'single-family',
    condo: 'condos',
    openHouse: 'open-houses',
    priceReduce: 'reduced-30',
    virtualTour: 'virtual-tour',
    newConstruction: 'new-construction',
    luxuryHomes: 'luxury-homes',
    new: 'new-7',
    foreClosed: 'foreclosed',
    hidePending: 'status-1',
    rentals: 'rentals',
    video: 'video',
};
const rangeFilters = new Set(['bath', 'dom', 'sqft', 'bed', 'price', 'hoa', 'lot', 'built', 'p']);
const valueFilters = new Set(['type', 'boundary', 'sortby', 'school', 'saz', 'new', 'status', 'reduced', 'garage', 'kw', 'sold', 'att', 'pagesize', 'detail']);
const boolFilters = new Set(['open-houses', 'luxury-homes', 'virtual-tour', 'foreclosed', 'sold', 'rentals', 'new-construction', 'listedbymovoto', 'video']);
const video = /^video$/i;
const rentals = /^rentals$/i;
const pageSize = /^pagesize-([\d]+)$/i;
const sold = /^sold(-(\w+))?$/i;
const type = /^(single-family,?|condos,?|multi-family,?|land,?|mobile,?|other,?)+$/i;
const attribute = /^att-([\w._,]+)$/i;
const openHouses = /^open-houses$/i;
const status = /^status-(\d)$/i;
const newFilter = /^new-([\d]+)$/i;
const priceReduced = /^reduced-([\d.]+)$/i;
const virtualTour = /^virtual-tour$/i;
const luxuryHomes = /^luxury-homes$/i;
const foreclosed = /^foreclosed$/i;
const newConstruction = /^new-construction$/i;
const price = /^price-([\d.]+)-([\d.]+)$/i;
const bed = /^bed-([\d.]+)-([\d.]+)$/i;
const bath = /^bath-([\d.]+)-([\d.]+)$/i;
const hoa = /^hoa-([\d.]+)-?(([\d.]+))?$/i;
const schoolZone = /^saz-(\w+)$/i;
const houseSize = /^sqft-([\d.]+)-([\d.]+)$/i;
const lotSize = /^lot-([\d.]+)-([\d.]+)$/i;
const yearBuilt = /^built-([\d]+)-([\d]+)$/i;
const dom = /^dom-([\d]+)-([\d]+)$/i;
const sort = /^sortby-([\w-]+)$/i;
const boundary = /^boundary-1$/i;
const keyword = /^kw-([\w._,]+)$/i;
const school = /^school-(\d+)(u?)-([\w]+)$/i;
const garage = /^garage-([\d]+)$/i;
const coordinates = /^@([\d-.,]+)$/i;
export const locationFilter = /^@([\d-.]+),([\d-.]+)$/i;
export const pageIndexFilter = /^p-(\d+)$/i;
const listedByMovoto = /^listedbymovoto$/i;
export function isFilter(segment) {
    return isRangeFilter(segment) || isValueFilter(segment) || isBoolFilter(segment);
}
export function isRangeFilter(segment) {
    const prefix = segment.split('-')[0];
    return rangeFilters.has(prefix);
}
export function isValueFilter(segment) {
    const prefix = segment.split('-')[0];
    let isValueFilter = valueFilters.has(prefix);
    return isValueFilter || type.test(segment) || coordinates.test(segment);
}
export function isBoolFilter(segment) {
    return boolFilters.has(segment);
}
export function getPathByFilter(input, skipRealTimeParams = false, symbol = ['/', '-']) {
    //if it's payload searchCondition
    let filter;
    let segmentsSplit = symbol[0];
    let keyValueSplit = symbol[1];
    if (filter && filter.searchType) {
        filter = getFilterByPayloadFilter(input);
    } else {
        filter = input;
    }
    const queryParams = [];
    const addQueryParam = (key, value) => {
        if (key && value) {
            queryParams.push(`${key}${keyValueSplit}${value}`);
        } else if (value) {
            queryParams.push(`${value}`);
        }
    };

    if (filter.rentals) {
        addQueryParam('', filterKeyWords.rentals);
    }

    // Add filter parameters to the path string
    if (filter.propertyType && typeof filter.propertyType == 'string') {
        filter.propertyType = [filter.propertyType];
    }
    if (filter.propertyType && filter.propertyType.length > 0) {
        if (filter.propertyType.length === 1) {
            let type = propertyTypes[filter.propertyType[0]] || filter.propertyType[0];
            addQueryParam('', type);
        } else {
            let types = filter.propertyType.map((e) => propertyTypes[e] || e);
            //remove duplicate item in types
            types = [...new Set(types)];
            addQueryParam('type', types.join(','));
        }
    }

    if (filter.ojoAttribute && filter.ojoAttribute.length > 0) {
        addQueryParam('att', filter.ojoAttribute.join(','));
    }

    if (filter.propertyCriteriaOpenHouse) {
        addQueryParam('', filterKeyWords.openHouse);
    }

    if (!filter.rentals) {
        if (filter.soldDateRange) {
            if (filter.soldDateRange === 'all') {
                addQueryParam('', filterKeyWords.sold);
            } else {
                addQueryParam('sold', filter.soldDateRange);
            }
        } else if (filter.hidePending) {
            addQueryParam('', filterKeyWords.hidePending);
        }
    }

    if (filter.updateNew) {
        addQueryParam('', filterKeyWords.new);
    }

    if (filter.updatePriceReduced) {
        addQueryParam('', filterKeyWords.priceReduce);
    }

    if (filter.updateVirtualTour) {
        addQueryParam('', filterKeyWords.virtualTour);
    }

    if (filter.video) {
        addQueryParam('', 'video');
    }

    if (filter.updateNewConstruction) {
        addQueryParam('', filterKeyWords.newConstruction);
    }

    if (filter.minPrice > 0 || filter.maxPrice > 0) {
        addQueryParam('price', `${filter.minPrice > 0 ? filter.minPrice : 0}-${filter.maxPrice > 0 ? filter.maxPrice : 0}`);
    }
    if (filter.minBed > 0 || filter.maxBed > 0) {
        addQueryParam('bed', `${filter.minBed ? filter.minBed : 0}-${filter.maxBed > 0 ? filter.maxBed : 0}`);
    }

    if (filter.minBath > 0 || filter.maxBath > 0) {
        addQueryParam('bath', `${filter.minBath > 0 ? filter.minBath : 0}-${filter.maxBath > 0 ? filter.maxBath : 0}`);
    }

    if (filter.minHoa == -1 && filter.maxHoa >= 0) {
        addQueryParam('hoa', `${filter.maxHoa > 0 ? filter.maxHoa : 0}`);
    } else if (filter.minHoa > 0 || filter.maxHoa > 0) {
        addQueryParam('hoa', `${filter.minHoa > 0 ? filter.minHoa : 0}-${filter.maxHoa > 0 ? filter.maxHoa : 0}`);
    }

    if (filter.minHouseSize > 0 || filter.maxHouseSize > 0) {
        addQueryParam('sqft', `${filter.minHouseSize > 0 ? filter.minHouseSize : 0}-${filter.maxHouseSize > 0 ? filter.maxHouseSize : 0}`);
    }

    if (filter.minLotSize > 0 || filter.maxLotSize > 0) {
        addQueryParam('lot', `${filter.minLotSize > 0 ? filter.minLotSize : 0}-${filter.maxLotSize > 0 ? filter.maxLotSize : 0}`);
    }

    if (filter.minYearBuild > 0 || filter.maxYearBuild > 0) {
        addQueryParam('built', `${filter.minYearBuild || 0}-${filter.maxYearBuild || 0}`);
    }

    if (filter.minDOM > 0 || filter.maxDOM > 0) {
        addQueryParam('dom', `${filter.minDOM > 0 ? filter.minDOM : 0}-${filter.maxDOM > 0 ? filter.maxDOM : 0}`);
    }

    if (filter.sort) {
        let key = filter.sort.includes('sortby') ? '' : 'sortby';
        addQueryParam(key, filter.sort.replace(/_/g, '-'));
    }

    if (filter.boundary) {
        addQueryParam('boundary', '1');
    }

    if (filter.photos) {
        addQueryParam('kw', 'photos');
    }

    let schoolStr = '';
    if (filter.schoolPre) {
        schoolStr += 'p';
    }
    if (filter.schoolElementary) {
        schoolStr += 'e';
    }
    if (filter.schoolMiddle) {
        schoolStr += 'm';
    }
    if (filter.schoolHigh) {
        schoolStr += 'h';
    }
    if (filter.schoolPublic) {
        schoolStr += 'u';
    }
    if (filter.schoolCharter) {
        schoolStr += 'c';
    }
    if (filter.schoolPrivate) {
        schoolStr += 'r';
    }
    if (schoolStr && filter.schoolMinRate) {
        if (filter.schoolIncludeUnrated) {
            schoolStr = `${filter.schoolMinRate}u-${schoolStr}`;
        } else {
            schoolStr = `${filter.schoolMinRate}-${schoolStr}`;
        }
        addQueryParam('school', schoolStr);
    }

    if (filter.schoolId) {
        addQueryParam('saz', `${filter.schoolId}`);
    }

    if (filter.propertyListedMovoto) {
        addQueryParam('listedbymovoto', 'listedbymovoto');
    }

    if (filter.coordinates && !skipRealTimeParams) {
        if (Array.isArray(filter.coordinates) && filter.coordinates[0]) {
            addQueryParam('', `@${filter.coordinates.join(',')}`);
        } else if (filter.coordinates && filter.coordinates.ne && filter.coordinates.sw) {
            let str = `${filter.coordinates.ne.lat},${filter.coordinates.ne.lng},${filter.coordinates.sw.lat},${filter.coordinates.sw.lng}`;
            if (filter.coordinates.zoom) {
                str += `,${filter.coordinates.zoom}`;
            }
            addQueryParam('', `@${str}`);
        }
    } else if (filter.center && !skipRealTimeParams) {
        addQueryParam('', `@${filter.center.lat},${filter.center.lng}`);
    }

    if (filter.pageIndex > 1 && !skipRealTimeParams) {
        addQueryParam('p', Number.parseInt(filter.pageIndex));
    }

    const filterPath = queryParams.join(segmentsSplit);

    if (segmentsSplit === '/') {
        return filterPath ? filterPath + '/' : '';
    } else {
        return filterPath;
    }
}
export function getFilterBySegments(segments) {
    let filter = {};
    if (!segments || segments.length === 0) {
        return filter;
    }
    segments.forEach((segment) => {
        let result;

        result = rentals.exec(segment);
        if (result) {
            filter.rentals = 1;
            return;
        }

        result = sold.exec(segment);
        if (result) {
            filter.isActive = 0;
            filter.houseStatus = 3;
            filter.soldDateRange = result[2] ? result[2].toLowerCase() : 'all';
            return;
        }

        result = type.exec(segment.replace(/^type-/, ''));
        if (result) {
            filter.propertyType = segment.replace(/^type-/, '').split(',');
            return;
        }

        result = attribute.exec(segment);
        if (result) {
            filter.ojoAttribute = result[1].split(',');
            return;
        }

        result = openHouses.exec(segment);
        if (result) {
            filter.propertyCriteriaOpenHouse = 1;
            return;
        }

        //todo update status to pending active sold searchurifactory line 754
        result = status.exec(segment);
        if (result) {
            if (result[1] == 1) {
                filter.hidePending = 1;
            }
            filter.houseStatus = result[1] * 1;
            return;
        }

        result = newFilter.exec(segment);
        if (result) {
            filter.updateNew = 1;
            return;
        }

        result = priceReduced.exec(segment);
        if (result) {
            filter.updatePriceReduced = 1;
            return;
        }

        result = virtualTour.exec(segment);
        if (result) {
            filter.updateVirtualTour = 1;
            return;
        }

        result = video.exec(segment);
        if (result) {
            filter.hasVideo = true;
            return;
        }

        result = luxuryHomes.exec(segment);
        if (result) {
            filter.propertyCriteriaLuxuryHome = 1;
            return;
        }

        result = foreclosed.exec(segment);
        if (result) {
            filter.propertyCriteriaForeclosed = 1;
            return;
        }

        result = newConstruction.exec(segment);
        if (result) {
            filter.updateNewConstruction = 1;
            return;
        }

        result = price.exec(segment);
        if (result) {
            filter.minPrice = Number.parseInt(result[1]);
            filter.maxPrice = Number.parseInt(result[2]);
            return;
        }

        result = bed.exec(segment);
        if (result) {
            filter.minBed = Number.parseInt(result[1]);
            filter.maxBed = Number.parseInt(result[2]);
            return;
        }

        result = bath.exec(segment);
        if (result) {
            filter.minBath = Number.parseFloat(result[1]);
            filter.maxBath = Number.parseFloat(result[2]);
            return;
        }

        result = hoa.exec(segment);
        if (result) {
            if (result[2] !== undefined) {
                filter.minHoa = Number.parseInt(result[1]);
                filter.maxHoa = Number.parseInt(result[2]);
            } else if (result[1] >= 0) {
                //SP hoa-0 as max setting
                filter.maxHoa = Number.parseInt(result[1]);
            }
            return;
        }

        result = schoolZone.exec(segment);
        if (result) {
            filter.schoolId = result[1];
            return;
        }

        result = houseSize.exec(segment);
        if (result) {
            filter.minHouseSize = Number.parseInt(result[1]);
            filter.maxHouseSize = Number.parseInt(result[2]);
            return;
        }

        result = lotSize.exec(segment);
        if (result) {
            filter.minLotSize = Number.parseInt(result[1]);
            filter.maxLotSize = Number.parseInt(result[2]);
            return;
        }

        result = yearBuilt.exec(segment);
        if (result) {
            filter.minYearBuild = Number.parseInt(result[1]);
            filter.maxYearBuild = Number.parseInt(result[2]);
            return;
        }

        result = dom.exec(segment);
        if (result) {
            filter.minDOM = Number.parseInt(result[1]);
            filter.maxDOM = Number.parseInt(result[2]);
            return;
        }

        result = sort.exec(segment);
        if (result) {
            filter.sort = `sortby-${result[1]}`;
            return;
        }

        result = boundary.exec(segment);
        if (result) {
            filter.boundary = 1;
            return;
        }

        result = keyword.exec(segment);
        if (result) {
            filter.fixerupper = result[1].indexOf('fixerupper') > -1 ? 1 : 0;
            filter.pool = result[1].indexOf('pool') > -1 ? 1 : 0;
            if (filter.pool) {
                if (filter.ojoAttribute && filter.ojoAttribute.indexOf('pool') === -1) {
                    filter.ojoAttribute.push('pool');
                } else {
                    filter.ojoAttribute = ['pool'];
                }
            }
            filter.photos = result[1].indexOf('photos') > -1 ? 1 : 0;
            return;
        }

        result = school.exec(segment);
        if (result) {
            filter.schoolMinRate = Number.parseInt(result[1]);
            filter.schoolIncludeUnrated = result[2] ? 1 : 0;
            filter.schoolHigh = result[3].indexOf('h') > -1 ? 1 : 0;
            filter.schoolMiddle = result[3].indexOf('m') > -1 ? 1 : 0;
            filter.schoolElementary = result[3].indexOf('e') > -1 ? 1 : 0;
            filter.schoolPre = result[3].indexOf('p') > -1 ? 1 : 0;
            filter.schoolPublic = result[3].indexOf('u') > -1 ? 1 : 0;
            filter.schoolCharter = result[3].indexOf('c') > -1 ? 1 : 0;
            filter.schoolPrivate = result[3].indexOf('r') > -1 ? 1 : 0;
            return;
        }

        result = garage.exec(segment);
        if (result) {
            filter.garage = 1;
            if (filter.garage) {
                if (filter.ojoAttribute && filter.ojoAttribute.indexOf('garage') === -1) {
                    filter.ojoAttribute.push('garage');
                } else {
                    filter.ojoAttribute = ['garage'];
                }
            }
            return;
        }

        result = coordinates.exec(segment);
        if (result) {
            let latlngs = result[1].split(',');
            if (latlngs.length >= 4) {
                filter.coordinates = {
                    ne: {
                        lat: Number.parseFloat(latlngs[0]),
                        lng: Number.parseFloat(latlngs[1]),
                    },
                    sw: {
                        lat: Number.parseFloat(latlngs[2]),
                        lng: Number.parseFloat(latlngs[3]),
                    },
                };
                if (latlngs.length === 5) {
                    filter.coordinates.zoom = Number.parseInt(latlngs[4]);
                }
                return;
            }
        }

        result = locationFilter.exec(segment);
        if (result) {
            filter.center = {
                lat: Number.parseFloat(result[1]),
                lng: Number.parseFloat(result[2]),
            };
            return;
        }

        result = pageIndexFilter.exec(segment);
        if (result) {
            filter.pageIndex = Number.parseInt(result[1]);
            return;
        }

        result = pageSize.exec(segment);
        if (result) {
            filter.pageSize = Number.parseInt(result[1]);
            return;
        }

        result = listedByMovoto.exec(segment);
        if (result) {
            filter.propertyListedMovoto = 1;
            return;
        }
    });
    return filter;
}
export function getFilterByPath(path) {
    let segments = getSegments(path);
    let filter = getFilterBySegments(segments);
    return filter;
}
export function getFilterByPayloadFilter(payload) {
    //todo maapping
    let filter = {
        ojoAttribute: [],
        coordinates: '',
        minBed: 0,
        maxBed: 0,
        minBath: 0,
        maxBath: 0,
        soldDateRange: 0,
        minPrice: 0,
        maxPrice: 0,
        propertyType: [],
        minHouseSize: 0,
        maxHouseSize: 0,
        minLotSize: 0,
        maxLotSize: 0,
        minYearBuild: 0,
        maxYearBuild: 0,
        minDOM: 0,
        maxDOM: 0,
        minHoa: -1,
        maxHoa: -1,
        pool: 0,
        fixerupper: 0,
        isActive: 1,
        updateNew: 0,
        propertyCriteriaLuxuryHome: 0,
        propertyCriteriaOpenHouse: 0,
        updatePriceReduced: 0,
        updateVirtualTour: 0,
        updateNewConstruction: 0,
        propertyCriteriaForeclosed: 0,
        propertyListedMovoto: 0,
        hidePending: 0,
        photos: 0,
        garage: 0,
        sort: null,
        schoolHigh: 0,
        schoolMiddle: 0,
        schoolElementary: 0,
        schoolPre: 0,
        schoolPublic: 0,
        schoolCharter: 0,
        schoolPrivate: 0,
        schoolMinRate: 1,
        schoolIncludeUnrated: 1,
        schoolId: null,
        rentals: 0,
    };
    if (payload.minPrice !== undefined) {
        filter.minPrice = payload.minPrice;
    }
    if (payload.maxPrice !== undefined) {
        filter.maxPrice = payload.maxPrice;
    }
    if (payload.minBed !== undefined) {
        filter.minBed = payload.minBed;
    }
    if (payload.maxBed !== undefined) {
        filter.maxBed = payload.maxBed;
    }
    if (payload.minBath !== undefined) {
        filter.minBath = payload.minBath;
    }
    if (payload.maxBath !== undefined) {
        filter.maxBath = payload.maxBath;
    }
    if (payload.propertyTypes && payload.propertyTypes.length > 0) {
        filter.propertyType = payload.propertyTypes.map((e) => propertyTypes[e] || e);
    }
    if (payload.minSqft !== undefined) {
        filter.minHouseSize = payload.minSqft;
    }
    if (payload.maxSqft !== undefined) {
        filter.maxHouseSize = payload.maxSqft;
    }
    if (payload.minLot !== undefined) {
        filter.minLotSize = payload.minLot;
    }
    if (payload.maxLot !== undefined) {
        filter.maxLotSize = payload.maxLot;
    }
    if (payload.minYear !== undefined) {
        filter.minYearBuild = payload.minYear;
    }
    if (payload.maxYear !== undefined) {
        filter.maxYearBuild = payload.maxYear;
    }
    if (payload.minDom !== undefined) {
        filter.minDOM = payload.minDom;
    }
    if (payload.maxDom !== undefined) {
        filter.maxDOM = payload.maxDom;
    }
    if (payload.minHoa !== undefined) {
        filter.minHoa = payload.minHoa;
    }
    if (payload.maxHoa !== undefined) {
        filter.maxHoa = payload.maxHoa;
    }
    if (payload.attributesTags && payload.attributesTags.length > 0) {
        filter.ojoAttribute = payload.attributesTags;
        if (payload.attributesTags.indexOf('pool') > -1) {
            filter.pool = 1;
        }

        if (payload.attributesTags.indexOf('garage') > -1) {
            filter.garage = 1;
        }
    }
    if (payload.isNewListingsOnly !== undefined) {
        filter.updateNew = payload.isNewListingsOnly;
    }
    if (payload.isReducedPrice !== undefined) {
        filter.updatePriceReduced = payload.isReducedPrice;
    }
    if (payload.isVirtualTourLinkOnly !== undefined) {
        filter.updateVirtualTour = payload.isVirtualTourLinkOnly;
    }

    if (payload.hasVideo !== undefined) {
        filter.hasVideo = payload.hasVideo;
    }

    if (payload.isNewConstruction !== undefined) {
        filter.updateNewConstruction = payload.isNewConstruction;
    }
    if (payload.isOpenHousesOnly !== undefined) {
        filter.propertyCriteriaOpenHouse = payload.isOpenHousesOnly;
    }
    if (payload.movotoListing !== undefined) {
        filter.propertyListedMovoto = payload.movotoListing;
    }
    if (payload.hasPhoto !== undefined) {
        filter.photos = payload.hasPhoto;
    }

    if (payload.sortColumn && payload.sortOrder) {
        filter.sort = 'sortby-' + payload.sortColumn + '-' + payload.sortOrder;
    }

    if (payload.searchPropertyStatus !== undefined) {
        if (payload.searchPropertyStatus === 'FOR_RENT') {
            filter.rentals = 1;
        } else if (payload.searchPropertyStatus === 'INACTIVE') {
            filter.isActive = 0;
            if (payload.soldDateRange) {
                filter.soldDateRange = payload.soldDateRange;
            }
        } else if (payload.searchPropertyStatus === 'ACTIVE') {
            filter.hidePending = 1;
        }
    }
    if (payload.schoolRequest && payload.schoolRequest.length > 0) {
        let school = payload.schoolRequest[0];
        filter.schoolId = school.schoolId;
        filter.schoolHigh = school.schoolLevel === 'HIGH' ? 1 : 0;
        filter.schoolMiddle = school.schoolLevel === 'MIDDLE' ? 1 : 0;
        filter.schoolElementary = school.schoolLevel === 'ELEMENTARY' ? 1 : 0;
        filter.schoolPre = school.schoolLevel === 'PRE_SCHOOL' ? 1 : 0;
        filter.schoolPublic = school.schoolType === 'PUBLIC' ? 1 : 0;
        filter.schoolCharter = school.schoolType === 'CHARTER' ? 1 : 0;
        filter.schoolPrivate = school.schoolType === 'PRIVATE' ? 1 : 0;
        filter.schoolMinRate = school.minRating;
        filter.schoolIncludeUnrated = school.includeUnrated ? 1 : 0;
    }
    if (payload.pageIndex !== undefined) {
        filter.pageIndex = payload.pageIndex;
    }
    if (payload.maxCountPerPage !== undefined) {
        filter.pageSize = payload.maxCountPerPage;
    }
    if (payload.maxLat && payload.minLat && payload.maxLng && payload.minLng) {
        filter.coordinates = {
            ne: {
                lat: payload.maxLat,
                lng: payload.maxLng,
            },
            sw: {
                lat: payload.minLat,
                lng: payload.minLng,
            },
        };
    }
    return filter;
}
export function getSearchPropertyStatusByFilter(filter) {
    filter = filter || {};
    let searchPropertyStatus = 'ACTIVE_AND_PENDING';
    if (filter.rentals) {
        searchPropertyStatus = 'FOR_RENT';
    } else if (filter.soldDateRange) {
        searchPropertyStatus = 'INACTIVE';
    } else if (filter.hidePending) {
        searchPropertyStatus = 'ACTIVE';
    }
    return searchPropertyStatus;
}
export function getPayloadFilterByFilter(filter) {
    let payload = {
        isDistressed: 0,
        pageIndex: 1,
        isOpenHousesOnly: 0,
        isNewListingsOnly: 0,
        isReducedPrice: 0,
        isVirtualTourLinkOnly: 0,
        isRental: 0,
        maxCountPerPage: 50,
        isNewConstruction: 0,
        attributesTags: [],
        isFixerUpper: 0,
        hasPhoto: 0,
        hasPool: 0,
        minLat: 0,
        maxLat: 0,
        minLng: 0,
        maxLng: 0,
        searchPropertyStatus: 'ACTIVE_AND_PENDING',
        schoolDistricts: null,
        luxuryHomes: false,
        expandListingAmount: 0,
        movotoListing: 0,
        isHomeRoam: false,
        propertyTypes: [],
    };
    if (!filter) {
        return payload;
    }

    if (filter.rentals) {
        payload.searchPropertyStatus = 'FOR_RENT';
        payload.isRental = 1;
    } else if (filter.soldDateRange) {
        payload.searchPropertyStatus = 'INACTIVE';
        payload.soldDateRange = filter.soldDateRange;
        //fromMapSearch=true to limit the search result.
        payload.fromMapSearch = true;
    } else if (filter.hidePending) {
        payload.searchPropertyStatus = 'ACTIVE';
    }

    if (filter.propertyType && filter.propertyType.length > 0) {
        payload.propertyTypes = filter.propertyType.map((e) => _getKeyByValue(propertyTypes, e) || e);
    }

    if (filter.ojoAttribute && filter.ojoAttribute.length > 0) {
        payload.attributesTags = filter.ojoAttribute;
    }

    if (filter.propertyCriteriaOpenHouse) {
        payload.isOpenHousesOnly = 1;
    }

    if (filter.updateNew) {
        payload.isNewListingsOnly = 1;
    }

    if (filter.updatePriceReduced) {
        payload.isReducedPrice = 1;
    }

    if (filter.updateVirtualTour) {
        payload.isVirtualTourLinkOnly = 1;
    }

    if (filter.hasVideo) {
        payload.hasVideo = true;
    }

    //not sure we are still using it or not
    // if (filter.propertyCriteriaLuxuryHome) {
    //     payload.luxuryHomes = true;
    // }
    // if (filter.propertyCriteriaForeclosed) {
    //     payload.propertyCriteriaForeclosed = 1;
    // }

    if (filter.updateNewConstruction) {
        payload.isNewConstruction = 1;
    }
    if (filter.minPrice !== undefined) {
        payload.minPrice = filter.minPrice;
    }
    if (filter.maxPrice !== undefined) {
        payload.maxPrice = filter.maxPrice;
    }

    if (filter.minBed !== undefined) {
        payload.minBed = filter.minBed;
    }

    if (filter.maxBed !== undefined) {
        payload.maxBed = filter.maxBed;
    }

    if (filter.minBath !== undefined) {
        payload.minBath = filter.minBath;
    }

    if (filter.maxBath !== undefined) {
        payload.maxBath = filter.maxBath;
    }

    if (filter.minHoa >= 0) {
        payload.minHoa = filter.minHoa;
    }

    if (filter.maxHoa >= 0) {
        payload.maxHoa = filter.maxHoa;
    }

    if (filter.minHouseSize !== undefined) {
        payload.minSqft = filter.minHouseSize;
    }

    if (filter.maxHouseSize !== undefined) {
        payload.maxSqft = filter.maxHouseSize;
    }

    if (filter.minLotSize !== undefined) {
        payload.minLot = filter.minLotSize;
    }

    if (filter.maxLotSize !== undefined) {
        payload.maxLot = filter.maxLotSize;
    }

    if (filter.minYearBuild !== undefined) {
        payload.minYear = filter.minYearBuild;
    }
    if (filter.maxYearBuild !== undefined) {
        payload.maxYear = filter.maxYearBuild;
    }
    if (filter.minDOM !== undefined) {
        payload.minDom = filter.minDOM;
    }
    if (filter.maxDOM !== undefined) {
        payload.maxDom = filter.maxDOM;
    }

    if (filter.school) {
        payload.schoolRequest = [
            {
                schoolId: filter.school.id,
                schoolBoundaryId: filter.school.boundaryId,
                location: {
                    lat: filter.school.location.lat,
                    lng: filter.school.location.lon,
                },
            },
        ];
    }

    const sortTypes = {
        dom: 'DOM',
        price: 'PRICE',
        sqft: 'SQFT',
        pricepersqft: 'PRICEPERSQFT',
        beds: 'BEDS',
        baths: 'BATHS',
        distance: 'CLOSEST',
        lot: 'LOT_SIZE',
        investment: 'INVESTMENT_OPPORTUNITY'
    };

    if (filter.sort) {
        let [sortColumn, sortOrder] = filter.sort.replace('sortby-', '').split('-');
        payload.sortColumn = sortTypes[sortColumn];
        payload.sortOrder = sortOrder;
    }

    if (filter.fixerupper) {
        payload.isFixerUpper = 1;
    }

    if (filter.ojoAttribute && filter.ojoAttribute.indexOf('pool') > -1) {
        payload.hasPool = 1;
    }

    if (filter.ojoAttribute && filter.ojoAttribute.indexOf('garage') > -1) {
        payload.garage = 1;
    } else {
        payload.garage = 0;
    }

    if (filter.photos) {
        payload.hasPhoto = 1;
    }

    if (Array.isArray(filter.coordinates) && filter.coordinates.length >= 4) {
        let [lat1, lng1, lat2, lng2] = filter.coordinates;
        if (lat1) {
            payload.maxLat = lat1;
            payload.maxLng = lng1;
            payload.minLat = lat2;
            payload.minLng = lng2;
            payload.isHomeRoam = filter.isHomeRoam;
        }
    } else if (filter.coordinates && filter.coordinates.ne && filter.coordinates.sw) {
        if (filter.coordinates.ne.lat) {
            payload.maxLat = filter.coordinates.ne.lat;
            payload.maxLng = filter.coordinates.ne.lng;
            payload.minLat = filter.coordinates.sw.lat;
            payload.minLng = filter.coordinates.sw.lng;
            payload.isHomeRoam = filter.isHomeRoam;
        }
    }

    if (filter.pageIndex) {
        payload.pageIndex = filter.pageIndex;
    }

    if (filter.propertyListedMovoto) {
        payload.movotoListing = 1;
    }
    return payload;
}
//Returns subtext for filters
export function getSubTextForFilter(input) {
    //if it's payload searchCondition
    var bedText = '';
    var bathText = '';
    var PriceText = '';
    var iconText = '';
    var subText = '';
    let filter;
    if (filter && filter.searchType) {
        filter = getFilterByPayloadFilter(input);
    } else {
        filter = input;
    }
    if (filter.propertyType && filter.propertyType.length > 0) {
        let pType = '';
        if (filter.propertyType.length === 1) {
            let type = propertyTypes[filter.propertyType[0]] || filter.propertyType[0];
            pType= type;
        } else {
            let types = filter.propertyType.map((e) => propertyTypes[e] || e);
            //remove duplicate item in types
            types = [...new Set(types)];
            pType = types;
        }
        if (pType.includes(propertyTypes['SINGLE_FAMILY_HOUSE'])) {
            iconText += '<i class="icon-property-single-family"></i>';
        }
        if (pType.includes(propertyTypes['CONDO'])) {
            iconText += '<i class="icon-property-condo"></i>';
        }
        if (pType.includes(propertyTypes['MULTI_FAMILY'])) {
            iconText += '<i class="icon-property-multi-family"></i>';
        }
        if (pType.includes(propertyTypes['LAND'])) {
            iconText += '<i class="icon-property-lot"></i>';
        }
        if (pType.includes(propertyTypes['MOBILE'])) {
            iconText += '<i class="icon-property-mobile"></i>';
        }
        if (pType.includes(propertyTypes['OTHER'])) {
            iconText += '<i class="icon-property-other"></i>';
        }
        subText = iconText + ' ' + subText;
    }
    if (filter.minBed > 0 || filter.maxBed > 0) {
        bedText = filter.minBed + '+ Beds';
    }
    if (filter.minBath > 0 || filter.maxBath > 0) {
        bathText = filter.minBath + '+ Baths';
    }
    bedText += (bathText ? ', ' : '') + bathText;

    if (filter.minPrice > 0 || filter.maxPrice > 0) {
        const minPrice = Number(filter.minPrice);
        const maxPrice = Number(filter.maxPrice);
        if (minPrice && !maxPrice) {
            PriceText = (bedText ? ', ' : '') + 'More than $' + format.friendlyPrice(minPrice);
        } else if (!minPrice && maxPrice) {
            PriceText = (bedText ? ', ' : '') + 'Less than $' + format.friendlyPrice(maxPrice);
        } else if (minPrice && maxPrice) {
            PriceText = (bedText ? ', ' : '') + '$' + format.friendlyPrice(minPrice) + ' to $' + format.friendlyPrice(maxPrice);
        } else {
            PriceText = '';
        }
    }
    subText += bedText + PriceText;
    return subText;
}

