export default {
    SignIn: 'Sign in',
    Join: 'Join',
    SignOut: 'Sign out',
    Home: 'Home',
    Notification: 'Notification',
    Feed: 'Feed',
    savedHomes: 'Favorite homes',
    Buy: 'Buy',
    Rent: 'Rent',
    Homeowner: 'Owned homes',
    ownerDashBoard: 'Homeowner dashboard',
    BuyingPower: 'Buying power',
    Mortgage: 'Mortgage',
    MortgageLoan: 'Loan',
    MortgageCalculator: 'Calculator',
    MortgageRates: 'Mortgage rates',
    GetPreApproved: 'Preapproval',
    GetInsuranceQuotes: 'Insurance quotes',
    HomeBuyingChecklist: 'Home buying checklist',
    HomesForSaleNearMe: 'Homes for sale near me',
    HomesForRentNearMe: 'Homes for rent near me',
    ApartmentsForRentNearMe: 'Apartments for Rent Near Me',
    HousesForRentNearMe: 'Houses for Rent Near Me',
    HomesforSale: '$baseName homes for sale',
    ApartmentsForRent: '$baseName apartments for rent',
    ApartmentsForSale: '$baseName condos for sale',
    HousesForRent: '$baseName homes for rent',
    HousesForSale: '$baseName single family for sale',
    multiFamily: '$baseName multi family/duplex for sale',
    land: '$baseName land for sale',
    RentalHomes: '$baseName rentals',
    OpenHouses: '$baseName open houses',
    RecentPriceReductions: '$baseName price reductions',
    RefinanceRates: 'Refinance rates',
    Sell: 'Sell',
    CashOffer: 'Cash offer',
    NoObligationCashOffer: 'No Obligation Cash Offer',
    GetaHomeValuation: 'Home value',
    HomeSaleCalculator: 'Sale calculator',
    HomeRepairServiceProviders: 'Repair & service providers',
    MeetanAgent: 'Meet an agent',
    BecomeaPartnerAgent: 'Become a Partner Agent',
    Agent: 'Agent',
    AgentsSearch: 'Top agents in $baseName ',
    AgentDirectory: 'Create Agent Profile',
    CreateanAgentProfile: 'Join agent network',
    downloadApp: 'Download app',
    myAgent: 'My agent',
    savedSearches: 'Saved searches',
    profile: 'Profile',
    settings: 'Settings',
    AgentHelp: 'Agent Help',
    Keywords: 'Homes for Sale, House for Sale, Real Estate Agents, Real Estate Listings, Find a Home, MLS Listings, Realtors, MLS listings, Movoto',
    HotleadTitle: 'Connect with an Agent',
    HotleadButtionCap: 'Connect Now',
    brokerageoffices: '$baseName brokerage office',
    veteranMortgage: 'Veterans & VA home loans',
    veteranResources: 'Veteran resources',
    sellHome: "Sell my home",
};
