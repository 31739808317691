export default function () {
    return {
        newDom: 0,
        ojoAttribute: [],
        searchTerm: null,
        coordinates: null,
        minBed: 0,
        maxBed: 0,
        minBath: 0,
        maxBath: 0,
        soldDateRange: 0,
        minPrice: 0,
        maxPrice: 0,
        propertyType: [],
        minHouseSize: 0,
        maxHouseSize: 0,
        minLotSize: 0,
        maxLotSize: 0,
        minYearBuild: 0,
        maxYearBuild: 0,
        minDOM: 0,
        maxDOM: 0,
        minHoa: -1,
        maxHoa: -1,
        pool: 0,
        fixerupper: 0,
        isActive: 1,
        updateNew: 0,
        propertyCriteriaLuxuryHome: 0,
        propertyCriteriaOpenHouse: 0,
        updatePriceReduced: 0,
        updateVirtualTour: 0,
        updateNewConstruction: 0,
        propertyCriteriaForeclosed: 0,
        propertyListedMovoto: 0,
        hidePending: 0,
        photos: 0,
        garage: 0,
        sort: null,
        schoolHigh: 0,
        schoolMiddle: 0,
        schoolElementary: 0,
        schoolPre: 0,
        schoolPublic: 0,
        schoolCharter: 0,
        schoolPrivate: 0,
        schoolMinRate: 1,
        schoolIncludeUnrated: 1,
        schoolId: null,
        rentals: 0,
        center: null,
        searchPropertyStatus: null
    };
}
