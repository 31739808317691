import { locationFilter, getPathByFilter, getFilterByPath, getSubTextForFilter, getSearchPropertyStatusByFilter } from './filterhelper';
import { getGeoByGoogleAddress, getGeoByData, getSearchInputByGeo, getGeoByPath } from './geohelper';
import { absolutePath } from './pathHelper';

function _convertGeo(obj) {
    if (obj.address_components) {
        return getGeoByGoogleAddress(obj);
    }
    return getGeoByData(obj);
}

function _getSaveSearchByGeo(geo, filter) {
    if (!geo) {
        return null;
    }
    let searchPath = geo.path;
    let displayText = '';
    let subText = '';
    if (filter) {
        let filterPath = getPathByFilter(filter, true);
        if (filterPath) {
            searchPath += filterPath;
            subText = getSubTextForFilter(filter);
        }
    }
    displayText = getSearchInputByGeo(geo);
    return {
        url: absolutePath(searchPath),
        displayText,
        subText,
    };
}

export function getSaveSearchByPath(path, excludeFilter) {
    if (path.indexOf('/for-sale/') >= 0) {
        if (!locationFilter.test(path)) {
            return null;
        }
        return {
            url: absolutePath(path),
            displayText: '',
        };
    } else {
        let { geo, filter } = getSearchObjByPath(path);
        if (excludeFilter) {
            return _getSaveSearchByGeo(geo);
        }
        return _getSaveSearchByGeo(geo, filter);
    }
}

export function getSaveSearchByGeo(geo, filter) {
    let geoObj = _convertGeo(geo);
    return _getSaveSearchByGeo(geoObj, filter);
}

export function getPathBySearchObj(geo, filter, skipRealTimeParams) {
    let geoObj = _convertGeo(geo);
    let geoPath = geoObj.path;
    let filterPath = '';
    if (filter) {
        filterPath = getPathByFilter(filter, skipRealTimeParams);
    }
    if (geoPath === '' && !filter.rentals) {
        geoPath = 'for-sale/';
    }
    return `${geoPath}${filterPath}`;
}

export function getSearchObjByPath(path) {
    let geo = getGeoByPath(path);
    let filter = getFilterByPath(path);
    return {
        geo,
        filter,
    };
}

export function getSearchPropertyStatusBySearchObj(filter) {
    return getSearchPropertyStatusByFilter(filter);
}
