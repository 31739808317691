const isSSR = typeof window === 'undefined';

export default (app) => {
    app.directive('tip', isSSR ? {} : {
        beforeMount(el) {
            var eventName = 'ontouchstart' in window ? 'touchstart' : 'mouseenter';
            $(el).on(eventName, (e) => {
                var $parent = $(el).offsetParent();
                var ewidth = $(el).width();
                var pwidth = $parent.width();
                var left = $(el).position().left;
                if(left<120){
                    $(el).addClass('left');
                    $(el).removeClass('right');
                }else if(left+ewidth > pwidth-120){
                    $(el).addClass('right');
                    $(el).removeClass('left');
                    document.querySelector('[tip].right').style.setProperty('--right-spacing', (left+ewidth-pwidth)+'px')
                }else{
                    $(el).removeClass('left');
                    $(el).removeClass('right');
                }
            });
        }
    });
};
