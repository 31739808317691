import tags from '../store/glb/data.tags.js';
import { v4 as uuidv4 } from 'uuid';
import { fetchCachedClientTreatments, formatSplitsForGA4 } from '../components/split/utils.js';

const isSSR = typeof window === 'undefined';

const timePeriod = 30 * 60 * 1000;

const _getParam = function (p) {
    var match = RegExp('[?&]' + p + '=([^&?]*)').exec(window.location.search);
    return match && decodeURIComponent(match[1].replace(/\+/g, ' '));
};
if (!isSSR) {
    window.baseTrack = null;
}
let realTimeCtx = null;

function getUserTrack(glb) {
    if (glb && glb.user) {
        return {
            user_id: glb.user.id,
            user_name: glb.user.fullName,
            user_email: glb.user.email,
            user_phone: glb.user.phone,
            user_role: glb.user.role,
            user_ojoId: glb.user.ojoId,
            user_activated: glb.user.activated,
        };
    }
    return {};
}

function getBaseTrack(glb) {
    const timeStamp = Date.now();
    const expiredTime = new Date(timeStamp + timePeriod);
    let userId = glb.user && glb.user.id;
    let isLoginLanding;
    let logined = isSSR ? false : $.getStorage('isLogin');
    if (userId) {
        if (!logined) {
            isLoginLanding = true;
            !isSSR && $.setStorage('isLogin', true);
        }
    } else if (logined) {
        !isSSR && $.setStorage('isLogin', false);
    }
    let sessionId = !isSSR && $.getCookie('MOVOTOSESSIONID');
    let isLanding = false;
    if (!sessionId) {
        sessionId = uuidv4();
        isLanding = true;
        !isSSR && $.setStorage('utm_campaign', '');
        !isSSR && $.setStorage('utm_source', '');
        !isSSR && $.setStorage('utm_medium', '');
    }
    //todo
    !isSSR && $.setCookie('MOVOTOSESSIONID', sessionId, expiredTime);

    let queries = {};
    if (!isSSR && window.location.search) {
        queries = $.parseQueryString(window.location.search);
        if (queries && $.trimEmojis(queries.utm_campaign)) {
            $.setStorage('utm_campaign', $.trimEmojis(queries.utm_campaign));
        }
        if (queries && $.trimEmojis(queries.utm_source)) {
            $.setStorage('utm_source', $.trimEmojis(queries.utm_source));
        }
        if (queries && $.trimEmojis(queries.utm_medium)) {
            $.setStorage('utm_medium', $.trimEmojis(queries.utm_medium));
        }
        if (queries && $.trimEmojis(queries.utm_term)) {
            $.setStorage('utm_term', $.trimEmojis(queries.utm_term));
        }
        if (queries && $.trimEmojis(queries.utm_content)) {
            $.setStorage('utm_content', $.trimEmojis(queries.utm_content));
        }
        if (queries && $.trimEmojis(queries.utm_campaign_operator)) {
            $.setStorage('utm_campaign_operator', $.trimEmojis(queries.utm_campaign_operator));
        }
        if (queries && $.trimEmojis(queries.utm_master_campaign)) {
            $.setStorage('utm_master_campaign', $.trimEmojis(queries.utm_master_campaign));
        }
    }
    var user = getUserTrack(glb);
    glb.utm_campaign = (!isSSR && $.getStorage('utm_campaign')) || '';
    glb.utm_source = (!isSSR && $.getStorage('utm_source')) || '';
    glb.utm_medium = (!isSSR && $.getStorage('utm_medium')) || '';
    glb.utm_term = (!isSSR && $.getStorage('utm_term')) || '';
    glb.utm_content = (!isSSR && $.getStorage('utm_content')) || '';
    glb.utm_campaign_operator = (!isSSR && $.getStorage('utm_campaign_operator')) || '';
    glb.utm_master_campaign = (!isSSR && $.getStorage('utm_master_campaign')) || '';
    if (!glb.utm_campaign && !glb.utm_source && !glb.utm_medium) {
        $.setCookie('TRAFFIC', 'organic');
    } else {
        $.removeCookie('TRAFFIC', glb.utm_source || glb.utm_medium || glb.utm_campaign);
    }
    return {
        //The platform (or vendor) where the traffic originates, like Facebook twiiter, Google, etc.
        utm_source: queries.utm_source || '',
        //identify the medium like Cost Per Click (CPC), social media, affiliate, or QR code
        utm_medium: queries.utm_medium || '',
        //Campaign name for IDentifying the source of the traffic
        utm_campaign: queries.utm_campaign || '',
        utm_term: queries.utm_term || '',
        utm_content: queries.utm_content || '',
        utm_campaign_operator: queries.utm_campaign_operator || '',
        utm_master_campaign: queries.utm_master_campaign || '',
        //info per session, it will be reset when no action in next 30min
        session_id: sessionId,
        session_campaign: glb.utm_campaign || '',
        session_source: glb.utm_source || '',
        session_medium: glb.utm_medium || '',
        session_term: glb.utm_term || '',
        session_content: glb.utm_content || '',
        session_campaign_operator: glb.utm_campaign_operator || '',
        session_master_campaign: glb.utm_master_campaign || '',
        session_split: formatSplitsForGA4(glb.splits, fetchCachedClientTreatments()),
        origin: location.origin,
        href: location.href,
        path: location.pathname,
        search: location.search,
        hash: location.hash,
        id: glb.movotoDeviceID || (!isSSR && $.getCookie('MOVOTODEVICEID')) || '',
        width: window.innerWidth,
        height: window.innerHeight,
        ratio: window.devicePixelRatio,
        os: glb.OS || '',
        version: glb.version || '',
        language: glb.language || '',
        ip: glb.ip || '',
        split: formatSplitsForGA4(glb.splits, fetchCachedClientTreatments()),
        mode: 'light',
        isMobile: glb.isMobile,
        isPhone: glb.isPhone,
        isUserBrowser: glb.isUserBrowser,
        isLanding: isLanding,
        isLoginLanding: isLoginLanding,
        ...user,
    };
}

function getRealTimeCtx({ pageType, glb, dpp, msp }) {
    let ctx = {
        ...(isSSR ? {} : window.baseTrack),
        ...{
            pageType: pageType || (glb && glb.pageType),
        },
        ...getUserTrack(glb),
    };

    if (glb && glb.agent) {
        ctx.agent_id = glb.agent.id;
        ctx.agent_name = glb.agent.fullName;
        ctx.agent_type = glb.agent.type;
        ctx.agent_email = glb.agent.email;
        ctx.agent_phone = glb.agent.phone;
    }

    let geoInfo;
    if (dpp && dpp.geo) {
        geoInfo = dpp.geo;
    } else if (msp && msp.geo) {
        geoInfo = msp.geo;
    } else {
        geoInfo = glb && glb.geo;
    }

    if (geoInfo) {
        ctx.address = geoInfo.address;
        ctx.zipCode = geoInfo.zipcode;
        ctx.county = geoInfo.county;
        ctx.neighborhood = geoInfo.neighborhood;
        ctx.city = geoInfo.city;
        ctx.county = geoInfo.county;
        ctx.state = geoInfo.state;
    }

    if (dpp && dpp.propertyId) {
        ctx.dpp_id = dpp.propertyId;
        ctx.dpp_price = dpp.price;
        ctx.dpp_bed = dpp.bed;
        ctx.dpp_bath = dpp.bath;
        ctx.dpp_area = dpp.area;
        ctx.dpp_dom = dpp.daysOnMovoto;
        ctx.dpp_type = dpp.propertyType;
        ctx.dpp_status = dpp.houseRealStatus;
        ctx.dpp_mlsId = dpp.mlsDbNumber;
        ctx.dpp_mlsNumber = dpp.mlsNumber || '';
        ctx.dpp_photoUrl = dpp.photoUrl;
        ctx.dpp_path = dpp.path;
        ctx.dpp_photoPath = dpp.tnImgPath;
        ctx.dpp_photoCount = dpp.photoCount;
        ctx.dpp_createDate = dpp.createdAt;
        ctx.dpp_listDate = dpp.listDate;
        ctx.dpp_soldDate = dpp.soldDate;
        ctx.dpp_isFavorite = dpp.isFavorite;
        ctx.dpp_isHot = dpp.isHot;
        ctx.dpp_yearBuilt = dpp.yearBuilt;
        ctx.dpp_hoafee = dpp.hoafee;
        ctx.dpp_lotSize = dpp.lotSize;
        ctx.dpp_sqftTotal = dpp.sqftTotal;
    }

    if (msp) {
        ctx.msp_viewStatus = msp.currentViewStatus;
        ctx.msp_pageIndex = msp.firstPageIndex;
        ctx.msp_pageCount = msp.pageCount;
        ctx.msp_totalCount = msp.totalCount;
        if (msp.filter) {
            ctx.msp_minBed = msp.filter.minBed;
            ctx.msp_maxBed = msp.filter.maxBed;
            ctx.msp_minBath = msp.filter.minBath;
            ctx.msp_maxBath = msp.filter.maxBath;
            ctx.msp_photos = msp.filter.photos;
            ctx.msp_minPrice = msp.filter.minPrice;
            ctx.msp_maxPrice = msp.filter.maxPrice;
            ctx.msp_minHouseSize = msp.filter.minHouseSize;
            ctx.msp_soldDateRange = msp.filter.soldDateRange;
            ctx.msp_ojoAttribute = msp.filter.ojoAttribute;
            ctx.msp_searchTerm = msp.filter.searchTerm;
            ctx.msp_coordinate = msp.filter.coordinates;
            ctx.msp_propertyType = msp.filter.propertyType;
            ctx.msp_minLotSize = msp.filter.minLotSize;
            ctx.msp_maxLotSize = msp.filter.maxLotSize;
            ctx.msp_minYearBuild = msp.filter.minYearBuild;
            ctx.msp_maxYearBuild = msp.filter.maxYearBuild;
            ctx.msp_minDOM = msp.filter.minDOM;
            ctx.msp_maxDOM = msp.filter.maxDOM;
            ctx.msp_minHoa = msp.filter.minHoa;
            ctx.msp_maxHoa = msp.filter.maxHoa;
            ctx.msp_pool = msp.filter.pool;
            ctx.msp_fixerupper = msp.filter.fixerupper;
            ctx.msp_isActive = msp.filter.isActive;
            ctx.msp_isNew = msp.filter.updateNew;
            ctx.msp_isOpenHouse = msp.filter.propertyCriteriaOpenHouse;
            ctx.msp_isPriceReduced = msp.filter.updatePriceReduced;
            ctx.msp_isVirtualTour = msp.filter.updateVirtualTour;
            ctx.msp_isNewConstruction = msp.filter.updateNewConstruction;
            ctx.msp_isListedMovoto = msp.filter.propertyListedMovoto;
            ctx.msp_hidePending = msp.filter.hidePending;
            ctx.msp_hasPhotos = msp.filter.photos;
            ctx.msp_hasGarage = msp.filter.garage;
            ctx.msp_sort = msp.filter.sort;
            ctx.msp_isRental = msp.filter.rentals;
            ctx.msp_schoolId = msp.filter.schoolId;
        }
    }
    return ctx;
}

function getValue(name, req, res) {
    if (!res) {
        return '';
    }
    if (name === 'search' && res.data) {
        return res.data.totalCount;
    }
    if (name === 'fetchdpp' && res.data && res.data.geo) {
        return res.data.geo.address;
    }
    if (res && res.status && res.status.msg) {
        return res.status.msg;
    }
    return '';
}
function getLabel(name, req, res) {
    return '';
}
function getResult(name, req, res) {
    return {
        label: getLabel(name, req, res),
        value: getValue(name, req, res),
    };
}
function setTrackData({ category, action, label, value, data = {} }) {
    if (action === 'load-page' || !realTimeCtx) {
        realTimeCtx = getRealTimeCtx(data);
    }

    let req = null;
    let res = null;
    if (action === 'request') {
        let result = getResult(category, data.req, data.res);
        if (result.label) {
            label = result.label;
        }
        if (result.value) {
            value = result.value;
        }
        req = data.req;
        res = data.res;
    }

    if (!isSSR && !window.trackData) {
        window.trackData = [];
    }
    let endTime = new Date();
    let startTime = data.startTime;
    let track = {
        ...{
            //category is the target of tracking
            category: category,
            //action is trigger type
            action: action,
            //label is the trigger of tracking
            label: label,
            //value the result after trigger or key info of the trigge
            value: value,
        },
        ...{
            time: startTime || endTime,
            cost: startTime ? endTime - startTime : 0,
        },
        req: req,
        res: res,
        ...realTimeCtx,
    };
    !isSSR && window.trackData.push(track);
    return track;
}
function initTrack(glb) {
    window.baseTrack = getBaseTrack(glb);
    $(document).on('glb.track', (e, data) => {
        let isLanding = window.baseTrack.isLanding;
        let isLoginLanding = window.baseTrack.isLoginLanding;
        if (isLanding) {
            window.baseTrack.isLanding = false;
            let track = setTrackData({
                category: glb.pageType,
                action: 'landing',
            });
            $(document).trigger('dom.track', track);
        }
        if (isLoginLanding) {
            window.baseTrack.isLoginLanding = false;
            let track = setTrackData({
                category: glb.pageType,
                action: 'login',
            });
            $(document).trigger('dom.track', track);
        }
        let track = setTrackData(data);
        $(document).trigger('dom.track', track);
    });
}
function initialByWindow() {
    window.hashTable = {};
}

function initialByCookies(glb) {
    let gclid = _getParam('gclid');
    if (gclid) {
        if (gclid.includes('@')) {
            gclid = gclid.split('@')[0];
        }
        $.getCookie('gclid') !== gclid && $.setCookie('gclid', gclid);
    } else {
        gclid = $.getCookie('gclid');
    }
    glb.gclid = gclid || '';
}
function initialByStorage(glb) {
    glb.viewedListings = $.getStorage('movoto.viewedlistings') || [];
    glb.marketSummary = $.getStorage('marketSummary') || {};
    let localHotleadInfo = $.getStorage('holeadInfo') || {};
    if (!glb.hotleadInfo) {
        glb.hotleadInfo = localHotleadInfo;
    } else if (localHotleadInfo.fullName && !glb.hotleadInfo.fullName) {
        glb.hotleadInfo.fullName = localHotleadInfo.fullName;
    }
    let landingPage = $.getSessionStorage('landingPage');
    if (!landingPage) {
        landingPage = (glb.pageInfo && glb.pageInfo.value) || '';
        $.setSessionStorage('landingPage', landingPage);
    }
    let landingPageType = $.getSessionStorage('landingPageType');
    if (!landingPageType) {
        landingPageType = (glb.pageInfo && glb.pageInfo.fullPageType) || '';
        $.setSessionStorage('landingPageType', landingPageType);
    }
    let landingPagePath = $.getSessionStorage('landingPagePath');
    if (!landingPagePath) {
        landingPagePath = location.pathname;
        $.setSessionStorage('landingPagePath', landingPagePath);
    }
    let firstVisitedPageType = $.getStorage('firstVisitedPageType');
    if (!firstVisitedPageType) {
        $.setStorage('firstVisitedPageType', glb.pageType);
    }
    glb.firstVisitedPageType = firstVisitedPageType || '';
    let acquisitionChannel = $.getSessionStorage('acquisitionChannel');
    if (!acquisitionChannel) {
        acquisitionChannel = '';
        if (_getParam('gclid') || _getParam('adid')) {
            acquisitionChannel = 'SEM';
        } else if (document.referrer.includes('google.com')) {
            acquisitionChannel = 'SEO';
        }
        $.setSessionStorage('acquisitionChannel', acquisitionChannel);
    }
    glb.tags = Object.assign(tags, $.getStorage('tags') || {});
    glb.recentlyViewedTags = $.getStorage('recentlyViewedTags') || [];
    glb.landingPage = landingPage || '';
    glb.landingPageType = landingPageType || '';
    glb.landingPagePath = landingPagePath || '';
    glb.acquisitionChannel = acquisitionChannel || '';
    glb.feed = $.getStorage('feed') || [];
    glb.digsSubscribe = $.getStorage('digsSubscribe') || [];
}

function initialClient(glb) {
    if (!isSSR) {
        $.initalStorage();
        initialByCookies(glb);
        initialByStorage(glb);
        initialByWindow(glb);
        initTrack(glb);
    }
}

if (!isSSR) {
    window.checkSplit = function (key, value) {
        // get end digit from string split
        const treatment = `${key.match(/\d+$/)[0]}|${value}`;
        const split_treatments = window.google_tag_manager[window.__INITIAL_STATE__.ga4Measurement].dataLayer.get('pageData').split_treatments;
        if (split_treatments && split_treatments.includes(treatment)) {
            return false;
        }
        $.removeCookie('MOVOTODEVICEID');
        location.reload();
    };
    window.getTrackData = function () {
        console.log(
            trackData.map((item) => {
                return [item.action, item.category, item.label, item.value, item.cost + 'ms', item.time.toLocaleString()];
            })
        );
    };
}

export default initialClient;
