//Detect Browser Type
var _browserType = null;
var _version = null;
export default {
    browserType: function(){
        if(_browserType !== null){
            return _browserType;
        }
        var agent = window.navigator.userAgent.toLowerCase();
        if(agent.indexOf('edg') > -1){
            _browserType = 'edge';
        }else if(agent.indexOf('opr') > -1 && !!window.opr){
            _browserType = 'opera';
        }else if(window.navigator.brave){
            _browserType = 'brave';
        }else if((agent.indexOf('chrome') > -1 && !!window.chrome) || agent.indexOf('crios') > -1){
            _browserType = 'chrome';
        }else if(agent.indexOf('trident') > -1){
            _browserType = 'ie';
        }else if(agent.indexOf('firefox') > -1){
            _browserType = 'firefox';
        }else if(agent.indexOf('safari') > -1){
            _browserType = 'safari';
        }else {
            _browserType = 'other';
        }
        return _browserType;
    },
    versions: function(){
        if(_version === null){
            var ua = navigator.userAgent;
            _version = {
                presto: ua.indexOf('Presto') > -1,
                trident: ua.indexOf('Trident') > -1,
                webKit: ua.indexOf('AppleWebKit') > -1,
                gecko: ua.indexOf('Gecko') > -1 && ua.indexOf('KHTML') === -1,
                android: ua.indexOf('Android') > -1 || ua.indexOf('Linux') > -1,
                ios: !!ua.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/),
                iPhone: ua.indexOf('iPhone') > -1 || ua.indexOf('Mac') > -1,
                iPad: ua.indexOf('iPad') > -1,
                webApp: ua.indexOf('Safari') === -1,
                mobile: !!ua.match(/AppleWebKit.*Mobile.*/) || !!ua.match(/AppleWebKit/)
            };
        }
        return _version;
    }
};