export default {
    "common": {
        "apartments": "Apartments",
        "forRent": "for Rent",
        "relatedLinks": "Related Links",
        "hotleadcomments": "I’d like to ask a question about ",
        "lookingForQuestion": "Didn’t Find What You’re Looking For?",
        "usQuestion": "Ask Us a Question",
        "any": "Any",
        "noMin": "No Min",
        "noMax": "No Max",
        "baths": "Bathrooms",
        "none": "None",
        "marketOverview": "Market Overview",
        "saveSearchLink": "Save This Search",
        "saveSearchDesc": "to get emails when there are new listings for",
        "homesForSale": "Homes for Sale",
        "newHomes": "New Homes",
        "openHouses": "Open Houses",
        "median": "Median",
        "nearby": "Real Estate & Homes For Sale Nearby",
        "nearbyRent": "Apartments and Houses For Rent Nearby",
        "zipCodes": "ZIP Codes",
        "neighborhoods": "Neighborhoods",
        "nNeighborhoods":"Explore Neighborhoods",
        "cities": "Cities",
        "counties": "Counties",
        "showMore": "Show More",
        "showLess": "Show Less",
        "contactUs": "Contact Us",
        "search": "Search",
        "seeNearBy": "See More Nearby Homes",
        "realEstate": "Real Estate",
        "list": "List",
        "view": "View",
        "schools": "Schools",
        "expandSearch": "Remove Boundary",
        "backToMySearch": "Back to My Search",
        "satellite": "Satellite",
        "highSchool": "High School",
        "middleSchool": "Middle School",
        "preK": "Pre-K & Elementary",
        "public": "Public",
        "charter": "Charter",
        "private": "Private",
        "tourHomes": "Tour Homes in",
        "name": "Full Name",
        "email": "Email",
        "price": "Price",
        "phone": "Phone Number",
        "comments": "Comments or Questions",
        "buySellComments": "How can we help you?",
        "writeMsg": "Write Message",
        "optional": "Optional",
        "zipCode": "ZIP Code",
        "MobilePhone": "Mobile Phone",
        "leadFormDisclaimer": "By clicking you agree to receive from Movoto, its affiliates and network of real estate & mortgage professionals (or through their agents) marketing calls & texts (including via automatic dialing or pre-recorded voice technology) related to your inquiry to the number you provide. Consent not required for purchase.",
        "ojoLeadFormDisclaimer": "We'll call you within the next business hour. Click to consent to receive text messages, calls (direct, autodialed, or prerecorded even if on the Do Not Call list) and/or emails from Movoto or our partner. Consent is not required to receive Real Estate services.",
        "noCTALeadFormDisclaimer": "By proceeding you agree to receive from Movoto, its affiliates and network of real estate & mortgage professionals (or through their agents) marketing calls & texts (including via automatic dialing or pre-recorded voice technology) related to your inquiry to the number you provide. Consent not required for purchase.",
        "leadFormNHSDisclaimer": "You are creating a Movoto account and agree to our Terms of Use and Privacy Policy.",
        "scheduleTour": "Schedule a Tour",
        "comment": "Hi, I am interested in touring homes in $city with a top local real estate agent.",
        "commentWithAgent": "Hi, I am interested in touring homes in $city with $agentName.",
        "searchDisclaimer": "Listings with this icon are © $year of",
        "leadIDDisclaimer": "By submitting your information, you agree Movoto can share your information with its network of real estate & mortgage professionals (<a class='text-dotted' href='https://www.movoto.com/about/terms-of-use/#affiliates' target='_blank'>affiliates</a>) (or through their agents) and consent to receive marketing calls & texts related to your inquiry (including via automatic telephone dialing system, or artificial or pre-recorded voice technology) from Movoto or affiliates to the number you provided even if your number appears on the company, state, or national Do Not Call Registry. You further agree that such affiliates may share information regarding your potential loan status with Movoto.  Consent not required for purchase.",
        "disclaimers": "Disclaimers",
        "there": "There are",
        "schoolsIn": "schools in",
        "ratingsProvided": "Ratings provided by",
        "seeAll": "See all",
        "localAgent": "with a Local Real Estate Agent",
        "noHomes": "No Homes in this area",
        "legend": "Legend",
        "defaultSearchText": "Current Location",
        "insights": "Insights",
        "notRelevantResult": "Didn't Find What You <br> Are Looking For?",
        "accessibilityRequestDisclaimer": "Movoto Real Estate is committed to ensuring accessibility for individuals with disabilities. We are continuously working to improve the accessibility of our web experience for everyone. We welcome feedback and accommodation requests, please submit them ",
        "popularSearches": "Popular Searches",
        "featuredCities": "Featured Cities",
        "nearMe": "near Me",
        "singleFamilyForSale": "Houses for Sale",
        "condoAndTownhouses": "Condos/townhouses for Sale",
        "condoForSale": "Condos for Sale",
        "realEstateWithPool": "Real Estate with A Pool",
        "priceReducedHomesForSale": "Price Reduced Homes for Sale",
        "newListingsForSale": "New Listings for Sale",
        "newConstructionForSale": "New Construction Homes for Sale",
        "enquiryTypeError": "Please Select an Option",
        "threeDimensionalTour" : "3D Tour",
        "rentals": "Rental",
        "propertyRent": "Property Rentals",
        "seoLinksTitleTooltip": "$displayName, $stateCode Real Estate & Homes for Sale",
        "seoLinksTitleTooltipRent": "$displayName, $stateCode Real Estate & Homes for Rent",
        "seoLinksTitleTooltipShort": "$displayName, $stateCode Homes for Sale",
        "seoLinksTitleTooltipShortRent": "$displayName, $stateCode Homes for Rent",
        "reviewTitle": "Title of this review",
        "reviewDescription": "Describe your experience with this agent in detail… (optional)",
        "reviewService": "Service Provided",
        "reviewPurchased": "Year Purchased",
        "reviewPropertyAddress": "Full property address",
        "reviewShowCityState": "Only show city and state",
        "subject": "Subject",
        "description": "Description"
    },
    "filter": {
        "price": "Price",
        "beds": "Bedrooms",
        "anyPrice": "Any Price",
        "minPrice": "Min Price",
        "maxPrice": "Max Price",
        "anyBeds": "Beds",
        "anyBaths": "Baths",
        "type": ["Home Type", "Home Types"],
        "popertyType": "Type",
        "more": "More",
        "filter": "Filter",
        "less": "Less",
        "saveSearch": "Save<span class='hidden-sm'> Search</span>",
        "save": "Save",
        "map_result_marker_zero": "No Homes",
        "map_result_marker_cnt_lt_total_interval": [
            "Map Showing $pluralCount Home.",
            "Map Showing $pluralCount of $TOTALLISTINGCOUNT Homes. Zoom or Filter to See More."
        ],
        "map_result_marker_cnt_eql_total_interval": [
            "Map Showing $pluralCount Home.",
            "Map Showing $pluralCount of $TOTALLISTINGCOUNT Homes. Move Map to See More."
        ],
        "map_result_marker_cnt_total": "$pluralCount of $TOTALLISTINGCOUNT Homes",
        "unit": ["$unitCount Unit", "$unitCount Units"],
        "homeSize": "Home Size",
        "zipCodes": "ZIP Codes",
        "neighborhoods": "Neighborhoods",
        "cities": "Cities",
        "counties": "Counties",
        "searchError": "There’s no inventory based on your search criteria.",
        "adjustFilter": "Adjust filters to expand your search.",
        "resetFilter": "Reset your filters",
        "changeSearch": "Expand your search area",
        "changeSearchArea": "Change Search Area",
        "expandSearchMiles": "Expand Search"
    },
    "moreFilter": {
        "sold":"Sold",
        "all":"All",
        "last1Week":"1 Week",
        "last1Month":"1 Month",
        "last3Months":"3 Months",
        "last6Months":"6 Months",
        "last1Year":"1 Year",
        "last2Years":"2 Years",
        "squareFeet": "Square Feet",
        "noHOA": "No HOA",
        "noHOAFee": 'No HOA Fee',
        "type": "Home Type",
        "singleFamily": "House",
        "condo": "Condo/Townhouse",
        "houses": "House",
        "apartments": "Apartment",
        "condoLite": "Condo/Town<span class='hidden-sm hidden-xs'>house</span>",
        "multiFamily": "Multi-family",
        "lotLand": "Lot/Land",
        "mobile": "Mobile",
        "other": "Other",
        "lotSize": "Lot Size",
        "yearBuilt": "Year Built",
        "hoaFees": "HOA Fee",
        "maxHOAFees": "Max HOA Fees",
        "day": ["Day", "Days"],
        "daysOnMovoto": "Days on Movoto",
        "daysOn": "Days on",
        "features": ["Feature", "Features"],
        "pool": "Pool",
        "fixerUpper": "Fixer-Upper",
        "garage": ["Garage", "Garages"],
        "cancel": "Cancel",
        "reset": "Reset",
        "apply": "Apply",
        "status": "Status",
        "propertyStatus": "Property Status",
        "photos": "Photos",
        "forSale": "For Sale",
        "recentlySold": "Sold",
        "foreclosure": "Foreclosure/Short Sale",
        "newInLast7Days": "New",
        "openHouses": "Open House",
        "virtualTour": "3D Tour",
        "newConstruction": "New Construction",
        "priceReduced": "Price Cut",
        "hidePendingInContract": "Hide Pending/Contract",
        "hidePending": "Hide Pending",
        "hideContract": "Hide Contract",
        "hideListingWithoutPhotos": "Hide Listing without Photos",
        "showPendingOnMap": "Show Pending on Map",
        "listedByMovoto": "Listed By Movoto",
        "noResults" : "No Results",
        "propertyType": {
            "singleFamily": "Single Family",
            "multiFamily": "Multi Family",
            "condos": "Condos & Townhouses",
        },
        "newListings": "New Listings",
        "under": "Under",
        "over": "Over",
        "acres": "Acres",
    },
    "sort": {
        "sort": "Sort",
        "sortBy": "Sort by",
        "sortByColon": "Sort by:",
        "newest": "List Date",
        "relevant": "Relevant",
        "priceHigh": "Price High",
        "priceLow": "Price Low",
        "sqftBig": "Sqft Big",
        "sqftSmall": "Sqft Small",
        "lotBig": "Lot Big",
        "lotSmall": "Lot Small",
        "pricePerAreaUnit": "$ / Sqft",
        "distanceasc": "Distance",
        "updatedTimeDesc": "Updated"
    },
    "card": {
        "house": "House",
        "new": "New",
        "listed": "Listed",
        "open": "Open",
        "priceReduced": "Price Reduced",
        "forSale": "For Sale",
        "movotoPendingListing": "Movoto Pending Listing",
        "pending": "Pending",
        "activeUnderContract": "Active Under Contract",
        "underContract": "Under Contract",
        "comingSoon": "Coming Soon",
        "soldbyMovoto": "Sold by Movoto",
        "sold": "SOLD",
        "offMarket": "Off Market",
        "dELISTED": "DELISTED",
        "notForSale": "Not For Sale",
        "bed": "icon-bed",
        "bath": "icon-bath",
        "singleFamily": "House",
        "condo": "Condo/Townhouse",
        "multiFamily": "Multi-family Home",
        "lotLand": "Lot/Land",
        "mobile": "Mobile",
        "other": "Other",
        "photo": "Photo",
        "photos": "Photos",
        "minute_ago": ["Just Listed", "$pluralCount Minutes"],
        "hour_ago": ["1 Hour", "$pluralCount Hours"],
        "day_ago": ["1 Day", "$pluralCount Days"],
        "listedByMovoto": "Listed by Movoto",
        "newAgo": "New",
        "Bd": "Bd",
        "Ba": "Ba",
        "Sqft": "Sqft",
        "Acre": "Acre",
        "zeroDay" : ["1 Day", "$pluralCount Minutes"],
        "forRent": "For Rent",
        "condoRent": "Apartment",
        "multiFamilyRent": "Apartment",
        "providedBy": "Provided by"
    },
    "advantage": {
        "title": "The Movoto Advantage",
        "movoto1": "Movoto gives you access to the most up-to-the-minute $filterName information in $nearbyCity. As a licensed brokerage in $stateFullName (and across the United States), Movoto has access to the latest real estate data including",
        "singleFamily": "Single family for Sale",
        "multiFamily": "Multi family/Duplex for Sale",
        "condo": "Townhomes/Condos for Sale",
        "land": "Land for Sale",
        "mobile": "Mobile/Manufactured for Sale",
        "condos": "Condos for Sale near Me",
        "townhouses": "Townhouses for Sale near Me",
        "homes": "Homes for Sale near Me",
        "homesRent": "Homes for Rent",
        "apartmentsRent": "Apartments for Rent",
        "singleFamilyRent": "Single Family for Rent",
        "houses": "Houses for Sale near Me",
        "openHouse": "open houses",
        "new": "New Listings for Sale",
        "sold": "Recently Sold Homes",
        "foreclosure": "Foreclosures for Sale",
        "beds": "$count+ Bedrooms",
        "baths": "$count+ Bathrooms",
        "rentals": "Rentals",
        "priceReduce": "price reduced homes",
        "newConstruction": "New Construction for Sale",
        "recent": "recently bought homes",
        "trends": "market trends",
        "luxuryHomes": "luxury homes",
        "pool": "homes with a pool",
        "backyard": "homes with a backyard",
        "open_floor_plan": "homes with a open floor plan",
        "walk_in_closet": "homes with a walk-in closet",
        "fireplace": "homes with a fireplace",
        "clubhouse": "homes with a clubhouse",
        "patio": "homes with a patio",
        "deck": "homes with a deck",
        "single_level": "homes with a single level",
        "hardwood_floors": "homes with a hardwood floors",
        "view": "homes with a view",
        "large_kitchen": "homes with a large kitchen",
        "kitchen_island": "homes with a kitchen island",
        "porch": "homes with a porch",
        "garage": "homes with a garage",
        "waterfront": "Waterfront for Sale",
        "more": "and more in $stateFullName and beyond.",
        "popular": "Also, you can search by a popular search like",
        "otherArea": "Not seeing what you're looking for? Expand your real estate search to other areas, such as",
        "and": "and",
        "ready": "When you're ready to buy, Movoto will connect you directly to a licensed local real estate agent who can guide you on your journey to buying your dream home.",
        "otherParagraph": "Movoto gives you access to the most up-to-the-minute real estate information in $stateFullName As a licensed brokerage in $stateFullName (and across the United States), Movoto has access to the latest real estate data including homes for sale, recently bought homes, new listings, market trends, and more in $stateFullName and beyond.<br/> Not seeing what you're looking for? Expand your real estate search to other areas, When you're ready to buy, Movoto will connect you directly to a licensed local real estate agent who can guide you on your journey to buying your dream home."
    },
    "compliance": {
        "generic": "The multiple listing information is provided by ",
        "start": "IDX information is provided exclusively for consumers’ personal, non-commercial use and that it may not be used for any purpose other than to identify prospective properties consumers may be interested in purchasing. Information deemed reliable but not guaranteed to be accurate. Buyer to verify all information. The information provided is for consumers' personal, non-commercial use and may not be used for any purpose other than to identify prospective properties consumers may be interested in purchasing. Listing information updated every 15 minutes.",
        "terms": "Terms of use",
        "privacy": "Privacy Policy",
        "rights": "All rights reserved.",
        "equal": "Equal Housing Opportunity",
        "caPrivacy": "California Privacy Info"
    },
    "seo": {
        "homes": "Homes"
    },
    "view": {
        "list": "List View",
        "map": "Map",
        "grid": "Grid View",
        "splitView": "Split View"
    },
    "paging": {
        "of": "of",
        "result": "Result",
        "result_plural": "Results",
        "forSale": "For Sale",
        "forRent": "For Rent",
        "sold" : "Sold"
    }
}
