var context = window.__INITIAL_STATE__;
var streetViewCheckList = {};
var mapLoaded = 0;
var tweenLoaded = 0;

window.CustomMarker = function (options) {
    var defaultOpt = {
        latlng: null,
        map: null,
        html: null,
        onclick: null,
        zIndex: null,
        autoTrigger: false,
        offset: null,
    };
    var opt = Object.assign({}, defaultOpt, options);
    var that = this;
    this.latlng = opt.latlng;
    this.html = opt.html;
    this.map = opt.map;
    this.onclick = opt.onclick;
    this.zIndex = opt.zIndex;
    this.offset = opt.offset;

    this.triggerClick = function (id) {
        var trigger = document.getElementById(id);
        if (!trigger) {
            that.autoTrigger = true;
        } else {
            that.onclick(trigger);
        }
    };
    this.setMap(opt.map);
};
var initialCustomMarker = function () {
    window.CustomMarker.prototype = new window.google.maps.OverlayView();
    window.CustomMarker.prototype.draw = function () {
        var self = this;
        var div = this.div;
        if (!div) {
            div = this.div = $(this.html)[0];
            var panes = this.getPanes();
            var overlay = panes.overlayMouseTarget;
            if (overlay.firstChild) {
                overlay.insertBefore(div, overlay.firstChild);
            } else {
                overlay.appendChild(div);
            }
            if (this.onclick) {
                window.google.maps.event.addDomListener($(div).children()[0], 'click', function (event) {
                    if (self.onclick) {
                        return self.onclick(div);
                    }
                });
                if (this.autoTrigger) {
                    this.autoTrigger = false;
                    this.triggerClick(div.id);
                }
            }
        }
        var point = this.getProjection().fromLatLngToDivPixel(this.latlng);
        if (point) {
            div.style.left = point.x + 'px';
            div.style.top = point.y + 'px';
        }
    };
    window.CustomMarker.prototype.remove = function () {
        if (this.div) {
            this.div.parentNode.removeChild(this.div);
            this.div = null;
        }
    };
};
window.CustomPolygon = function (options) {
    this._map = options.map;
    this.template = options.template;
    this._div = null;
    this.setMap(this._map);
};
var initalCustomPolygon = function () {
    window.CustomPolygon.prototype = new window.google.maps.OverlayView();
    window.CustomPolygon.prototype.onAdd = function () {
        var div = document.createElement('div');
        div.innerHTML = this.template;
        div.style.borderStyle = 'none';
        div.style.borderWidth = '0';
        div.style.position = 'absolute';
        div.style.visibility = 'hidden';
        this._div = div;
        var panes = this.getPanes();
        panes.floatPane.appendChild(div);
    };
    window.CustomPolygon.prototype.draw = function (event) {
        if (event && event.latLng) {
            var div = this._div;
            var point = this.getProjection().fromLatLngToDivPixel(event.latLng);
            div.style.left = point.x + 'px';
            div.style.top = point.y + 22 + 'px';
        }
    };
    window.CustomPolygon.prototype.onRemove = function () {
        this._div.parentNode.removeChild(this._div);
        this._div = null;
    };
    window.CustomPolygon.prototype.hide = function () {
        if (this._div && this._div.style.visibility !== 'hidden') {
            // The visibility property must be a string enclosed in quotes.
            this._div.style.visibility = 'hidden';
        }
        return this;
    };
    window.CustomPolygon.prototype.show = function () {
        if (this._div && this._div.style.visibility !== 'visible') {
            this._div.style.visibility = 'visible';
        }
        return this;
    };
    window.CustomPolygon.prototype.isShown = function () {
        return this._div && this._div.style.visibility === 'visible';
    };
};
var initialCustomMapStyle = function () {
    window.inactiveMapStyle = new window.google.maps.StyledMapType(
        [
            {
                elementType: 'geometry.fill',
                stylers: [
                    {
                        saturation: -100,
                    },
                    {
                        lightness: 50,
                    },
                ],
            },
            {
                elementType: 'geometry.stroke',
                stylers: [
                    {
                        saturation: -100,
                    },
                    {
                        lightness: 50,
                    },
                ],
            },
            {
                elementType: 'labels',
                stylers: [
                    {
                        visibility: 'off',
                    },
                ],
            },
            {
                featureType: 'poi',
                stylers: [
                    {
                        visibility: 'off',
                    },
                ],
            },
            {
                featureType: 'road',
                stylers: [
                    {
                        lightness: 100,
                    },
                ],
            },
            {
                featureType: 'transit',
                stylers: [
                    {
                        visibility: 'off',
                    },
                ],
            },
        ],
        { name: 'Inactive Map' }
    );
    var defaultMapStyle = [
        {
            elementType: 'geometry',
            stylers: [{ color: '#ecf1f6' }],
        },
        {
            featureType: 'administrative',
            elementType: 'geometry.stroke',
            stylers: [{ color: '#ecf1f6' }],
        },
        {
            featureType: 'administrative',
            elementType: 'labels.text.fill',
            stylers: [{ color: '#8b929c' }],
        },
        {
            featureType: 'administrative',
            elementType: 'labels.text.stroke',
            stylers: [{ color: '#f5f5f5' }, { weight: '2' }],
        },
        {
            featureType: 'administrative.neighborhood',
            elementType: 'geometry.stroke',
            stylers: [{ color: '#d6d6d6' }, { weight: '1' }],
        },
        {
            featureType: 'administrative.land_parcel',
            elementType: 'geometry.stroke',
            stylers: [{ color: '#ffffff' }, { weight: '2' }],
        },
        {
            featureType: 'landscape.man_made',
            elementType: 'geometry.fill',
            stylers: [{ color: '#ecf1f6' }],
        },
        {
            featureType: 'landscape.man_made',
            elementType: 'geometry.stroke',
            stylers: [{ color: '#BABFC8' }, { weight: '2' }],
        },
        {
            featureType: 'landscape.natural',
            elementType: 'geometry',
            stylers: [{ color: '#bcdad1' }],
        },
        {
            featureType: 'landscape.natural.landcover',
            elementType: 'geometry',
            stylers: [{ color: '#ccebd7' }],
        },
        {
            featureType: 'poi',
            elementType: 'geometry',
            stylers: [{ color: '#d7f0e0' }],
        },
        {
            featureType: 'poi.business',
            elementType: 'all',
            stylers: [{ visibility: 'off' }],
        },
        {
            featureType: 'poi.government',
            elementType: 'all',
            stylers: [{ visibility: 'off' }],
        },
        {
            featureType: 'poi.medical',
            elementType: 'geometry',
            stylers: [{ color: '#f4e1e1' }],
        },
        {
            featureType: 'poi.medical',
            elementType: 'labels.icon',
            stylers: [{ color: '#f9b9b9' }],
        },
        {
            featureType: 'poi.medical',
            elementType: 'labels.text',
            stylers: [{ visibility: 'off' }],
        },
        {
            featureType: 'poi.park',
            elementType: 'geometry',
            stylers: [{ color: '#d7f0e0' }],
        },
        {
            featureType: 'poi.park',
            elementType: 'labels.text',
            stylers: [{ visibility: 'off' }],
        },
        {
            featureType: 'poi.attraction',
            elementType: 'labels.icon',
            stylers: [{ color: '#a4abbe' }],
        },
        {
            featureType: 'poi.attraction',
            elementType: 'labels.text',
            stylers: [{ visibility: 'off' }],
        },
        {
            featureType: 'poi.place_of_worship',
            elementType: 'all',
            stylers: [{ visibility: 'off' }],
        },
        {
            featureType: 'poi.school',
            elementType: 'geometry',
            stylers: [{ color: '#dae1ec' }],
        },
        {
            featureType: 'poi.school',
            elementType: 'labels',
            stylers: [{ visibility: 'off' }],
        },
        {
            featureType: 'poi.sports_complex',
            elementType: 'all',
            stylers: [{ visibility: 'off' }],
        },
        {
            featureType: 'road',
            elementType: 'geometry',
            stylers: [{ color: '#fafafa' }],
        },
        {
            featureType: 'road',
            elementType: 'labels.text.fill',
            stylers: [{ color: '#8b929c' }],
        },
        {
            featureType: 'road',
            elementType: 'labels.text.stroke',
            stylers: [{ color: '#f5f5f5' }],
        },
        {
            featureType: 'road.highway',
            elementType: 'geometry.fill',
            stylers: [{ color: '#fff7e4' }],
        },
        {
            featureType: 'road.highway',
            elementType: 'geometry.stroke',
            stylers: [{ color: '#f9da8d' }],
        },
        {
            featureType: 'road.highway',
            elementType: 'labels.text',
            stylers: [{ visibility: 'off' }],
        },
        {
            featureType: 'transit',
            elementType: 'labels.icon',
            stylers: [{ color: '#a4abbe' }],
        },
        {
            featureType: 'transit',
            elementType: 'labels.text',
            stylers: [{ visibility: 'off' }],
        },
        {
            featureType: 'water',
            elementType: 'geometry',
            stylers: [{ color: '#c3dff9' }],
        },
        {
            featureType: 'water',
            elementType: 'labels.text.fill',
            stylers: [{ color: '#8b929c' }],
        },
    ];

    window.activeMapStyle = new window.google.maps.StyledMapType(defaultMapStyle, { name: 'Active Map' });
};

export default {
    hasStreetView: function (opt) {
        $.loadGMap().then(function () {
            var key = null;
            if (opt.address) {
                key = encodeURIComponent(opt.address);
            }
            if (opt.lat && opt.lng) {
                key = opt.lat + ',' + opt.lng;
            } else {
                return opt.callback(false);
            }

            if (streetViewCheckList[key] !== undefined) {
                if (streetViewCheckList[key] !== 'loading') {
                    return opt.callback(streetViewCheckList[key]);
                }
                $(document).one('hasStreetView.' + key, function (e, hasStreetView) {
                    opt.callback(hasStreetView);
                });
            } else {
                streetViewCheckList[key] = 'loading';
                var metaDataPath = '/maps/api/streetview/metadata?location=' + key + '&' + context.googleMapKey;
                if (context.isDevelopment) {
                    fetch(`https://maps.googleapis.com${metaDataPath}`, { method: 'GET' })
                        .then((response) => response.json())
                        .then(function (res) {
                            streetViewCheckList[key] = res && res.status === 'OK';
                            opt.callback(streetViewCheckList[key]);
                            $(document).trigger('hasStreetView.' + key, streetViewCheckList[key]);
                        });
                } else {
                    const body = { url: metaDataPath };
                    fetch(`${context.appUrl}webservices/getgooglemapurlwithsignature/`, {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json; charset=UTF-8',
                        },
                        body: JSON.stringify(body),
                    })
                        .then((response) => response.json())
                        .then(function (data) {
                            fetch(`https://maps.googleapis.com${data.url}`, { method: 'GET' })
                                .then((response) => response.json())
                                .then(function (res) {
                                    streetViewCheckList[key] = res && res.status === 'OK';
                                    opt.callback(streetViewCheckList[key]);
                                    $(document).trigger('hasStreetView.' + key, streetViewCheckList[key]);
                                });
                        });
                }
            }
        });
    },
    loadGMap: function () {
        return new Promise((resolve) => {
            //has load
            if (mapLoaded === 2) {
                resolve(window.google && window.google.maps);
            } else {
                $(document).one('gMapLoaded', function () {
                    resolve(window.google && window.google.maps);
                });
                if (mapLoaded === 0) {
                    mapLoaded = 1;
                    //get ready to load
                    var config = {
                        loading: 'async',
                        libraries: 'places,geometry,drawing',
                        callback: 'googlemapcallback',
                    };
                    var mapUrl = 'https://maps.googleapis.com/maps/api/js?' + context.googleMapKey;
                    Object.keys(config).forEach((key) => {
                        mapUrl += '&' + key + '=' + config[key];
                    });
                    $.preload({ files: [mapUrl], type: 'js', callback: 'googlemapcallback', options: { async: true } }).then(function () {
                        initialCustomMarker();
                        initalCustomPolygon();
                        initialCustomMapStyle();
                        mapLoaded = 2;
                        resolve(window.google && window.google.maps);
                        $(document).trigger('gMapLoaded');
                    });
                }
            }
        });
    },
    loadTween: function () {
        return new Promise((resolve) => {
            //has load
            if (tweenLoaded === 2) {
                resolve(window.tween);
            } else {
                $(document).one('tweenLoaded', function () {
                    resolve(window.tween);
                });
                if (tweenLoaded === 0) {
                    tweenLoaded = 1;
                    //get ready to load
                    var config = {
                        callback: 'tweencallback',
                    };
                    var tweenUrl = 'https://cdnjs.cloudflare.com/ajax/libs/tween.js/16.3.5/Tween.min.js';
                    $.preload({ files: [tweenUrl], type: 'js' }).then(function () {
                        tweenLoaded = 2;
                        resolve(window.tween);
                        $(document).trigger('tweenLoaded');
                    });
                }
            }
        });
    },
};
