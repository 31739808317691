const options = {
    creditList: [
        {
            value: 760,
            text: '760-850 (Excellent)',
        },
        {
            value: 740,
            text: '740-759 (Excellent)',
        },
        {
            value: 720,
            text: '720-739 (Good)',
        },
        {
            value: 700,
            text: '700-719 (Good)',
        },
        {
            value: 680,
            text: '680-699 (Fair)',
        },
        {
            value: 660,
            text: '660-679 (Fair)',
        },
        {
            value: 640,
            text: '640-659 (Poor)',
        },
        {
            value: 620,
            text: '620-639 (Poor)',
        },
        {
            value: 580,
            text: '580-619 (Poor)',
        },
        {
            value: 300,
            text: '300-579 (Poor)',
        },
    ],
    interestRateList: [
        {
            loanYear: 30,
            text: '30 Year Fixed',
            interestRate: 6.61,
            period: 'PERIOD_FIXED_30YEARS',
            value: 0,
        },
        {
            loanYear: 20,
            text: '20 Year Fixed',
            interestRate: 6,
            period: 'PERIOD_FIXED_20YEARS',
            value: 1,
        },
        {
            loanYear: 15,
            text: '15 Year Fixed',
            interestRate: 5.93,
            period: 'PERIOD_FIXED_15YEARS',
            value: 2,
        },
        {
            loanYear: 30,
            text: '7/1 ARM',
            interestRate: 5.5,
            period: 'PERIOD_ARM_7YEARS',
            value: 3,
        },
        {
            loanYear: 30,
            text: '5/1 ARM',
            interestRate: 6.06,
            period: 'PERIOD_ARM_5YEARS',
            value: 4,
        },
        {
            loanYear: 30,
            text: '10/1 ARM',
            interestRate: 6,
            period: 'PERIOD_ARM_10YEARS',
            value: 5,
        },
    ],
    interestPeriodTextMap: {}, // later added
};

// add interest "period" -> "text" map
options.interestPeriodTextMap = options.interestRateList.reduce(function (prevObj, info) {
    prevObj[info.period] = info.text;
    return prevObj;
}, {});

export default options;
