export default function () {
    return {
        masthead: null,
        geo: null,
        cityInfo: null,
        cityContent: null,
        marketSummary: null,
        schoolSummary: null,
        weatherSummary: null,
        marketTrendsSummary: null,
        marketTrendsSnapshot: null,
        nearbySchools: null,
        nearbyCities: null,
        nearbyZipCodes: null,
        nearbyNeighborhoods: null,
        nearbyCounties: null,
    };
}
