export default{
    setCookie: function (name, value, exp) {
        var Days = 180;
        if (!exp) {
            exp = new Date();
            exp.setTime(exp.getTime() + Days * 24 * 60 * 60 * 1000);
        }
        if(location.origin === 'http://localhost:3015'){
            document.cookie = name + '=' + encodeURIComponent(value) + ';path=/;expires=' + exp.toGMTString();
        }else{
            document.cookie = name + '=' + encodeURIComponent(value) + ';path=/;SameSite=None;Secure;expires=' + exp.toGMTString();
        }
    },
    removeCookie: function (name) {
        var expGMTStr = 'Thu, 01 Jan 1970 00:00:01 GMT';
        if(location.origin === 'http://localhost:3015'){
            document.cookie = name + '=;path=/;expires=' + expGMTStr;
        }else{
            document.cookie = name + '=;path=/;SameSite=None;Secure;expires=' + expGMTStr;
        }
    },
    getCookie: function (name) {
        var arr, reg = new RegExp('(^| )' + name + '=([^;]*)(;|$)');
        if ((arr = document.cookie.match(reg))) {
            return decodeURIComponent(arr[2]);
        }
        return null;
    }
};
