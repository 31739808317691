export default function(item){
    return {
        id: item.id,
        title: item.title,
        pageViews: item.pageViews,
        description: item.description,
        url: item.url,
        image: item.featuredImageThumb || '',
        author: item.author ? item.author.name : '',
        date: item.updateDate
    };
}