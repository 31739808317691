import format from '../../utilities/format.js';

const dpp = state => state;
const getMlsInfo = state => state.mls;
const getUpdatedStatus = state => state.updatedStatus;
const getLocalHighlight = state => state.localHighlight;
const getLocalHighlightScore = state => state.localHighlightScore;
const getPropertyId = state => state.propertyId;
const getNearbyRentals = state => state.nearbyRentHomes;
const getNearbyNHS = state => state.nearbyNHSHomes;
const getAttributes = state => state.attributes;
const getEstRentFee = (state) => {
    if (state.nearbyRentHomes && state.nearbyRentHomes.length >= 2) {
        let count = 0;
        let sum = state.nearbyRentHomes.reduce(function (prev, next) {
            if (next.listPrice) {
                count++;
                return prev + next.listPrice;
            }
        }, 0);
        let rent = sum / count;
        let yearlyRent = rent * 12;
        let grm = state.listPrice / yearlyRent;
        let rate = '';
        if (grm >= 0 && grm < 4) {
            rate = 'Excellent';
        } else if (grm >= 4 && grm < 7) {
            rate = 'Great';
        } else if (grm >= 7 && grm < 10) {
            rate = 'Good';
        }
        return {
            value: rent,
            grm: grm > 0 ? grm.toFixed(1) : '',
            rate: rate,
        };
    }
    return null;
};

const getCategories = state =>{
    let extentionName = state.imageFormat || 'jpeg';
    return state.categorizedPhotos.map(function(cate, cateIndex){
        var tmp = {
            tag: cate.tag,
            path: cate.url.replace(/(_[tplr])?.(jpeg|webp)/, ''),
            _p: state.imageDownloaderStatus ? `_p.${extentionName}` : `.${extentionName}`,
            _r: state.imageDownloaderStatus ? `_r.${extentionName}` : `.${extentionName}`,
            _l: state.imageDownloaderStatus ? `_l.${extentionName}` : `.${extentionName}`,
            mlsIndex: cate.sequenceNum,
            cateIndex: cateIndex,
            index: 0,
            count: cate.photos.length
        };
        return tmp;
    });
};

const getAvmValuation = state =>{
    if(state.avmInfo){
        return state.avmInfo.valuation
    }
    return ''
};

const getAvmText = state =>{
    if(state.avmInfo){
        return `$${format.friendlyPrice(state.avmInfo.valuation)}`
    }
    return ''
};
const getAvmRangText = state =>{
    if(state.avmInfo){
        return `$${format.friendlyPrice(state.avmInfo.minValuation)} - $${format.friendlyPrice(state.avmInfo.maxValuation)}`
    }
    return ''
};

const getAvmPrice = state =>{
    if(state.avmInfo){
        return `$${format.formatNumberByComma(state.avmInfo.valuation)}`
    }
    return ''
};
const getPhotos = state => {
    var hasCategories = state.categorizedPhotos.length > 1;
    var i = 0;
    let extentionName = state.imageFormat || 'jpeg';
    return state.categorizedPhotos.reduce(function(prev, next, cateIndex){
        return prev.concat(next.photos.map(function(image, index){
            var tmp = {
                tag: hasCategories && index === 0 ? next.tag : '',
                cate: hasCategories ? next.tag : '',
                cateCount: hasCategories ? next.photos.length : '',
                path: image.url.replace(/(_[tplr])?.(jpeg|webp)/, ''),
                _p: state.imageDownloaderStatus ? `_p.${extentionName}` : `.${extentionName}`,
                _r: state.imageDownloaderStatus ? `_r.${extentionName}` : `.${extentionName}`,
                _l: state.imageDownloaderStatus ? `_l.${extentionName}` : `.${extentionName}`,
                mlsIndex: image.sequenceNum,
                cateIndex: cateIndex,
                index: index,
                i: i++
            };
            return tmp;
        }));
    }, []);
};

const getMapUrls = state => {
    if(state.mapUrls && state.mapUrls.length){
        let arr = state.mapUrls.sort((a, b) => {return a.index - b.index});
        return arr.map(item => {
            return {
                src: item.src,
                src_l: item.src_l,
                cateIndex: 0,
                i: item.index,
                index: item.index
            };
        });
    }
};

const publicRecord = state => state.publicRecord;
const getPriceHistory = state => state.priceHistory;
const getEstPrice = state => state.estPrice;
const getEstPricePerArea = state => state.estPricePerArea;
const getEstPriceRange = state => state.estPriceRange;
const getMortgagePerMonth = state => state.mortgagePerMonth;
const getMonthTotal = state => state.monthTotal;
const getNearbyHome = state => state.nearbyHomes;
const getNearbySoldHomes = state => state.nearbySoldHomes;
const getseoNearbyCity = state => state.seoNearbyCity;
const getNearbyPois = state => state.nearbyPois;
const getNearbySchools = state=>state.nearbySchools;
const getcityArticles = state=>state.cityArticles;
const getMarket1Y = state=> state.market1Y;
const getMarket5Y = state=> state.market5Y;
const getSeoNearbyData = state => {
    return {
        seoNearbyCity: state.seoNearbyCity,
        seoNearbyZipCode: state.seoNearbyZipCode
    };
};

const geo = state => state.geo;
const getForecast = state => state.forecast;
const getCateIndex = (state) => state.cateIndex;
const getPhotoIndex = (state) => state.photoIndex;
const getImageIndex = (state) => state.imageIndex;
const getHotleadLabel2 = (state, getters, rootState, rootGetter) => {
    if (state.isNHS) {
        return 'dpp.hotButton.requestHomeDetails';
    } else if (state.isActive && !rootState.glb.agent.id) {
        return 'dpp.hotButton.requestTour';
    } else {
        return 'dpp.hotButton.requestInfo';
    }
};

export default {
    dpp,
    getCateIndex,
    getPhotoIndex,
    getImageIndex,
    getPropertyId,
    geo,
    getCategories,
    getPhotos,
    getMlsInfo,
    getUpdatedStatus,
    publicRecord,
    getPriceHistory,
    getSeoNearbyData,
    getEstPrice,
    getEstPricePerArea,
    getEstPriceRange,
    getEstRentFee,
    getMortgagePerMonth,
    getMonthTotal,
    getLocalHighlight,
    getLocalHighlightScore,
    getNearbyHome,
    getNearbySoldHomes,
    getseoNearbyCity,
    getNearbyPois,
    getNearbySchools,
    getcityArticles,
    getMarket1Y,
    getMarket5Y,
    getForecast,
    getMapUrls,
    getAvmRangText,
    getAvmText,
    getAvmPrice,
    getNearbyRentals,
    getAvmValuation,
    getNearbyNHS,
    getHotleadLabel2,
    getAttributes,
};
