<template>
    <SplitProvider
        :userKey="$store.state.glb.movotoDeviceID"
        :apiKey="$store.state.glb.splitClientKey"
    >
      <div v-inital v-fullscreen v-ga:ux>
        <router-view/>
      </div>
    </SplitProvider>
</template>
<script>
import SplitProvider from "./common/components/split/SplitProvider.vue";

export default {
    name: 'app',
    components: { SplitProvider },
};
</script>
<style>
    @import "~@/common/scss/ui.scss";
</style>
