export default (app) => {
    app.directive('autoselect', {
        beforeMount(el) {
            var $input = $(el);
            $input.on('focusin.autoselect', function() {
                if (el.select) {
                    el.select();
                } else if (el.trigger) {
                    el.trigger('select');
                }
            });
        }
    });
}