 function fetchAgents({commit, getters, dispatch, rootState}, params) {
    var data = {
        city: params.city,
        state: params.state,
        size: 8
    };
    const url = `${rootState.glb.appUrl}api/home/agent/info/`;
    return dispatch('glb/sendRequest', {url: url, data: data, method: 'GET', requestName: 'agents', keepSingle: 'agents'}, {root: true})
    .then(res => {
        if(res){
            commit('updateAgents', {data: res.data || [], glb: rootState.glb});
            return res;
        }
        return Promise.reject(res);
    });
  }

export default {
    fetchAgents
};
