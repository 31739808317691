

export default {
    loadImg: function ($img, imgSrc) {
        return new Promise((resolve) => {
            if(!window.loadedImage){
                window.loadedImage = [];
            }
            $img.removeClass('img-load-error img-load-success');
            $img.one('load', function(){
                $img.addClass('img-load-success');
                window.loadedImage.push(imgSrc);
                resolve();
            });
            $img.one('error', function () {
                $img.addClass('img-load-error');
                $(document).trigger('img.error', imgSrc);
                resolve();
            });
            if(imgSrc){
                if ($img.attr('src') !== imgSrc) {
                    $img.attr('src', imgSrc);
                }
            }else{
                $img.addClass('img-load-error');
                $(document).trigger('img.error', imgSrc);
            }
        });
    }
};
