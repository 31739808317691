import format from '@/common/utilities/format';
import motime from '@/common/utilities/motime';

export const formatPrice = function (value) {
    var price = Math.round(value);
    if (!price) {
        return '—';
    }
    return '$' + format.formatNumberByComma(price);
};

export const formatNumber = function (value) {
    if (!value) {
        return '0';
    }
    return format.formatNumberByComma(value);
};

export const formatFriendlyPrice = function (value, placeholder) {
    if (placeholder && (!value || value === '-')) {
        return placeholder;
    }
    if (!value) {
        return '—';
    }
    return '$' + format.friendlyPrice(value);
};

export const friendlyPriceNear10K = function (value, placeholder) {
    if (placeholder && (!value || value === '-')) {
        return placeholder;
    }
    if (!value) {
        return '—';
    }
    return '$' + format.friendlyPriceNear10K(value);
};

export default {
    install: (app) => {
        app.config.globalProperties.$filters = {
            formatPrice,
            formatNumber,
            formatFriendlyPrice,
            friendlyPriceNear10K,
            formatComma: function (number, decimals = 0, dec_point = '.', thousands_sep = ',') {
                if (number === 0) {
                    return 0;
                }
                if (!Number(number)) {
                    return '—';
                }
                let decimal, decimalPoint, negative , num = number
                negative = num < 0 ? '-' : '';
                num = Math.abs(num);
                decimal = ((num - parseInt(num)).toFixed(decimals)).slice(2);
                num = ('' + (parseInt(num.toFixed(decimals)))).split('').reverse().join('').replace(/...(?!$)/g, '$&' + (thousands_sep.replace(/\$/g, '$$$$'))).split('').reverse().join('');
                decimalPoint = decimals !== 0 ? dec_point : '';
                return '' + negative + num + decimalPoint + decimal;
            },
            formatPriceWithUnitWithThreeFixed: function(value) {
                if (!value) {
                    return '—';
                }
                return '$' + format.formatPriceWithUnitWithThreeFixed(value);
            },
            formatPricePerArea: function(value, showAcre, isRental) {
                if (!value) {
                    return '—';
                }
                if(showAcre){
                    return '$' + format.friendlyPrice(Math.round(value * 43560));
                }
                if(isRental) {
                    if(value % 1 === 0 || value.toFixed(1) % 1 === 0) {
                        return '$' + value.toFixed(0);
                    } else {
                        return '$' + value.toFixed(1);
                    }
                }
                return '$' + format.formatNumberByComma(Math.round(value));
            },
            formatArea: function(value, showAcre) {
                if (!value) {
                    return '—';
                }
                if(showAcre){
                    var val = value / 43560;
                    if(val % 1 === 0){
                        val = val.toFixed(0);
                    }else{
                        val = val.toFixed(2);
                    }
                    return parseFloat(val);
                }
                return format.formatNumberByComma(value);
            },
            formatFriendlyLotSize: function (value, lotSizeUnit, includeUnit = true, sqftLabel = ' Sqft', acreLabel = ' Acre') {
                if (!value) {
                    return '—';
                }
                if (value > 10889) {
                    var val = (lotSizeUnit && lotSizeUnit==='Acre') ? value : (value / 43560);
                    if (val % 1 === 0) {
                        val = val.toFixed(0);
                    } else {
                        val = val.toFixed(2);
                    }
                    return `${parseFloat(val)}${includeUnit ? acreLabel : ''}`;
                }
                return `${format.formatNumberByComma(value)}${includeUnit ? sqftLabel : ''}`;
            },
            stripNonNumeric: function(text) {
                if (typeof text === 'string') {
                    return text.replace(/\D/g, '');
                }
                return text + '';
            },
            formatDate: function(date, options) {
                if (!date) {
                    return '';
                }
                return motime.formatDate(date, options);
            },
            formatPhoneNumber: function(phoneNumber) {
                var phoneNumberFormatted = format.fomatPhoneNumber(phoneNumber, '($1) $2-$3');
                return phoneNumberFormatted;
            },
            formatFriendlyDate: function(date) {
                return motime.fromNow(date);
            },
            removeLeadingZero: function(str) {
                if(!str || !str.length) {
                    return;
                }
                let outputStr = str;
                if (outputStr.startsWith('0')) {
                    outputStr = outputStr.substr(1);
                }
                let idx = outputStr.indexOf('/');
                if (outputStr[idx + 1] === '0') {
                    outputStr = outputStr.substr(0, idx + 1) + outputStr.substr(idx + 2);
                }

                return outputStr;
            },
        }
    }
};
