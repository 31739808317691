import getGeo from '../glb/geo';
import prevHotlead from './data.prevhotlead';
const isSSR = typeof window === 'undefined';

export default function () {
    return {
        isSSR,
        geo: getGeo(),
        welcomeImage: '',
        phoneNumber: '6504450449',
        phoneNumber2: '',
        version: '',
        appUrl: '',
        ojoUrl: '',
        cdnUrl: '',
        digsUrl: '',
        staticUrl: '',
        boundaryUrl: '',
        requestUrlRaw: '',
        referUrl: '',
        splitClientKey: undefined,
        googleMapKey: undefined,
        gaAccount: undefined,
        firebaseMessaging: undefined,
        googleLoginClientId: undefined,
        isDevelopment: false,
        isUserBrowser: undefined,
        mlsLogoIds: [],
        pageType: '',
        muted: true,
        pageInfo: {
            value: '',
            sitesection: '',
            type: '',
            listing_pagetype: '',
            userType: '',
        },
        landingPage: '',
        landingPagePath: '',
        landingPageType: '',
        firstVisitedPageType: '',
        brokerages: {},
        isPhone: undefined,
        isMobile: undefined,
        language: undefined,
        OS: undefined,
        extendedOS: undefined,
        randomId: '',
        menu: undefined,
        runat: undefined,
        rentals: false,
        rentalLink: '',
        enableThirdPart: true,
        user: {
            id: '',
            fullName: '',
            email: '',
            phone: '',
            role: '',
            activated: false,
            ojoId: '',
            userType: '',
        },
        agent: {
            id: '',
            name: '',
            firstName: '',
            lastName: '',
            type: '',
            email: '',
            phone: '',
            isAgentFeedback: '',
            relationshipCreatedAt: '',
            profilePhotoUrl: '',
            biography: '',
            licenses: [],
        },
        assignedAgents: [],
        dppAssignedAgents: [],
        propertyStatus: {
            active: 'ACTIVE',
            pending: 'PENDING',
            sold: 'SOLD',
            removed: 'REMOVED',
            offMarket: 'OFF_MARKET',
            forRent: 'FOR_RENT',
        },
        propertyTypes: {
            single: 'SINGLE_FAMILY_HOUSE',
            multi: 'MULTI_FAMILY',
            land: 'LAND',
            mobile: 'MOBILE',
            condo: 'CONDO',
            other: 'OTHER',
            commercial: 'COMMERCIAL',
        },
        hotleadInfo: {},
        viewport: {
            width: !isSSR && window ? window.innerWidth : 375,
            height: !isSSR && window ? window.innerHeight : 667,
            xsScreen: [0, 639],
            smScreen: [640, 991],
            mdScreen: [992, 1399],
            lgScreen: [1400, 1750],
            scrollTop: !isSSR && window ? window.scrollY : 0,
            scrollLeft: !isSSR && window ? window.scrollX : 0,
        },
        viewedListings: [],
        updatedViewedItem: null,
        progressStatus: 0,
        favoriteListings: [],
        subscriptions: [],
        digsSubscribe: [],
        feed: [],
        savedSearches: null,
        claimedHomes: null,
        claimedPropertiesList: null,
        updatedFavItem: null,
        initialed: false,
        loginToDoList: [],
        lastSearch: null,
        prevSearch: null,
        recentSearchList: [],
        autoSuggest: null,
        autoSearchResult: [],
        mlsIds: [],
        seo: {
            title: '',
            h1: '',
            description: '',
        },
        metaObj: {
            canonicalUrl: '',
        },
        mortgagelink: '',
        refinancelink: '',
        veteranslink: '',
        cablelink: '',
        creditlink: '',
        insurancelink: '',
        recentlyViewed: [],
        recentlyViewedTags: [],
        zipNewListings: [],
        zipReducedListings: [],
        zipRecentlySoldListings: [],
        zipOpenListings: [],
        zipBestValueListings: [],
        cityNewListings: [],
        cityCondosListings: [],
        cityUrlListings: [],
        cityReducedListings: [],
        cityOpenListings: [],
        cityBestValueListings: [],
        surroundingListings: [],
        attributeNearbyHomes: {},
        brokerageAgents: [],
        nearbySold: [],
        nearbySale: [],
        listingCountObj: {},
        rentLang: '',
        neighborhoodNewListings: [],
        neighborhoodOpenListings: [],
        neighborhoodBestValueListings: [],
        neighborhoodReducedListings: [],
        tags: {},
        marketTrendsForInsights: {},
        splits: {},
        agentCount: 0,
        transactionCount: 0,
        unit: 'metric',
        signalJWT: '',
        movotoDeviceID: '',
        mapType: 'roadmap',
        mapDetails: '',
        utm_campaign: '',
        utm_source: '',
        utm_medium: '',
        webpush: '',
        gclid: '',
        selectedPoi: [],
        opendoor: null,
        nearbyHomes: [],
        nearbyVideos: null,
        nearbyPlaces: null,
        rentalNearbyAffordUrl: '',
        showAppBanner: false,
        hideMlsInfo: false,
        marketSummary: {},
        prevHotlead: prevHotlead(),
        log: false,
        popLeadBeforeRedirect: false,
        authFormState: '',
        authErrorMessage: '',
        authRedirectUrl: '',
        sellLeadType: [],
        filterLabel: [],
        compliance: {},
        dppCount: 0,
        userPreset: {
            price: null,
        },
        removeAds: false,
        articleConfig: {
            tag: {},
            category: {},
        },
    };
}
