import mapper from '../mapper/index.js';

const isSSR = typeof window === 'undefined';

function getGeo(geo, glb) {
    var geostorage = !isSSR && $.getStorage('mapsearch.geo');
    if (geostorage && geostorage.cityId) {
        return mapper.geo(geostorage, glb.rentals);
    }
    return geo;
}

function updateHome(state, { data, glb }) {
    state.geo = getGeo(data.geo, glb);
    state.stateList = data.stateList;
    state.popularCityList = data.popularCityList;
    state.homeImgWebp = data.homeImgWebp;
    state.homeImgWebpSM = data.homeImgWebpSM;
    state.homeImgWebpMD = data.homeImgWebpMD;
    state.homeImgWebpLG = data.homeImgWebpLG;
    state.homeImgWebpXL = data.homeImgWebpXL;
    state.homeImgSrcset = data.homeImgSrcset;
    state.seoNewListings = data.seoNewListings;
    state.seoReducedListings = data.seoReducedListings;
    state.seoOpenListings = data.seoOpenListings;
}
export default {
    updateHome,
};
