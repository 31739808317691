import { createApp, createSSRApp, h } from 'vue';
import { createRouter, createWebHistory, createMemoryHistory } from 'vue-router';
import createStore from './common/store/index.js';
import initialFilters from './common/filters/index.js';
import initializeRouter from './common/routers/index.js';
import $eventBus from './common/providers/eventbus.js';
import urlMixin from './common/mixins/url.js';
import vnumberMixin from './common/mixins/vnumber.js';
import compNameMixin from './common/mixins/comp.js';
import molang from './common/plugins/molang.js';
import App from './app.vue';
import { fetchCachedClientTreatments, formatSplitsForGA4 } from './common/components/split/utils.js';

const isSSR = typeof window === 'undefined';

export function importDatadogRUM() {
    return import(/* webpackChunkName: "datadog-rum" */ '@datadog/browser-rum');
}

const getRouter = (routes, defaultPath) =>
    createRouter({
        history: (isSSR ? createMemoryHistory : createWebHistory)(defaultPath),
        routes,
    });

function initDatadogRUM() {
    if (isSSR) {
        return;
    }
    try {
        const glb = window.__INITIAL_STATE__;
        if (glb.splits['movoto-enable-datadog-rum-CW-10249'] === 'on' && glb.enableThirdPart) {
            importDatadogRUM().then(({ datadogRum }) => {
                datadogRum.init({
                    applicationId: 'a5fff77c-4a9e-4f31-8b13-2917d5c2d937',
                    clientToken: 'pub0d7b506a8f86075d8db32685ed779e03',
                    site: 'datadoghq.com',
                    service: 'movoto-web',
                    env: glb.isTenX ? glb.tenXEnv : glb.isDevelopment ? 'development' : 'production',
                    version: glb.version,
                    sessionSampleRate: 100,
                    sessionReplaySampleRate: 100,
                    trackUserInteractions: true,
                    trackResources: true,
                    trackLongTasks: true,
                    trackFrustrations: true, // This will help frustation signals enabled.
                    defaultPrivacyLevel: 'allow',
                    trackViewsManually: true, // we manually track views in the router.beforeEach function in vweb/common/routers/index.js
                    allowedTracingUrls: [
                        /https:\/\/.*\.movoto\.com/,
                        (url) => {
                            return url.includes(glb.appUrl);
                        },
                    ],
                });
                let user = {
                    movotoDeviceId: glb.movotoDeviceID,
                    split: glb.splits ? formatSplitsForGA4(glb.splits, fetchCachedClientTreatments()) : '',
                };
                if (glb.user && glb.user.id && glb.user.id !== '') {
                    user = {
                        ...user,
                        ...glb.user,
                    };
                }
                datadogRum.setUser(user);
                window.ddAddError = datadogRum.addError;
            });
        }
    } catch (err) {
        console.error('An error occured initializing Datadog RUM', err);
    }
}

export default (model = null) => {
    initDatadogRUM();
    const store = createStore();
    const router = initializeRouter(getRouter, model);

    const app = createApp({
        router,
        render: () => h(App),
    });

    app.provide('$eventBus', $eventBus);
    app.use(router);
    app.use(store);

    molang.install(app, store);
    initialFilters.install(app);
    app.config.globalProperties.isSSR = isSSR;

    app.mixin(compNameMixin);
    app.mixin(urlMixin);
    app.mixin(vnumberMixin);
    // https://stackoverflow.com/a/64429013
    app.mixin({
        mounted() {
            this.$el.__vue__ = this; // for __vue__ for backwards compatibility.
        },
    });

    return { app, router, store };
};
