const compNameMixin = {
    mounted() {
        var name = this.$options.name;
        if(name){
            var $el = $(this.$el);
            if(this.$options.root || !/^mvt/.test(name)){
                if($el.parent().is('.mvt-lazyload')){
                    $el.parent().attr('comp', name);
                }else{
                    $el.attr('comp', name);
                }
            }
        }
    }
};
export default compNameMixin;
