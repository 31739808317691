
function updateMymovoto(state, { data, glb }) {
    state.geo = data.geo;
    state.homeId = data.homeId;
    state.pageType = data.pageType;
    state.fullUserInfo = data.fullUserInfo || {};
    state.settings = data.settings || [];
    state.subscribeInfo = data.subscribeInfo || {};
    state.sortBy = data.sortBy || '';
    state.favoriteList = data.favoriteList || {};
    state.savedSearch = data.savedSearch || {};
}

function updateSavedSearchById(state, data) {
    state.savedSearchByIdData = data;
}

function setProfileUpdated(state, isProfileUpdated = false) {
    state.isProfileUpdated = isProfileUpdated;
}

function updateHomeId(state, homeId) {
    state.homeId = homeId;
}

export default {
    updateMymovoto,
    updateSavedSearchById,
    setProfileUpdated,
    updateHomeId,
};
