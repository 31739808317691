// any issue here please contact with conjee
// https://css-tricks.com/prevent-page-scrolling-when-a-modal-is-open/
var _mobileSafari = null;
var isMobileSafari = function() {
    if(_mobileSafari === null){
        _mobileSafari = !!(/safari/i.test(navigator.userAgent) && 'ontouchstart' in window);
    }
    return _mobileSafari;
};
var disableScroll = function(){
    var y = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;
    var $el = $(document.body);
    if($el.css('position') === 'fixed'){
        return;
    }
    $el.css({
        position: 'fixed',
        top: `-${y}px`
    });
    document.documentElement.style.setProperty('--overlayer-top', y + 'px');
    setTimeout(() => {
        //for the real mobile device safari when keyboard is open
        window.scrollTo(0, 0);
        // $('html, body').scrollTop(0);
    }, 100);
};

var enableScroll = function (restTop) {
    var $el = $(document.body);
    if ($el.css('position') !== 'fixed') {
        return;
    }
    var y = ($el.css('top').replace('px', '') || 0) * -1;
    $el.css({
        position: '',
        top: '',
    });
    if (restTop === true) {
        y = 0;
    }
    window.scrollTo(0, y);
    // $('html, body').scrollTop(y);
    document.documentElement.style.setProperty('--overlayer-top', '0px');
};

export default{
    openOverlayer: function (name) {
        if(isMobileSafari()){
            disableScroll();
        }
        $('#body').addClass(name);
    },
    closeOverlayer: function (name, restTop) {
        var $body = $('#body');
        $body.removeClass(name);
        if(isMobileSafari()){
            if($body.hasClass('overlay-active') || $body.hasClass('aside-active') || $body.hasClass('dialog-active') || $body.hasClass('searchbox-active') || $body.hasClass('popownup-active')){
                return;
            }
            enableScroll(restTop);
        }
    }
};
