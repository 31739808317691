const geolanding = state => state;

const getGeoDisplayText = state => {
    switch (state.geo.geoType) {
        case 'ZIPCODE':
            return `${state.geo.zipcode}, ${state.geo.state}`;
        case 'NEIGHBORHOOD':
            return `${state.geo.neighborhood} ${state.geo.city}, ${state.geo.state}`;
        case 'COUNTY':
            return `${state.geo.county}, ${state.geo.state}`;
        case 'CITY':
        case 'ADDRESS':
            return `${state.geo.city}, ${state.geo.state}`;
        case 'STATE':
            return state.geo.state;
        default:
            return '';
    }
};

export default {
    geolanding,
    getGeoDisplayText,
};
