import movotovalidator from '../../../utility/movotovalidator';
const typeMapping = {
    zipcode: 'postal_code',
    city: 'locality',
    state: 'administrative_area_level_1',
    county: 'administrative_area_level_2',
};
export default {
    required: (str) => {
        return !!str.replace(/\s/g, '');
    },
    amount: (str) => {
        return str.replace(/\D/g, '') > 0;
    },
    rate: (str) => {
        return str.replace(/\D/g, '') > 0;
    },
    phoneNumber: (str) => {
        const reg = /^[+]?[(]?\d{3}[)]?[-\s.]?\d{3}[-\s.]?\d{4}$/im;
        const validAreaCodeList = [
            201, 202, 203, 205, 206, 207, 208, 209, 210, 212, 213, 214, 215, 216, 217, 218, 219, 220, 224, 225, 228, 229, 231, 234, 239, 240, 248, 251, 252, 253, 254, 256, 260, 262, 267, 269, 270,
            272, 276, 281, 301, 302, 303, 304, 305, 307, 308, 309, 310, 312, 313, 314, 315, 316, 317, 318, 319, 320, 321, 323, 325, 330, 331, 334, 336, 337, 339, 346, 347, 351, 352, 360, 361, 364,
            380, 385, 386, 401, 402, 404, 405, 406, 407, 408, 409, 410, 412, 413, 414, 415, 417, 419, 423, 424, 425, 430, 432, 434, 435, 440, 442, 443, 456, 458, 469, 470, 475, 478, 479, 480, 484,
            500, 501, 502, 503, 504, 505, 507, 508, 509, 510, 512, 513, 515, 516, 517, 518, 520, 530, 531, 533, 534, 539, 540, 541, 544, 551, 559, 561, 562, 563, 566, 567, 570, 571, 573, 574, 575,
            580, 585, 586, 588, 601, 602, 603, 605, 606, 607, 608, 609, 610, 611, 612, 614, 615, 616, 617, 618, 619, 620, 623, 626, 628, 629, 630, 631, 636, 641, 646, 650, 651, 657, 660, 661, 662,
            667, 669, 678, 681, 682, 700, 701, 702, 703, 704, 706, 707, 708, 710, 712, 713, 714, 715, 716, 717, 718, 719, 720, 724, 725, 727, 731, 732, 734, 737, 740, 743, 747, 754, 757, 760, 762,
            763, 765, 769, 770, 772, 773, 774, 775, 779, 781, 785, 786, 800, 801, 802, 803, 804, 805, 806, 808, 809, 810, 812, 813, 814, 815, 816, 817, 818, 828, 830, 831, 832, 843, 844, 845, 847,
            848, 850, 854, 855, 856, 857, 858, 859, 860, 862, 863, 864, 865, 866, 870, 872, 877, 878, 880, 881, 888, 900, 901, 903, 904, 906, 907, 908, 909, 910, 912, 913, 914, 915, 916, 917, 918,
            919, 920, 925, 928, 929, 930, 931, 934, 936, 937, 938, 940, 941, 947, 949, 951, 952, 954, 956, 959, 970, 971, 972, 973, 978, 979, 980, 984, 985, 989,
        ];
        const canadaAreaCodeList = movotovalidator.getCanadaAreaCodes();
        const areaCodeList = validAreaCodeList.concat(canadaAreaCodeList);
        const userAreaCode = parseInt(str.slice(0, 3));
        let isValidAreacode = false;

        if (areaCodeList.indexOf(userAreaCode) > -1) {
            isValidAreacode = true;
        }

        return reg.test(str) && isValidAreacode;
    },
    zipcode: (str) => {
        var reg = /^\d{5}$/;
        return reg.test(str);
    },
    password: (str) => {
        let validation = true;
        if (str.length < 5 || str.length > 50 || !/[a-zA-Z]/.test(str) || !/\d/.test(str) || /[\\/]/.test(str)) {
            validation = false;
        }
        return validation;
    },
    minAndMaxCharCount: (str, params) => {
        return str.length >= params.min && str.length <= params.max;
    },
    hasLetters: (str) => {
        return /[a-zA-Z]/.test(str);
    },
    hasNumbers: (str) => {
        return /\d/.test(str);
    },
    noBackslash: (str) => {
        return !/[\\/]/.test(str);
    },
    email: (str) => {
        //the following regex will allow only English letters, numbers and specific special characters which are valid for email. https://www.w3resource.com/javascript/form/email-validation.php
        var reg = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(.\w{2,3})+$/;
        return reg.test(str);
    },
    fullName: (str) => {
        return str && str.split(' ').length > 1;
    },
    selectOption: (str) => {
        return str && str != '0';
    },
    geo: (text, { geo, geoTypes }) => {
        //check if it's valide google autocomplete suggestion
        if (text && geo && geo.address_components && geo.address_components.length > 0) {
            if (geoTypes && geoTypes.length) {
                return geoTypes.some((type) => {
                    //check if google autocomplete types is address
                    return geo.address_components.some((item) => {
                        return item.types && item.types.indexOf(typeMapping[type]) > -1;
                    });
                });
            } else {
                return true;
            }
        }
        return false;
    },
};
