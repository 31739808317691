
function fetchNearbySold({commit, getters, dispatch, rootState}, data) {
  const url = `${rootState.glb.appUrl}api/v/property/nearbysold/`;
  return dispatch('glb/sendRequest', {url: url, data: data, method: 'GET', requestName: 'property', keepSingle: 'nearbysold'}, {root: true})
  .then(res => {
      if(res){
          commit('updateNearbySold', {data: res.data || [], glb: rootState.glb});
          return res;
      }
      return Promise.reject(res);
  });
}

function fetchNearbySoldForLand({commit, getters, dispatch, rootState}, data) {
  const url = `${rootState.glb.appUrl}api/v/property/nearbysold/`;
  return dispatch('glb/sendRequest', {url: url, data: data, method: 'GET', requestName: 'property', keepSingle: 'nearbysold'}, {root: true})
  .then(res => {
      if(res){
          commit('updateNearbySoldForLand', {data: res.data || [], glb: rootState.glb});
          return res;
      }
      return Promise.reject(res);
  });
}

function fetchNearbySaleForLand({commit, getters, dispatch, rootState}, data) {
  const url = `${rootState.glb.appUrl}api/v/property/nearbyforsale/`;
  return dispatch('glb/sendRequest', {url: url, data: data, method: 'GET', requestName: 'property', keepSingle: 'nearbysale'}, {root: true})
  .then(res => {
      if(res && res.data){
          commit('updateNearbySaleForLand', {data: res.data || [], glb: rootState.glb});
          return res;
      }
      return Promise.reject(res);
  });
}

function fetchNearbyRentals({commit, getters, dispatch, rootState}, data) {
  const url = `${rootState.glb.appUrl}api/v/property/nearbyrentals/`;
  return dispatch('glb/sendRequest', {url: url, data: data, method: 'GET', requestName: 'property', keepSingle: 'nearbyrental'}, {root: true})
  .then(res => {
      if(res && res.data){
          commit('updateNearbyRentals', {data: res.data || [], glb: rootState.glb});
          return res;
      }
      return Promise.reject(res);
  });
}

function fetchNearbyPois({ commit, getters, dispatch, rootState }, data) {
    if (!data || !data.latitude || !data.longitude || !data.propertyId) {
        return Promise.resolve(null);
    }
    const url = `${rootState.glb.appUrl}api/v/property/poi/${data.latitude}/${data.longitude}/${data.propertyId}/?count=20`;
    return dispatch('glb/sendRequest', { url: url, method: 'GET' }, { root: true }).then((res) => {
        if (res) {
            commit('updatenearbyPois', res.data || null);
            return res;
        }
        return Promise.reject(res);
    });
}

function fetchcityArticles({commit, getters, dispatch, rootState}, data) {
    const url = `${rootState.glb.appUrl}api/property/articles/?cityid=${data.cityId}&state=${data.state}&size=${data.size}`;
    return dispatch('glb/sendRequest', {url: url, method: 'GET'}, {root: true})
    .then(res => {
        if(res){
            //Todo node layer should fixed return data is String not JSON issue asap
            if(typeof res.data === 'string'){
              res.data = JSON.parse(res.data);
            }
            commit('updatecityArticles', {data: res.data || [], glb: rootState.glb});
            return res;
        }
        return Promise.reject(res);
    });
}


function fetchMarketChartData({commit, getters, dispatch, rootState}, data) {
    var years = data.isFiveYear ? 'L5Y' : 'L1Y';
    const url = `${rootState.glb.appUrl}api/markettrends/dppmarkettrenddata/?cityid=${data.cityId}&neighborhoodid=${data.neighborhoodId}&zipcode=${data.zipcode}&countyid=${data.countyId}&timePeriod=${years}`;
    return dispatch('glb/sendRequest', {url: url, method: 'GET'}, {root: true})
    .then(res => {
        if(res){
            if(data.isFiveYear){
              commit('updateFiveYearStats', {data: res || [], glb: rootState.glb});
            }else{
              commit('updateOneYearStats', {data: res || [], glb: rootState.glb});
            }
            return res;
        }
        return Promise.resolve(null);
    });
}

function fetchCommuteData({commit, getters, dispatch, rootState}, data) {
    if (!data || !data.source || !data.destination) {
      return Promise.resolve(null);
    }
    const url = `${rootState.glb.appUrl}api/v/property/commute/`;
    return dispatch('glb/sendRequest', {url, method: 'POST', data}, {root: true})
      .then(res => {
        if (res && res.data) {
          return res.data;
        }
        return Promise.resolve(null);
      });
  }

function fetchNearbyNHS({commit, getters, dispatch, rootState}, data) {
  const url = `${rootState.glb.appUrl}api/v/property/nearbynhs/`;
  return dispatch('glb/sendRequest', {url: url, data: data, method: 'GET', requestName: 'property', keepSingle: 'nearbynhs'}, {root: true})
  .then(res => {
      if(res && res.data){
          commit('updateNearbyNHS', {data: res.data || [], glb: rootState.glb});
          return res.data;
      }
      return Promise.reject(res);
  });
}

export default {
  fetchNearbySold,
  fetchNearbySoldForLand,
  fetchNearbySaleForLand,
  fetchNearbyPois,
  fetchcityArticles,
  fetchMarketChartData,
  fetchCommuteData,
  fetchNearbyRentals,
  fetchNearbyNHS
};
