function fixedThreeDigits(value) {
    var num = Math.round(value);
    if (num < 10) {
        return Math.round(value * 100) / 100;
    } else if (num < 100) {
        return Math.round(value * 10) / 10;
    }
    return num;
}

function fixedTwoDigits(value) {
    var num = Math.round(value);
    if (num < 100) {
        return Math.round(value * 10) / 10;
    }
    return num;
}

function normalRound(value) {
    var num = Math.round(value);
    return num;
}

function formatDecimalNumberByComma(amount, maxDecimalPlaces) {
    if (amount === null || amount === undefined) {
        return '0';
    }
    maxDecimalPlaces = maxDecimalPlaces || 3;
    amount = Number(Math.round(amount+'e'+maxDecimalPlaces)+'e-'+maxDecimalPlaces)
    return amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') || '0'
}

function formatNumberByComma(amount) {
    if (amount === null || amount === undefined) {
        return '';
    }
    var _amount = Math.round(amount);
    return _amount ? _amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') : '0';
}

const capitalizeTitle = (s) => {
    if (typeof s !== 'string') {
        return '';
    }
    return s
        .split(' ')
        .map((word) => {
            return word.charAt(0).toUpperCase() + word.slice(1);
        })
        .join(' ');
};

const capitalize = (s) => {
    if (typeof s !== 'string') { return ''; }
    return s.charAt(0).toUpperCase() + s.slice(1);
};

const formatFriendlyLotSize = function (value, lotSizeUnit, isEndWithPlus) {
    if (!value) {
        return '—';
    }
    if (value > 10889) {
        var val = (lotSizeUnit && lotSizeUnit==='Acre') ? value : (value / 43560);
        if (val % 1 === 0) {
            val = val.toFixed(0);
        } else {
            val = val.toFixed(2);
        }
        if (isEndWithPlus) {
            return parseFloat(val) + '+ Acre';
        }
        return parseFloat(val) + ' Acre';
    }
    if (isEndWithPlus) {
        return formatNumberByComma(value) + '+ Sqft';
    }
    return formatNumberByComma(value) + ' Sqft';
}

const area = function(value, showAcre) {
    if (!value) {
        return '—';
    }
    if(showAcre){
        var val = value / 43560;
        if(val % 1 === 0){
            val = val.toFixed(0);
        }else{
            val = val.toFixed(2);
        }
        return val;
    }
    return formatNumberByComma(value);
};

const formatPrice = function (value) {
    var price = Math.round(value);
    if (!price) {
        return '—';
    }
    return '$' + formatNumberByComma(value);
};

export default {
    fixedThreeDigits,
    fixedTwoDigits,
    normalRound,
    formatNumberByComma,
    formatPrice,
    formatDecimalNumberByComma,
    capitalize,
    capitalizeTitle,
    area,
    formatFriendlyLotSize,
    friendlyPrice: function (value) {
        var num = value * 1;
        var prefix = '';
        if(Number.isNaN(num)){
            return '';
        }
        if(num < 0){
            num = Math.abs(num);
        }

        var val = num.toFixed(0);
        var suffix = '';
        if(num < 10000){
            val = val.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
        } else {
            if(val.length < 7){
                suffix = 'K';
            } else if(val.length < 10){
                suffix = 'M';
            } else if(val.length < 13){
                suffix = 'B';
            } else {
                suffix = 'T';
            }
            val = val.replace(/\B(?=(\d{3})+(?!\d))/g, '.').substr(0, 4);
            val = parseFloat(val).toString();
        }
        return `${prefix}${val}${suffix}`;
    },
    friendlyPriceNear10K: function (value) {
        var num = value * 1;
        var prefix = '';
        if(Number.isNaN(num)){
            return '';
        }
        if(num < 0){
            num = Math.abs(num);
        }

        var val = num.toFixed(0);
        var suffix = '';
        if(num < 10000){
            val = val.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
        } else {
            if(val.length < 7){
                suffix = 'K';
            } else if(val.length < 10){
                suffix = 'M';
            } else if(val.length < 13){
                suffix = 'B';
            } else {
                suffix = 'T';
            }
            val = val.replace(/\B(?=(\d{3})+(?!\d))/g, '.').substr(0, 4);
            if(Number.isInteger(parseFloat(val))){
                val = parseInt(val/10)*10;
            }else{
                val = parseFloat(val).toFixed(1);
            }
            val = parseFloat(val).toString();
        }
        return `${prefix}${val}${suffix}`;
    },
    fomatPhoneNumber(number, format) {
        var display = number + '';
        var rawNumber = display.replace(/\D/g, '');
        if (rawNumber.length > 10) {
            rawNumber = rawNumber.substring(rawNumber.length - 10, rawNumber.length);
        } else {
            rawNumber = (Array(10).join(0) + rawNumber).slice(-10);
        }
        rawNumber = rawNumber.replace(/(\d{3})(\d{3})(\d{4})/, format || '$1-$2-$3');
        return rawNumber;
    },
    dateGetString(timestamp) {
        var p = new Date(timestamp);
        return p.getMonth() + 1 + '/' + p.getDate() + '/' + ('' + p.getFullYear()).substr(2);
    },
    formateMonthYear(date) {
        var p = new Date(date);
        if (p) {
            var month = p.toLocaleString('default', { month: 'short' });
            return month + ' ' + p.getFullYear();
        }
        return '';
    },
    formatPriceWithUnitWithThreeFixed: function (number) {
        if (!Number(number) || number === 0) {
            number = '—';
        }
        if (number > 1000 && number < 1000000) {
            number = parseInt(number / 1000) + 'K';
        } else if (number >= 1000000) {
            number = (number / 1000000).toFixed(2).replace(/\.?0+$/, '') + 'M';
        } else {
            number = formatNumberByComma(number);
        }
        return number;
    },
    arrayToString: function (arr) {
        if (arr.length === 1) return arr[0];
        const list = [...arr];
        const last = list.pop();
        return list.join(', ') + ' & ' + last;
    }
};
