const isSSR = typeof window === 'undefined';
var defaultSrc = 'data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7';
function loadImg (img) {
    var $img = $(img);
    var imgSrc = $img.attr('data-src');
    var src = $img.attr('src');
    var hasSuccess = $img.hasClass('img-load-success');
    if (!imgSrc) {
      if(!hasSuccess){
        $img.addClass('img-load-error');
      }
      return false;
    }
    $img.removeAttr('data-src');
    if(src !== imgSrc){
      $.loadImg($img, imgSrc);
    }
    return true;
}

function loadComponent(el){
  var vnode = el;
  if(vnode.__vue__ && typeof vnode.__vue__.load === 'function'){
    vnode.__vue__.load();
    $(document).trigger('dom.load');
    return true;
  }
}

function isImg(el){
  return el.nodeName === 'IMG' || el.nodeName === 'IFRAME';
}

function load(el){
  if(isImg(el)){
    return loadImg(el);
  }
  return loadComponent(el);
}

function handler(entries) {
  entries.forEach(entry => {
    if (!entry.isIntersecting) {
      return;
    }
    if(load(entry.target)){
      window.movotoObserver.unobserve(entry.target);
    }
  });
}

function createObserver(el){
  if(!window.movotoObserver){
    const options = {
      rootMargin: '50% 100% 50% 100%',
      threshold: 0
    };
    window.movotoObserver = new IntersectionObserver(handler, options);
  }
  window.movotoObserver.observe(el);
}
function ignoreLazyLoad(el){
  return !window.IntersectionObserver;
}

function disableLazyLoad(el){
  return $(el).closest('[data-role="scroller"]').length > 0;
}
function syncImage(el){
  var $el = $(el);
  var hasLoad = $el.hasClass('img-load-success') || $el.hasClass('img-load-error');
  if(hasLoad){
     loadImg(el);
  }
}
export default(app)=>{
  app.directive('lazyload', isSSR ? {} : {
    beforeMount(el){
      if(isImg(el)){
        $(el).addClass('lazyload');
        var src = $(el).attr('src');
        if(!src){
          $(el).attr('src', defaultSrc);
        }
      }
    },
    mounted(el, binding){
      if(disableLazyLoad(el)){
        return;
      }
      if(binding.value === false) {
          load(el);
      } else {
        createObserver(el);
      }
    },
    updated(el){
      if(disableLazyLoad(el)){
        return;
      }
      if(isImg(el) && !ignoreLazyLoad(el)){
        syncImage(el);
      }
    },
    unmounted(el){
      window.movotoObserver.unobserve(el);
    }
  });
};

