export default function(data){
    var articles = [];
    if(data && data.length){
        articles = data.map((item) => {
            var embed = item._embedded;
            var image = embed && embed['wp:featuredmedia'] && embed['wp:featuredmedia']['0'] && embed['wp:featuredmedia']['0'].source_url;
            return {
                id: item.id,
                title: item.title && item.title.rendered,
                description: item.excerpt && item.excerpt.rendered,
                link: item.link,
                image: image || '',
                author: embed && embed.author && embed.author['0'] && embed.author['0'].name,
                date: item.date
            }
        });
    }
    return articles;
}