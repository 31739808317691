export default {
    'san-francisco-ca': {
        displayName: 'SF Bay Area',
        address: '1900 S Norfolk Street, Suite 350, San Mateo, CA 94403',
        phone: '8772773448',
        googleMapUrl: 'https://goo.gl/maps/ZJ2cQ4kx9X42',
        location: {
            lat: '37.552871',
            lng: '-122.2942597'
        },
        image: {
            xs: 'san-francisco_mobile.jpg',
            sm: 'san-francisco.jpg',
            alt: 'San Francisco seven sisters'
        },
        url: 'brokerage/san-francisco-ca/',
        cityCode: '69270'
    },
    'san-jose-ca': {
        displayName: 'San Jose',
        address: '521 Charcot Ave, Suite 157, San Jose, CA 95131',
        phone: '4087970558',
        googleMapUrl: 'https://www.google.com/maps/place/521+Charcot+Ave+STE+157,+San+Jose,+CA+95131/@37.3838648,-121.9165493,17z/data=!3m1!4b1!4m5!3m4!1s0x808fcbe4be911691:0xf9d5e0f55c3ddc79!8m2!3d37.3838606!4d-121.9143606',
        location: {
            lat: '37.3838606',
            lng: '-121.9165493'
        },
        image: {
            xs: 'san-jose_mobile.jpg',
            sm: 'san-jose.jpg',
            alt: 'San Jose downtown'
        },
        url: 'brokerage/san-jose-ca/',
        cityCode: '69797'
    },
    'fresno-ca': {
        displayName: 'Fresno',
        address: '677 W Palmdon Drive, Suite 202, Fresno, CA 93704',
        phone: '5595173436',
        googleMapUrl: 'https://goo.gl/maps/uRXMDn7wL1E2',
        location: {
            lat: '36.8363922',
            lng: '-119.8068578'
        },
        image: {
            xs: 'fresno_mobile.jpg',
            sm: 'fresno.jpg',
            alt: 'Fresno downtown'
        },
        url: 'brokerage/fresno-ca/',
        cityCode: '69054'
    },
    'sacramento-ca': {
        displayName: 'Sacramento',
        phone: '9162889309',
        googleMapUrl: 'https://goo.gl/maps/sAK1UPvzXMt6ctyb9',
        location: {
            lat: '38.581572',
            lng: '-121.4944'
        },
        image: {
            xs: 'sacramento_mobile.jpg',
            sm: 'sacramento.jpg',
            alt: 'Sacramento'
        },
        url: 'brokerage/sacramento-ca/',
        cityCode: '69324'
    },
    'los-angeles-ca': {
        displayName: 'Los Angeles',
                address: '445 Silver Spur Road, Rolling Hills Estates, CA 90274',
                phone: '6263170118',
                googleMapUrl: 'https://goo.gl/maps/HKtfm2UU2wGsdEsg8',
                location: {
                    lat: '33.7739431',
                    lng: '-118.3767704'
                },
                image: {
                    xs: 'los-angeles_mobile.jpg',
                    sm: 'los-angeles.jpg',
                    alt: 'Yellow flowers in Los Angeles'
                },
                url: 'brokerage/los-angeles-ca/',
        cityCode: '67121'
    },
    'las-vegas-nv': {
        displayName: 'Las Vegas',
        address: '3900 W Alameda Ave, Suite 1200, Burbank, CA 91505',
        phone: '7025701288',
        googleMapUrl: 'https://goo.gl/maps/2dTb1J6TFP2BnDjS9',
        location: {
            lat: '36.0562693',
            lng: '-115.1076771'
        },
        image: {
            xs: 'las-vegas_mobile.jpg',
            sm: 'las-vegas.jpg',
            alt: 'Las Vegas landscape'
        },
        url: 'brokerage/las-vegas-nv/',
        cityCode: '66849'
    }
};
