import moment from "moment";

const ONE_SECOND = 1000;
const ONE_MINUTE = ONE_SECOND * 60;
const ONE_HOUR = ONE_MINUTE * 60;
const ONE_DAY = ONE_HOUR * 24;

const months = () => [
    'January', 'February',
    'March', 'April',
    'May', 'June',
    'July', 'August',
    'September', 'October',
    'November', 'December'
];

const weekdays = () => [
    'Sunday',
    'Monday',
    'Tuesday',
    'Wednesday',
    'Thursday',
    'Friday',
    'Saturday'
];

function _parseDateTime(iptDate, isUtc = true) {
    if (!iptDate) {
        return null;
    } else if (iptDate instanceof Date) {
        return iptDate;
    }
    const dotIndex = iptDate.indexOf('.');
    if (dotIndex >= 0) {
        iptDate = iptDate.substring(0, dotIndex);
    }
    if (isUtc) {
        return _parseUtcDateTime(iptDate);
    }
    return new Date(iptDate.replace(/-/g, '/'));
}

function _parseUtcDateTime(iptDate) {
    if (!iptDate) {
        return null;
    }
    try {
        const dateArr = iptDate.split(/[T\/:\s\-]/g);
        dateArr[1] = dateArr[1] - 1; // month index should minus 1
        return new Date(Date.UTC(...dateArr));
    } catch (err) {
        return null;
    }
}

function formatDate(iptDate, options = {}) {
    const date = _parseDateTime(iptDate);
    if (!date || date == 'Invalid Date') {
        return '';
    }
    let baseOptions = {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric',
        ...options
    };
    options.raw && (baseOptions = options);
    return date.toLocaleString('en-US', baseOptions);
}

function formatDateWithMoment(iptDate, format) {
    const date = _parseDateTime(iptDate);
    if (!date || date == 'Invalid Date') {
        return '';
    }
    return moment.utc(date).format(format);
}

function fromNow(iptDate) {
    const date = _parseDateTime(iptDate);
    if (!date) {
        return '';
    }
    const now = new Date();
    let milliseconds = Math.abs(date - now);
    const days = Math.floor(milliseconds / ONE_DAY);
    milliseconds -= days * ONE_DAY;
    const hours = Math.floor(milliseconds / ONE_HOUR);
    milliseconds -= hours * ONE_HOUR;
    const minutes = Math.floor(milliseconds / ONE_MINUTE);
    milliseconds -= minutes * ONE_MINUTE;
    const seconds = Math.floor(milliseconds / ONE_SECOND);
    milliseconds -= seconds * ONE_SECOND;
    if (days) {
        return `${days} Day${days === 1 ? '' : 's'} Ago`;
    } else if (hours) {
        return `${hours} Hour${hours === 1 ? '' : 's'} Ago`;
    } else if (minutes) {
        return `${minutes} Minute${minutes === 1 ? '' : 's'} Ago`;
    }
    return `${seconds} Second${seconds === 1 ? '' : 's'} Ago`;
}

function dateDiff(iptDate1, iptDate2, type = 'seconds') {
    const date1 = _parseDateTime(iptDate1);
    const date2 = iptDate2 || new Date();
    let milliseconds = Math.abs(date1 - date2);
    if (type === 'seconds') {
        return Math.floor(milliseconds / ONE_SECOND);
    } else if (type === 'minutes') {
        return Math.floor(milliseconds / ONE_MINUTE);
    } else if (type === 'hours') {
        return Math.floor(milliseconds / ONE_HOUR);
    }
    return Math.floor(milliseconds / ONE_DAY);
}

function toDateTime(date, time) {
    date = date ? date.replace(/-/g, '/') : '';
    time = time || '00:00:00';
    return new Date(`${date} ${time}`);
}

export default {
    formatDate,
    formatDateWithMoment,
    dateDiff,
    fromNow,
    toDateTime,
    months,
    weekdays
};
