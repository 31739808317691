export default {
  "ContactUs": "Contactanos",
  "send": "Enviar",
  "neighborhoodguide": "Guía de Vecindario",
  "markettrend": "Tendencias del Mercado",
  "schools": "Escuelas",
  "realestatetips": "Consejos de bienes raíces",
  "aprTip": "APR toma la tasa de interés del préstamo y la agrega a ciertas tarifas pagadas por el prestatario al cierre, incluido el costo inicial que Bankrate muestra en cada oferta. La TASA APR muestra qué préstamo es menos costoso durante todo el plazo del préstamo. La TASA APR que se muestra aquí no incluye el seguro hipotecario primario (si corresponde) y otras tarifas cobradas por su prestamista y puede ser diferente de la TASA APR revelada por su prestamista en la estimación del préstamo y la divulgación de cierre.",
  "rateTip": "La tasa es la cantidad que pagará cada año para pedir prestado el dinero, expresada como un porcentaje",
  "repaymentTip": "El pago mensual que se muestra incluye tanto los pagos de capital como los de intereses. No incluye el seguro hipotecario privado (PMI), que su prestamista puede requerir si su pago inicial es inferior al 20 por ciento de su precio de compra. PMI generalmente cuesta entre 0.5 y 1 por ciento del monto original del préstamo.",
  "breadcrumb": {
    "tooltipTitle": "Venta De Casas en $value",
    "tooltipTitleRent": "Casas En Alquiler en $value"
  },
  "enquiryType": {
    "placeholder": "¿Cómo podemos servirle mejor?",
    "one": "Comuníquese con mi agente asignado",
    "two": "Conectarse con un agente",
    "three": "Soporte de alquiler",
    "four": "Consulta general (ventas / trabajos / etc.)",
    "five": "Informar datos incorrectos",
    "six": "Eliminar fotos de la propiedad",
    "seven": "Comentarios sobre accesibilidad",
    "eight": "Otro"
  },
  "interestOptions": {
    "placeholder": "Seleccione una opción",
    "one": "Sólo Navegando",
    "two": "Vender y comprar",
    "three": "Comprar",
    "four": "Venta",
    "five": "Alquiler",
    "six": "Otro",
    "notsure": "No estoy seguro",
  },
  "sitemap": {
    "h1": {
      "state": "Casas en Venta $state",
      "letter": "$state Bienes Raíces - Ciudades comenzando con $letter"
    },
    "mainContent": {
      "relatedArticles": "Artículos relacionados",
      "topCity": "Ciudades populares en $state",
      "topCounty": "Condados populares en $state",
      "cityLink": "Casas de Venta en $city",
      "countyLink": "Condados de $city",
      "homeLink": "Ver casas en venta en $state",
      "homesLinkRent": "Ver casas en alquiler en $state",
      "stateLinks": "Enlaces Inmobiliarios De $state",
      "SFHLink": "Chalet",
      "condoLink": "Casa/Apartamento",
      "apartmentLink": "Apartmentos",
      "houseLink": "Casas",
      "rentLink" : "Alquileres",
      "foreclosedLink": "Ejecución Hipotecaria/Venta Corta",
      "openHouseLink": "Casas Abiertas",
      "reducedLink": "Precio Reducido",
      "soldLink": "Vendido Recientemente",
      "newListing": "$state Nueva Listados",
      "county":"Condado $countyName",
      "countyForRent": "Alquileres del Condado de $countyName"
    }
  },
  "homeOwner": {
    "l1": "Sepa cuánto vale su casa",
    "h1": "Supervise el valor de mercado de su casa",
    "desc1": "Reciba una valoración gratuita y basada en datos de su casa basada en información actualizada del mercado y ventas de viviendas comparables.",
    "l2": "Seguimiento de hipotecas y capital",
    "h2": "Realice un seguimiento de los saldos de capital e hipotecas",
    "desc2": "Realice un seguimiento del valor neto de su vivienda, optimice su hipoteca y aprenda cómo construir riqueza a largo plazo.",
    "l3": "Herramientas para una propiedad de vivienda exitosa",
    "h3": "Obtenga alertas de mantenimiento personalizadas",
    "desc3": "Reciba notificaciones de mantenimiento, realice un seguimiento de su progreso y guarde para futuras reparaciones.",
    "l4": "Planifique para el futuro",
    "h4": "Desbloquee la guía de expertos para cada paso",
    "desc4": "Sugerencias personalizadas para ayudarlo a ahorrar en impuestos a la propiedad, seguros e hipotecas."
  }
}