export default function () {
    return {
        trigger: '',
        popHistory: [],
        hotleadHistory: [],
        firstHotlead: null,
        firstOptions: null,
        hasSubmitLead: false,
        hotlead: {
            enquiryType: 0,
            veteransType: '',
            email: '',
            name: '',
            phone: '',
            zipcode: '',
            type: '',
            comment: '',
            leadSource: '',
            requestAgentId: '',
            isSeller: false,
            propertyId: '',
            cta: '',
            pageURL: '',
            propertyUrl: '',
            userAgent: '',
            address: '',
            xLeadId: '',
            price: '',
            moveIn: false,
            submission_attrs: {
                is_veteran: null,
                mortgage: null,
                utm: {},
            },
            rentalNearbyAffordUrl: '',
        },
        options: {
            email: '',
            name: '',
            phone: '',
            price: '',
            propertyType: '',
            bath: '',
            bed: '',
            hotleadSup: '',
            hotleadTitle: '',
            hotleadSub: '',
            profileUrl: '',
            hideProfile: false,
            profileHighlighter: false,
            hotleadType: '',
            theme: '',
            buttonText: '',
            propertyId: '',
            comment: '',
            propertyData: null,
            agentData: null,
            requestAgentId: null,
            leadSource: '',
            showZipcode: false,
            showAddress: false,
            showGeo: false,
            showComment: null,
            hideVeteran: false,
            date: '',
            leadDisclaimer: '',
            overwriteLeadTypeToBuy: false,
            timeDisplay: null,
            enableChangeDate: false,
            surveyInfo: null,
            hideIncentive: false,
            rentalNearbyAffordUrl: '',
            fromMyAgent: false,
            veteransType: '',
            isVeteranLead: false,
            hotleadCount: 0,
            hasOJOAgent: false,
            newUser: false,
            userType: '',
            isVeteran: false,
            isValidGeo: false,
            skip3rdPartyPop: false,
        },
    };
}
