export default function() {
    return {
        geo:{
            hasGeoInfo: false,
            subPremise: '',
            address: '',
            formatAddress: '',
            zipcode: '',
            zipcodePath: '',
            neighborhood: '',
            neighborhoodId: '',
            neighborhoodCode: '',
            neighborhoodPath: '',
            city: '',
            cityCode: '',
            cityPath: '',
            schoolPath: '',
            cityId: '',
            county: '',
            countyId: '',
            countyCode: '',
            countyPath: '',
            state: '',
            stateCode: '',
            statePath: '',
            lat: 0,
            lng: 0,
            gpsPath: '',
            geoPath: '',
            geoType: ''
        },
        name: null,
        schoolId: null,
        ncesId: null,
        type: null,
        rating: null,
        subType: null,
        boundaryId: null,
        districtId: null,
        districtName: null,
        districtNcesId: null,
        dspUrl: null,
        externalUrls: null,
        level: null,
        levelCode: null,
        assignHomes: null,
        nearbyHomesLink: null,
        preSchoolInd: null,
        elementarySchoolInd: null,
        highSchoolInd: null,
        middleSchoolInd: null,
        tsRatio: null,
        avgRating: null,
        isActive: null,
        seoNearbyZipCode: [],
        seoNearbyneighborhood: [],
        seoNearbyCities: [],
        recentArticlesData: [],
        MarketStatisticsData: [],
        stateRating: {
            stateRating: '',
            stateRatingId: '',
            stateYear: ''
        },
        summary: {},
        tests: null
    };
}