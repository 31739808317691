const guide = state => state;
const relatedArticleCards = state => {
    let relatedArticles = state.relatedArticles;
    return relatedArticles.length < 3 ? relatedArticles : relatedArticles.slice(0, 2);
};
const relatedArticleLinks = state => {
    let relatedArticles = state.relatedArticles;
    return relatedArticles.length < 6 ? relatedArticles.slice(2) : relatedArticles.slice(2, 5);
};

export default {
    guide,
    relatedArticleCards,
    relatedArticleLinks
};
