import property from './_propertyMapper';
import geo from './_geoMapper';
import poi from './_poiMapper';
import menu from './_menuMapper';
import footerlinks from './_footerLinkMapper';
import article from './_articleMapper';
import cityhub from './_cityhubMapper';

export default {
    property,
    geo,
    poi,
    menu,
    footerlinks,
    article,
    cityhub,
};
