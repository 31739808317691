import Header from './en/header.js';
import esHeader from './es/header.js';
import Search from './en/search.js';
import esSearch from './es/search.js';
import Common from './en/common.js';
import esCommon from './es/common.js';
import Dpp from './en/dpp.js';
import esDpp from './es/dpp.js';

const enDataJSON = {
    header: Header,
    search: Search,
    dpp: Dpp,
    common: Common
};

const esDataJSON = {
    header: esHeader,
    search: esSearch,
    dpp: esDpp,
    common: esCommon
};

const baseOptions = {
    year: (new Date()).getFullYear()
};

function getDataStore(lang) {
    if (lang === 'es') {
        return esDataJSON;
    }
    return enDataJSON;
}

function getString(dataStore, key, opts) {
    if (!key) {
        return '';
    }
    const options = Object.assign({}, baseOptions, opts);
    try {
        const sections = key.split('.');
        let val = dataStore[sections[0]];
        sections.shift();
        while (sections.length) {
            const section = sections.shift();
            val = val[section];
        }
        if (Array.isArray(val)) {
            let index = 0;
            if (options && options.pluralCount > 1) {
                index = 1;
            }
            val = val[index];
        }
        if (!val || typeof (val) != 'string') {
            return '';
        }
        if (val && options) {
            val = val.replace(/\$[a-zA-Z0-9]+/g, (matched) => {
                return options[matched.substr(1)] || matched;
            });
        }
        return val;
    } catch (err) {
        return '';
    }
}

export default {
    getDataStore,
    getString
}