import getGeo from '../glb/geo';

export default function () {
    return {
        geo: getGeo(),
        pageType: '',
        pageHeading: '',
        categories: [],
        article: null,
        articleType: '',
        hottestArticles: [],
        latestArticles: [],
        pageCount: 0,
        paginationText: '',
        states: [],
        isCityHub: false,
        isHub: false,
        hubType: false,
        heroImage: '',
    };
}
