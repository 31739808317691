import debounce from 'lodash/throttle';
let preveSensive = 0;
export default (app) => {
    app.directive('fullscreen', {
        beforeMount(el, binding) {
            $(document).off('dom.scroll.fullscreen').on('dom.scroll.fullscreen', debounce(function () {
                if(!$.winStatus){
                    return;
                }
                if($.winStatus.scrollTop < 100){
                    $('#body').hasClass('hideTitle') && $('#body').removeClass('hideTitle');
                    $('#body').hasClass('fullscreen') && $('#body').removeClass('fullscreen');
                } else if(preveSensive != $.winStatus.sensitive && $.winStatus.sensitive > 10){
                    !$('#body').hasClass('hideTitle') && $('#body').addClass('hideTitle');
                    if ($.winStatus.isScrollDown) {
                        !$('#body').hasClass('fullscreen') && $('#body').addClass('fullscreen');
                    }else{
                        $('#body').hasClass('fullscreen') && $('#body').removeClass('fullscreen');
                    }
                    preveSensive = $.winStatus.sensitive;

                }
            }, 500));
        }
    });
};


