let spyobserver = null;
const isSSR = typeof window === 'undefined';

const getScrollParent = (node) => {
    const isElement = node instanceof HTMLElement;
    const overflowY = isElement && window.getComputedStyle(node).overflowY;
    const isScrollable = overflowY !== 'visible' && overflowY !== 'hidden';

    if (!node) {
        return null;
    } else if (isScrollable && node.scrollHeight >= node.clientHeight) {
        return node;
    }

    return getScrollParent(node.parentNode) || null;
};
const activeLink = (element, key) => {
    const target = element.querySelector(`[href="#${key}"]`);
    // Prevent ".active" from being repeatedly added
    if (target && !target.classList.contains('active')) {
        const children = element.querySelectorAll('.active');
        // Remove ".active" from all children
        children.forEach((child) => {
            child.classList.remove('active');
        });
        // Add ".active" to target child
        target.classList.add('active');
        const list = element.querySelector('.mvt-shifter-list');

        const scrollLeft = list.scrollLeft;
        const scrollWidth = list.offsetWidth;
        const targetLeft = target.offsetLeft;
        const targetWidth = target.offsetWidth;

        // If target item is offscreen to the right, scroll it into view
        if (targetLeft + targetWidth - scrollLeft > scrollWidth) {
            const targetEndRightPosition = targetLeft - (scrollWidth - targetWidth) + scrollWidth * 0.15;
            list.scrollTo(targetEndRightPosition, list.pageYOffset);
        } else if (targetLeft < scrollLeft) {
            list.scrollTo(targetLeft, list.pageYOffset);
        }
    }
};

const scrollSpyDirective = {
    mounted(el, binding) {
        if (window.IntersectionObserver) {
            if (!spyobserver) {
                // Get scrolled content, or default to next scrollable parent element
                const scroller = binding.value ? getScrollParent(document.querySelector(binding.value)) : getScrollParent(el);
                const observerOptions = {
                    root: scroller,
                    rootMargin: '-20% 0px -80% 0px',
                    threshold: 0,
                };

                spyobserver = new window.IntersectionObserver(function (entries) {
                    let updated = false;
                    entries.forEach((entry) => {
                        if (entry.isIntersecting && !updated) {
                            activeLink(el, entry.target.getAttribute('id'));
                            updated = true;
                        }
                    });
                }, observerOptions);

                el.querySelectorAll('a').forEach((node) => {
                    const item = document.querySelector(node.getAttribute('href'));
                    if (item) {
                        spyobserver.observe(item);
                    }
                });
            }
        }
    },
    unmounted() {
        if (spyobserver) {
            spyobserver.disconnect();
        }
    },
};

export default (app) => {
    app.directive('scrollspy', isSSR ? {} : scrollSpyDirective);
};
