function initalSurveyMortgage(state, data) {
    if (data.ctxString) {
        state.ctxString = data.ctxString;
        state.ctx = getJSONByQuery(data.ctxString);
    }
    state.geo = data.geo;
}
function getQueryByJSON(json) {
    return encodeURIComponent(btoa(JSON.stringify(json)));
}

function getJSONByQuery(string) {
    try {
        return JSON.parse(atob(decodeURIComponent(string)));
    } catch (e) {
        return '';
    }
}
function updateCtx(state, data) {
    if (data.key && data.formData) {
        state.ctxString = getQueryByJSON({
            key: data.key,
            formData: data.formData,
            history: data.history || [],
        });
    } else {
        state.ctxString = '';
    }
}

export default {
    initalSurveyMortgage,
    updateCtx,
};
