var resource = {
    js: {
        load: function (src, callback, options) {
            return new Promise((resolve, reject) => {
                var script = document.createElement('script');
                if (callback) {
                    window[callback] = function () {
                        resolve();
                    };
                } else {
                    script.onload = resolve;
                    script.onerror = reject;
                }
                if (options?.async) {
                    script.async = true;
                }
                script.src = src;
                document.getElementsByTagName('head')[0].appendChild(script);
            });
        },
        cache: {},
    },
    css: {
        load: function (src) {
            return new Promise((resolve, reject) => {
                var link = document.createElement('link');
                link.rel = 'stylesheet';
                link.type = 'text/css';
                link.href = src;
                link.onload = resolve;
                link.onerror = reject;
            });
        },
        cache: {},
    },
};
var _getFiletype = function (filename) {
    if (!filename) {
        return;
    }
    return filename.substring(filename.lastIndexOf('.') + 1, filename.length);
};
var _loadResource = function (url, filetype, callback, options) {
    var type = filetype || _getFiletype(url);
    var loader = resource[type];
    if (loader && loader.cache) {
        if (loader.cache[url]) {
            console.log('cache');
            return true;
        }
        loader.cache[url] = true;
        return loader.load(url, callback, options);
    } else {
        console.log('do not support load' + type);
        return false;
    }
};
export default {
    preload: function (options) {
        var defaultOpt = {
            files: [],
        };
        var opt = Object.assign(defaultOpt, options);
        var resources = [];
        if (opt.files && opt.files.length) {
            opt.files.forEach(function (item) {
                resources.push(_loadResource(item, opt.type, opt.callback, opt.options));
            });
        }
        return Promise.all(resources);
    },
    getPreLoadFiles: function (key) {
        var cache = [];
        switch (key) {
            case 'js':
                Object.keys(resource.js.cache).forEach((key) => {
                    cache.push(key);
                });
                break;
            case 'css':
                Object.keys(resource.css.cache).forEach((key) => {
                    cache.push(key);
                });
                break;
            default:
                Object.keys(resource.js.cache).forEach((key) => {
                    cache.push(key);
                });
                Object.keys(resource.css.cache).forEach((key) => {
                    cache.push(key);
                });
                break;
        }
        return cache;
    },
};
