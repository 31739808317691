export default {
    scrollTo: function(el, position){
        var $panel = typeof el === 'string' ? $(document.getElementById(el)) : $(el);
        if($panel.length > 0){
            var $scroll = $.scrollParentY($panel);
            var moveIntoView = function() {
                if(position === 'center'){
                    $panel[0].scrollIntoView({block: 'center'});
                }else{
                    var offset = $(window).width() < 640 ? 110 : 85;
                    if(position > 0){
                        offset = position * 1;
                    }
                    $panel[0].scrollIntoView(true);
                    if($scroll){
                        $scroll[0].scrollTop -= offset;
                    }else{
                        window.scrollBy(0, offset * -1);
                    }
                }
            };
            moveIntoView();
            setTimeout(function(){
                var target = $panel.hasClass('mvt-collapse') ? $panel[0] : ($panel[0].offsetParent !== null ? $panel.find('.mvt-collapse')[0] : $panel.closest('.mvt-collapse')[0]);
                var vnode = target && target.__vue__ && target.__vue__;
                if(vnode && vnode.open && !vnode.active){
                    vnode.open();
                    setTimeout(()=>{
                        moveIntoView();
                    }, 10);
                }
            }, 100);
        }
    }
};
