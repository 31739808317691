function getSitemapLinks(data) {
    if (data.cityList && data.cityList.length) {
        return data.cityList;
    } else if (data.zipcodeList && data.zipcodeList.length) {
        return data.zipcodeList;
    } else {
        return []
    }
}


function updateSitemap(state, { data, glb }) {
    state.geo = data.geo;
    state.pageType = data.pageType;
    state.stateInfo = data.stateInfo || null;
    state.allCounties = data.allCounties || [];
    state.biggestCities = data.biggestCities || [];
    state.biggestCounties = data.biggestCounties || [];
    state.cityCountList = data.cityCountList || {};
    state.sitemapList = getSitemapLinks(data) || [];
    state.listingCountByLetter = data.listingCountByLetter || {};
    state.relatedArticles = data.relatedArticles || [];
    state.nearByListings = data.nearByListings || [];
    state.paragarphics = data.paragarphics || null;
    state.bannerUrl = data.bannerUrl || null;
    state.bannerUrlWebp = data.bannerUrlWebp || null;
    state.pageTitle = data.pageTitle || '';
    state.zipcodeDisplayName = data.zipcodeDisplayName || '';
    state.cityDisplayName = data.cityDisplayName || '';
    state.listingCount = data.listingCount || 0;
    state.listings = data.listings || [];
    state.pages = data.pages || [];
    state.zipcodes = data.zipcodes || [];
    state.stateDisplayName = data.stateDisplayName || '';
    state.config = data.config || {};
    state.allStates = data.allStates || [];
    state.countyName = data.countyName || '';
    state.realEstateLinks = data.realEstateLinks || '';
    state.directoryType = data.directoryType || null;
    state.directoryTypes = data.directoryTypes || null;
    state.nearbyStates = data.nearbyStates ? (data.nearbyStates.nearStateSimpleBeanList || []) : [];
    state.letter = data.letter || '';
    state.letterUpperCase = data.letterUpperCase || '';
    state.popularCityList = data.popularCityList || [];
    state.biggestListings = data.biggestListings || [];
    state.nearByTitle = data.nearByTitle || '';
    state.biggestTitle = data.biggestTitle || '';
    state.headingTitle = data.headingTitle || '';
    state.subText = data.subText || '';
}

export default {
    updateSitemap
};
