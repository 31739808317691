var _scrollWidth = null;
export default {
    scrollWidth: function(){
        if(_scrollWidth !== null){
           return _scrollWidth;
        }
        var outer = document.createElement('div');
        outer.style.visibility = 'hidden';
        outer.style.width = '100px';
        outer.style.msOverflowStyle = 'scrollbar';
        document.body.appendChild(outer);
        var widthNoScroll = outer.offsetWidth;
        outer.style.overflow = 'scroll';
        var inner = document.createElement('div');
        inner.style.width = '100%';
        outer.appendChild(inner);
        var widthWithScroll = inner.offsetWidth;
        outer.parentNode.removeChild(outer);
        _scrollWidth = widthNoScroll - widthWithScroll;
        return _scrollWidth;
    }
};
