export default {
  scrollParentY: function($this) {
    var scrollParent;
    $this.parents().each(function() {
      var parent = $(this);
      if(parent.prop('scrollHeight') > parent.prop('clientHeight') && parent.css('overflowY') !== 'hidden'){
        scrollParent = parent;
        return false;
      }
    });
    if(scrollParent){
      return scrollParent.is('body, html') ? null : scrollParent.eq(0);
    }
    return null;
  },
  scrollParentX: function($this) {
    var scrollParent = $this.parents().each(function() {
      var parent = $(this);
      if(parent.prop('scrollWidth') > parent.prop('clientWidth') && parent.prop('scrollWidth') > $this.prop('scrollWidth') && parent.css('overflowX') !== 'hidden'){
        scrollParent = parent;
        return false;
      }
    });
    if(scrollParent){
      return scrollParent.is('body, html') ? null : scrollParent.eq(0);
    }
    return null;
  },
  scrollParent(element, includeHidden) {
    var style = getComputedStyle(element);
    var excludeStaticParent = style.position === 'absolute';
    var overflowRegex = includeHidden ? /(auto|scroll|hidden)/ : /(auto|scroll)/;

    if (style.position === 'fixed') { return document.body; }
    for (var parent = element; (parent = parent.parentElement);) {
        style = getComputedStyle(parent);
        if (excludeStaticParent && style.position === 'static') {
            continue;
        }
        if (overflowRegex.test(style.overflow + style.overflowY + style.overflowX)) { return parent; }
    }

    return document.body;
  }
};
