import debounce from 'lodash/debounce';
import throttle from 'lodash/throttle';

const isSSR = typeof window === 'undefined';

export default (app) => {
    if (!isSSR) {
        var widthBefore = $(window).width();
        $.winStatus = {
            sensitive: 0,
            originalScrollTop: $(window).scrollTop(),
            isLandscape: $(window).outerWidth() > $(window).outerHeight(),
            scrollTop: $(window).scrollTop(),
            scrollLeft: $(window).scrollLeft(),
            causeByKeyboard: false,
            isScrollDown: false,
            width: $(window).outerWidth(),
            height: $(window).outerHeight()
        };
    }
    var _isDev = function(){
        var $body = $('#body');
        if ($._isDev()) {
            $body.addClass('dev');
        }
    };
    var _isLandscap = function () {
        var $body = $('#body');
        $.winStatus.width = $(window).outerWidth();
        $.winStatus.height = $(window).outerHeight();
        $.winStatus.isLandscape = $.winStatus.width > $.winStatus.height;
        if ($.winStatus.isLandscape) {
            $body.addClass('landscape');
            $body.removeClass('portrait');
        } else {
            $body.addClass('portrait');
            $body.removeClass('landscape');
        }
    };
    var _isScrollDown = function () {
        $.winStatus.originalScrollTop = $.winStatus.scrollTop;
        $.winStatus.scrollTop = $(window).scrollTop();
        var sensitive = 0;
        if ($.winStatus.scrollTop > $.winStatus.originalScrollTop) {
            $.winStatus.isScrollDown = true;
        } else if ($.winStatus.scrollTop < $.winStatus.originalScrollTop) {
            $.winStatus.isScrollDown = false;
        }
        sensitive = Math.abs($.winStatus.scrollTop - $.winStatus.originalScrollTop);
        $.winStatus.sensitive = sensitive;
    };
    var _updateByResize = function () {
        _isScrollDown();
        _isLandscap();
    };
    var _updatebyScroll = function () {
        _isScrollDown();
    };
    ///------DONOT touch this code unless confirm with conjee--------
    var _initalDynamicCss = function(){
        var scrollWidth = $.scrollWidth();
        document.documentElement.style.setProperty('--scroll-size', scrollWidth + 'px');
    };
    var _updateVH = function(){
        document.documentElement.style.setProperty('--viewport-height', window.innerHeight + 'px');
    };
    //unbind is not working when use navigation to change page
    var unbind = function(){
        $(window).off('scroll.mvt');
        $(window).off('resize.mvt');
        $(document).off('keyup.mvt');
    };

    ///------DONOT touch this code unless confirm with conjee--------
    var _updateWindowStatus = function (type) {
        $.winStatus.causeByKeyboard = $('input, select, textarea').is(':focus');
        switch (type) {
            case 'inital':
                _updateVH();
                _initalDynamicCss();
                _updateByResize();
                break;
            case 'resize':
                // _initalDynamicCss();
                _updateByResize();
                break;
            case 'scroll':
                _updatebyScroll();
                break;
            case 'load':
                break;
            default:
                break;
        }
        return status;
    };
    var _initalNotification = function(){
        $.notification.initial();
    };

    app.directive('inital', isSSR ? {} : {
        beforeMount: function () {
            unbind();
            _isDev();
            _initalNotification();
            setTimeout(() => {
                $.loadPerimeterX();
            }, 2000);
            _updateWindowStatus('inital');
            $(window).on('popstate', function() {
                if(window.stopPop){
                    window.stopPop = false;
                    return;
                }
                $(document).trigger('dom.popstate', history.state && history.state.key);
            });
            //only excute for winodw resize without thorrtle
            window.addEventListener('resize', _updateVH);

            $(document).on('keyup.mvt', function(e) {
                if(!$('input, select, textarea').is(':focus')){
                    if(e.key == 'Escape') {
                        $(document).trigger('dom.key.esc');
                    } else if(e.key == 'ArrowLeft'){
                        $(document).trigger('dom.key.left');
                    } else if (e.key == 'ArrowRight'){
                        $(document).trigger('dom.key.right');
                    } else if (e.key == 'ArrowUp'){
                        $(document).trigger('dom.key.up');
                    } else if (e.key == 'ArrowDown'){
                        $(document).trigger('dom.key.down');
                    }
                }
            });
            $(window).on('scroll.mvt', throttle(function () {
                _updateWindowStatus('scroll');
                if(!$.winStatus.causeByKeyboard){
                    $(document).trigger('dom.scroll');
                }
            }, 500));
            $(window).on('resize.mvt', debounce(function () {
                _updateWindowStatus('resize');
                var isWidthChange = widthBefore != $(window).width();
                $(document).trigger('dom.resize', isWidthChange);
                widthBefore = $(window).width();
            }, 500));
            $(document).trigger('dom.load');
            $('.spinnerholder').hide();
            document.querySelector('#app').hidden = false;
        }
    });
};
