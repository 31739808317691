import $eventBus from '@/common/providers/eventbus';

const isSSR = typeof window === 'undefined';

var loadedXlead = 0;
function loadXlead() {
    var dfd = new Promise((resolve, reject) => {
        if (loadedXlead === 2) {
            resolve(window.LeadiD);
        } else if (loadedXlead === 0) {
            loadedXlead = 1;
            var id = '14f12c5d-25f7-93f6-83f6-9b168cc1eb12';
            $.preload({ files: [`//create.lidstatic.com/campaign/${id}.js?snippet_version=2`], type: 'js' }).then(function () {
                loadedXlead = 2;
                resolve(window.LeadiD);
            });
        }
    });
    return dfd;
}
var time;
export default (app) => {
    app.directive('xlead', isSSR ? {} : {
        beforeMount() {
            if (!window.__INITIAL_STATE__ || !window.__INITIAL_STATE__.enableThirdPart) {
                return;
            }
            window.xLeadId = true;
            $eventBus.$on('xlead-form-snap', function () {
                if (time) {
                    clearTimeout(time);
                }
                time = setTimeout(() => {
                    loadXlead().then(function (LeadiD) {
                        // console.log('xlead-form-snap');
                        LeadiD && LeadiD.snap();
                    });
                }, 100);
            });
            $eventBus.$on('xlead-snap', function () {
                loadXlead().then(function (LeadiD) {
                    // console.log('xlead-snap');
                    LeadiD && LeadiD.snap();
                });
            });
            $eventBus.$on('xlead-stop', function () {
                setTimeout(() => {
                    // console.log('xlead-stop');
                    window.LeadiD && window.LeadiD.destroy();
                }, 30000);
            });

            setTimeout(() => {
                if (!window.LeadiD) {
                    $eventBus.$emit('xlead-snap');
                }
            }, 15000);
            $eventBus.$on('eventbus-dialog-open', function () {
                $eventBus.$emit('xlead-snap');
            });
        },
    });
};
