import officeInfo from './data.brokerage';
import stateData from './data.state';
import mortgageOptions from './data.mortgageoptions';
import format from '../../utilities/format';
import attrTextMapper from './data.attributes';
import urlMixin from '../../mixins/url.js';

const isSSR = typeof window === 'undefined';

var complianceConfig = null;
const availableCities = ['Las Vegas', 'Henderson', 'North Las Vegas', 'Boulder City', 'Mesquite', 'Sloan', 'Jean', "Mountain's Edge", 'Primm', 'Nelson', 'Arden'];
const glb = (state) => state;
const viewport = (state) => state.viewport;
const viewedListings = (state) => state.viewedListings;
const favoriteListings = (state) => state.favoriteListings;
const getLastSearch = (state) => state.lastSearch;
const getPrevSearch = (state) => state.prevSearch;
const getRecentSearchList = (state) => state.recentSearchList;
const getAutoSuggest = (state) => state.autoSuggest;
const getSubscriptions = (state) => state.subscriptions;
const getAutoSearchResult = (state) => state.getAutoSearchResult;
const getMenu = (state) => state.menu;
const getFeed = (state) => state.feed;
const getDigsSubscribe = (state) => state.digsSubscribe;
const getBrokerageAgents = (state) => state.brokerageAgents;
const getAgent = (state) => state.agent;
const getClaimedHomes = (state) => {
    if (state.claimedHomes && state.claimedHomes.length) {
        const claimedHomesId = state.claimedHomes
            .sort((a, b) => {
                return b.lastUpdatedAt - a.lastUpdatedAt;
            })
            .map((item) => item.propertyId);

        state.claimedPropertiesList.sort((a, b) => {
            let indexA = claimedHomesId.indexOf(a.propertyId);
            let indexB = claimedHomesId.indexOf(b.propertyId);
            return indexA - indexB;
        });

        return state.claimedPropertiesList;
    }
    return state.claimedPropertiesList;
};

const getClaimedHomesWithMortgageInfo = (state) => (propertyId) => {
    return (state.claimedHomes || []).find((home) => home.propertyId === propertyId);
};

const getSavedHomesCount = (state) => {
    return state.user.id && state.favoriteListings ? state.favoriteListings.length : 0;
};

const getSavedSearchesCount = (state) => {
    return state.user.id && state.savedSearches ? state.savedSearches.length : 0;
};

const getSavedSearches = (state) => {
    return state.user.id && state.savedSearches;
};

const isBrokerageArea = (state) => {
    const geo = state.geo;
    return geo.state === 'CA' || (geo.state === 'NV' && availableCities.includes(geo.city));
};
const getOfficeInfo = () => {
    return officeInfo;
};

const getStateData = () => {
    return stateData;
};
const getMortgageOptionsData = () => {
    return mortgageOptions;
};

const getProgressStatus = (state) => state.progressStatus;
const getMlsIds = (state) => state.mlsIds;
const getSeoInfo = (state) => state.seo;
const getMetaObj = (state) => state.metaObj;

const getPopularList = (state) => {
    //This is quick fix, need to remove all features related code from code base.
    // const geo = state.geo;
    // if(geo.state==='TX' && (geo.geoType === "CITY") && geo.city && geo.cityCode){
    //     const attrList = Object.keys(attrTextMapper);
    //     const attrBaseUrl = urlMixin.methods.generateMapSearchUrl(state.appUrl, {
    //         type: 'city',
    //         cityName: geo.city,
    //         stateCode: geo.stateCode,
    //         filterArray: ['attributes']
    //     }).filteredUrlObj['attributes'].replace(/\/$/, '');
    //     let poplist =  attrList.map(attr => {
    //         return {nameForUrl : `${attrBaseUrl}${attr}/`, displayName : `${geo.city} ${attrTextMapper[attr]}`};
    //     }
    //         );
    //     return poplist;
    // }

    return [];
};

const getPhoneNumber = (state) => {
    if (state.pageType === 'vdpp') {
        return state.phoneNumber2 || state.phoneNumber;
    }
    return state.phoneNumber;
};
const getHeaderPhoneNumber = (state) => state.headerPhoneNumber || state.phoneNumber;

// Use method-style access to prevent caching that causes memory leaks during SSR https://vuex.vuejs.org/guide/getters.html#method-style-access
// Hook up to components in the methods section (NOT computed) `methods: { ...mapGetters('glb', ['getComplianceMethod']) }`
// Invoke only within methods of your components like this `this.getComplianceMethod()()`
const getComplianceMethod = (state) => () => {
    return state.mlsIds.map(function (id) {
        return Object.assign({ id: id, name: '', rule: [], desc: '', desc1: '' }, state.compliance[id]);
    });
};

const getRecentlyViewed = (state) => state.recentlyViewed;
const getZipNewListings = (state) => state.zipNewListings;
const getZipReducedListings = (state) => state.zipReducedListings;
const getZipRecentlySoldListings = (state) => state.zipRecentlySoldListings;
const getZipOpenListings = (state) => state.zipOpenListings;
const getCityCondosListings = (state) => state.cityCondosListings;
const getCityUrlListings = (state) => state.cityUrlListings;
const getCityNewListings = (state) => state.cityNewListings;
const getCityReducedListings = (state) => state.cityReducedListings;
const getCityOpenListings = (state) => state.cityOpenListings;
const getCityBestValueListings = (state) => state.cityBestValueListings;
const getNeighborhoodBestValueListings = (state) => state.neighborhoodBestValueListings;
const getNeighborhoodReducedListings = (state) => state.neighborhoodReducedListings;
const getZipBestValueListings = (state) => state.zipBestValueListings;
const getSurroundingListings = (state) => state.surroundingListings;
const getNearbySale = (state) => state.nearbySale;
const getNearbySold = (state) => state.nearbySold;
const getNeighborhoodNewListings = (state) => state.neighborhoodNewListings;
const getNeighborhoodOpenListings = (state) => state.neighborhoodOpenListings;
const getNearbyHomeFromAttribute = (state) => (attributId) => state.attributeNearbyHomes[attributId];
const getNearbyHomesFromLatLng = (state) => state.nearbyHomes;
const getTags = (state) => state.tags;
const getSplits = (state) => state.splits;
const getSplit = (state) => (key, value) => {
    if (!state.splits[key]) {
        return false;
    }
    if (value) {
        return state.splits[key] === value;
    }
    return state.splits[key].indexOf('on') === 0;
};
const getRecentTags = (state) => state.recentlyViewedTags;

const getErrorHtml = (state) => (err) => {
    if (err.code === 30) {
        return `Please enter a valid mobile phone number,<br> or call us <a class="link" href="tel:${state.phoneNumber}"><i class="icon-phone-o"></i> ${format.fomatPhoneNumber(
            state.phoneNumber,
            '($1) $2-$3'
        )}</a>`;
    }
    if (err.code === 34) {
        if (err.data && err.data.suggest) {
            return `Did you mean: <b>${err.data.suggest}</b>`;
        }
        return `Please enter a valid email address`;
    }
    return err.msg || 'Unknown Error';
};
const getSignalJWT = (state) => state.signalJWT;
const getMovotoDeviceID = (state) => state.movotoDeviceID;
const mapType = (state) => state.mapType;
const mapDetails = (state) => state.mapDetails;

const getSelectedPoi = (state) => state.selectedPoi;
const getOpendoor = (state) => state.opendoor;
const hasAgent = (state) => state.agent && state.agent.id;
const removeAds = (state) => (isSSR || state.removeAds ? true : state && state.mlsIds && (state.mlsIds.indexOf(102) >= 0 || state.mlsIds.indexOf(1048) >= 0));
const removeAllAds = (state) => (isSSR || state.removeAds ? true : state && state.mlsIds && state.mlsIds.indexOf(1048) >= 0);
const getVideos = (state) => state.nearbyVideos;
const getPlaces = (state) => state.nearbyPlaces;

const getPrevHotlead = (state) => state.prevHotlead;
const popLeadBeforeRedirect = (state) => state.popLeadBeforeRedirect;

export default {
    glb,
    removeAds,
    hasAgent,
    viewport,
    viewedListings,
    favoriteListings,
    getProgressStatus,
    getLastSearch,
    getPrevSearch,
    getAutoSuggest,
    getSubscriptions,
    getAutoSearchResult,
    getOfficeInfo,
    getSeoInfo,
    getMlsIds,
    getComplianceMethod,
    getStateData,
    getMortgageOptionsData,
    getClaimedHomes,
    getClaimedHomesWithMortgageInfo,
    getMenu,
    getPhoneNumber,
    getHeaderPhoneNumber,
    getRecentlyViewed,
    getZipNewListings,
    getZipReducedListings,
    getZipRecentlySoldListings,
    getZipOpenListings,
    getCityCondosListings,
    getCityUrlListings,
    getCityNewListings,
    getCityReducedListings,
    getCityOpenListings,
    getCityBestValueListings,
    getFeed,
    getDigsSubscribe,
    getBrokerageAgents,
    isBrokerageArea,
    getSurroundingListings,
    getNearbySale,
    getNearbySold,
    getNeighborhoodNewListings,
    getNeighborhoodOpenListings,
    getTags,
    getRecentTags,
    getNearbyHomeFromAttribute,
    getSplit,
    getSplits,
    getErrorHtml,
    getSignalJWT,
    getMovotoDeviceID,
    mapType,
    mapDetails,
    getSavedHomesCount,
    getSavedSearchesCount,
    getSavedSearches,
    getPopularList,
    getSelectedPoi,
    getAgent,
    getOpendoor,
    getNearbyHomesFromLatLng,
    getPrevHotlead,
    popLeadBeforeRedirect,
    getNeighborhoodBestValueListings,
    getNeighborhoodReducedListings,
    getZipBestValueListings,
    getMetaObj,
    removeAllAds,
    getRecentSearchList,
    getVideos,
    getPlaces,
};
