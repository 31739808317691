import mapper from '../mapper/index.js';

function getCategoryList(arr, state) {
    if (arr && arr.length) {
        return arr.map(function(e) {
            return {
                text: e.name,
                href: state.appUrl + 'guide/' + e.url,
                path: 'guide/' + e.url
            };
        });
    }
    return [];
}

function getPropertyList(arr, state) {
    if (arr && arr.length) {
        return arr.map(function(e) {
            return mapper.property(e, state);
        });
    }
    return [];
}

function updateGuide(state, { data, glb }) {
    state.geo = data.geo;
    state.pageType = data.pageType;
    state.pageHeading = data.pageHeading;
    state.article = processArticleData(data.article, glb);
    state.hottestArticles = data.hottestArticles || [];
    state.latestArticles = data.latestArticle || [];
    state.pageCount = data.pageCount || 0;
    state.paginationText = data.paginationHTML || '';
    state.neighborhoods = data.neighborhoods || [];
    state.states = data.states || [];
    state.articleType = data.articleType || '';
    state.isHub = !!data.isHub;
    state.hubType = data.hubType || '';
    state.articles = data.articles || [];
    state.neighHubs = data.neighHubs || [];
    state.homeForSaleNearByCity = data.homeForSaleNearByCity || [];
    state.postalCodeInCity = data.postalCodeInCity || [];
    state.relatedArticles = data.relatedArticles || [];
    state.topNeighborhood = data.topNeighborhood || [];
    state.topZipCode = data.topZipCode || [];
    state.newListings = getPropertyList(data.newListings, glb);
    state.nextArticle = data.nextArticle || null;
    state.prevArticle = data.prevArticle || null;
    state.heroImage = data.heroImage || '',
    state.marketOverviewInfo = data.marketOverviewInfo || [];
}

function processArticleData(article, glb) {
    if (!article) return null;
    (article.bodyList || []).forEach(function(item) {
        if (item.type === 'listings') {
            item.data = getPropertyList(item.data, glb);
        }
    });
    return article;
}

function updateCategoryList(state, { data, glb }) {
    state.categories = getCategoryList(data, glb);
}

export default {
    updateGuide,
    updateCategoryList
};
