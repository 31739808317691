import mapper from '../mapper/index.js';

function getGeoByBasic(basic) {
    if(!basic){
        return null;
    }
    return {
        hasGeoInfo: !!basic.state,
        state: basic.state,
        city: basic.city,
        cityId: basic.cityId,
        county: basic.county,
        countyId: basic.countyId,
        zipcode: basic.zip,
        address: basic.street,
        subPremise: '',
        lat: basic.lat,
        lng: basic.lon
    };
}

function getPropertyList(arr, state){
    if(arr && arr.length){
        return arr.map(function(e){
           return mapper.property(e, state);
        });
    }
    return [];
}

function updateDsp(state, {data, glb}) {
    var basic = data.basic;
    var dsp = {
        name: basic.name,
        schoolId: basic.uId,
        ncesId: basic.ncesId,
        type: basic.type,
        rating: basic.rating,
        subType: basic.subType,
        boundaryId: basic.boundaryId,
        districtId: basic.boundaryId,
        districtName: basic.districtName,
        districtNcesId: basic.districtNcesId,
        dspUrl: basic.dspUrl,
        externalUrls: basic.externalUrls,
        level: basic.level,
        levelCode: basic.levelCode,
        nearbyHomesLink: basic.nearbyHomesLink,
        preSchoolInd: basic.preSchoolInd,
        elementarySchoolInd: basic.elementarySchoolInd,
        highSchoolInd: basic.highSchoolInd,
        middleSchoolInd: basic.middleSchoolInd,
        tsRatio: data.census ? data.census.TeacherStudentRatio : null,
        avgRating: data.summary ? data.summary.avgQuality : null
    };
    var geoInfo = getGeoByBasic(basic);
    if(geoInfo){
        dsp.geo = mapper.geo(geoInfo, glb.rentals);
    }

    Object.assign(state, dsp);

    state.schoolSearchUrl = glb.appUrl + state.geo.cityCode + '-' + state.geo.stateCode + '/saz-' + state.schoolId + '/';
    state.seoNearbyZipCode = data.seoNearbyZipCode;
    state.seoNearbyneighborhood = data.seoNearbyneighborhood;
    state.seoNearbyCities = data.seoNearbyCities;
    state.seoNearbyCounties = data.seoNearbyCounties;
    state.stateRating = data.stateRating;
    state.tests = data.tests;
    state.MarketStatisticsData = data.MarketStatisticsData;
    state.allSchoolUrl = data.allSchoolUrl;
    state.districtSchools = data.districtSchools;
    state.summary = data.summary || {};
    state.census = data.census;
    state.reviews = data.reviews;
    state.assignHomes = data.assignHomes && getPropertyList(data.assignHomes, glb);
    state.nearbyschools = data.nearbyschools;

}

export default {
    updateDsp
};

