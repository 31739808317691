import getGeo from '../glb/geo';

export default function () {
    return {
        geo: getGeo(),
        displayName: '',
        phone: '',
        url: '',
        googleMapUrl: '',
        snapshot: null,
        nearbyHomeList: [],
        neighborhoods: [],
        zipcodes: [],
        counties: [],
        cities: [],
    };
}
