
var Router = function() {};

Router.prototype.add = function(key, path) {
    if (window.history && key) {
        if (history.state && history.state.key === key) {
            history.replaceState({key: key}, null, path||`#${key}`);
        } else {
            history.pushState({key: key}, null, path||`#${key}`);
        }
    }
};

Router.prototype.back = function(key, stopPop){
    if (history.state && history.state.key === key) {
        window.stopPop = true;
        history.back();
    }
};


var router = new Router();

export default {
    router: router
};
