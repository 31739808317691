var _isMobile = null;
export default {
    isMobile: function() {
        if (_isMobile !== null) {
            return _isMobile;
        }
        _isMobile = !!/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
        return _isMobile;
    },
    _isDev: function(){
        return window.__INITIAL_STATE__ && window.__INITIAL_STATE__.isDevelopment;
    }
};
