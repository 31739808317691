export default {
    aa: 'Armed Forces America',
    ae: 'Armed Forces',
    ap: 'Armed Forces Pacific',
    ak: 'Alaska',
    al: 'Alabama',
    ar: 'Arkansas',
    az: 'Arizona',
    ca: 'California',
    co: 'Colorado',
    ct: 'Connecticut',
    dc: 'Washington DC',
    de: 'Delaware',
    fl: 'Florida',
    ga: 'Georgia',
    gu: 'Guam',
    hi: 'Hawaii',
    ia: 'Iowa',
    id: 'Idaho',
    il: 'Illinois',
    in: 'Indiana',
    ks: 'Kansas',
    ky: 'Kentucky',
    la: 'Louisiana',
    ma: 'Massachusetts',
    md: 'Maryland',
    me: 'Maine',
    mi: 'Michigan',
    mn: 'Minnesota',
    mo: 'Missouri',
    ms: 'Mississippi',
    mt: 'Montana',
    nc: 'North Carolina',
    nd: 'North Dakota',
    ne: 'Nebraska',
    nh: 'New Hampshire',
    nj: 'New Jersey',
    nm: 'New Mexico',
    nv: 'Nevada',
    ny: 'New York',
    oh: 'Ohio',
    ok: 'Oklahoma',
    or: 'Oregon',
    pa: 'Pennsylvania',
    pr: 'Puerto Rico',
    ri: 'Rhode Island',
    sc: 'South Carolina',
    sd: 'South Dakota',
    tn: 'Tennessee',
    tx: 'Texas',
    ut: 'Utah',
    va: 'Virginia',
    vi: 'Virgin Islands',
    vt: 'Vermont',
    wa: 'Washington',
    wi: 'Wisconsin',
    wv: 'West Virginia',
    wy: 'Wyoming',
};
