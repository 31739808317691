import load from './_load';
import loadmap from './_load.map';
import loadImg from './_load.img';
import loadBankratePop from './_load.ojolink';
import loadCmAds from './_load.ojolink';
import loadThirdPartScripts from './_load.thirdparty.scripts';
import loadauth from './_load.auth';
import geoservice from './_geoservice';
import localstorage from './_localstorage';
import device from './_device';
import delay from './_delay';
import createCss from './_createcss';
import browser from './_browser';
import cookie from './_cookie';
import scrollWidth from './_scrollwidth';
import notification from './_notification';
import utilities from './_utilities';
import scrollTo from './_scrollto';
import scrollParent from './_scrollParent';
import overlayer from './_overlayer';
import fullscreen from './_fullscreen';
import loadChart from './_load.chart';
import router from './_router';
import redirect from './_redirect';

var jqext = {};
Object.assign(jqext, scrollParent);
Object.assign(jqext, utilities);
Object.assign(jqext, load);
Object.assign(jqext, loadmap);
Object.assign(jqext, loadBankratePop);
Object.assign(jqext, loadThirdPartScripts);
Object.assign(jqext, loadauth);
Object.assign(jqext, loadChart);
Object.assign(jqext, geoservice);
Object.assign(jqext, browser);
Object.assign(jqext, cookie);
Object.assign(jqext, scrollWidth);
Object.assign(jqext, scrollTo);
Object.assign(jqext, localstorage);
Object.assign(jqext, device);
Object.assign(jqext, createCss);
Object.assign(jqext, delay);
Object.assign(jqext, notification);
Object.assign(jqext, overlayer);
Object.assign(jqext, loadImg);
Object.assign(jqext, fullscreen);
Object.assign(jqext, router);
Object.assign(jqext, redirect);
Object.assign(jqext, loadCmAds);
Object.assign(jqext, { event: { special: {} } });

export default jqext;

export const fnExt = {
    scrollTop: function (amount) {
        // when an amount is passed in, we set the scroll position
        if (amount !== undefined) {
            if (this.is(window)) {
                window.scrollTo(0, amount);
            } else {
                this.prop('pageYOffset', amount);
                this.get(0).scroll({
                    top: amount,
                    behavior: 'smooth',
                });
            }
        }
        return (this.is(window) ? this.prop('pageYOffset') : (this[0] && this[0].scrollTop)) || 0;
    },
    scrollLeft: function (amount) {
        // when an amount is passed in, we set the scroll position
        if (amount !== undefined) {
            if (this.is(window)) {
                window.scrollTo(amount, 0);
            } else {
                this.prop('pageXOffset', amount);
                this.get(0) && this.get(0).scroll({
                    left: amount,
                    behavior: 'smooth',
                });
            }
        }
        return (this.is(window) ? this.prop('pageXOffset') : (this[0] && this[0].scrollLeft)) || 0;
    },
};