import fomat from '../../utilities/format.js';
import states from '../glb/data.state.js';
import urlMixin from '../../../common/mixins/url.js';

function capitalize(word) {
    if (!word) {
        return word;
    }
    var words = word.split(' ');
    return words
        .map(function (str) {
            const lower = str.toLowerCase();
            return str.charAt(0).toUpperCase() + lower.slice(1);
        })
        .join(' ');
}

function convertGeoInfo(data) {
    var geoInfo = {};
    if (data.stateCode) {
        geoInfo.state = data.stateCode;
    }
    if (data.location) {
        geoInfo.lat = data.location.lat;
        geoInfo.lng = data.location.lng;
    }
    if (data.type === 'STATE') {
        geoInfo.state = data.displayName;
        geoInfo.stateId = data.id;
    } else if (data.type === 'CITY') {
        geoInfo.city = data.displayName;
        geoInfo.cityId = data.id;
    } else if (data.type === 'COUNTY') {
        geoInfo.county = data.displayName;
        geoInfo.countyId = data.id;
    } else if (data.type === 'POSTAL_CODE') {
        geoInfo.city = data.cityDisplayName;
        geoInfo.zipcode = data.displayName;
        geoInfo.zipcodeId = data.id;
    } else if (data.type === 'NEIGHBORHOOD' || data.type.endsWith('_NEIGHBORHOOD')) {
        geoInfo.city = data.cityDisplayName;
        geoInfo.neighborhood = data.displayName;
        geoInfo.neighborhoodId = data.id;
        geoInfo.neighborhoodPath = data.sitemapNeighborhoodPageUrl;
    } else {
        return data;
    }
    return geoInfo;
}

function getGeoType(data) {
    if (data.address) {
        return 'ADDRESS';
    } else if (data.zipcode) {
        return 'ZIPCODE';
    } else if (data.neighborhood) {
        return 'NEIGHBORHOOD';
    } else if (data.city) {
        return 'CITY';
    } else if (data.county) {
        return 'COUNTY';
    } else if (data.state) {
        return 'STATE';
    }
    return 'GPS';
}

function getGeoId(data, geoType) {
    if (geoType === 'ZIPCODE') {
        return data.zipcodeId;
    } else if (geoType === 'NEIGHBORHOOD' || geoType.endsWith('_NEIGHBORHOOD')) {
        return data.neighborhoodId;
    } else if (geoType === 'CITY') {
        return data.cityId;
    } else if (geoType === 'COUNTY') {
        return data.countyId;
    } else if (geoType === 'STATE') {
        return data.stateId;
    }
    return '';
}

function getGeoName(data, geoType) {
    if (geoType === 'ZIPCODE') {
        return data.zipcode;
    } else if (geoType === 'NEIGHBORHOOD' || geoType.endsWith('_NEIGHBORHOOD')) {
        return data.neighborhood;
    } else if (geoType === 'CITY') {
        return data.city;
    } else if (geoType === 'COUNTY') {
        return data.county;
    } else if (geoType === 'STATE') {
        return data.state;
    }
    return '';
}

const _formatText = function (text) {
    return text
        .replace(/[^a-zA-Z0-9]+/gi, '-')
        .replace(/-*\/$/, '/')
        .replace(/-$/, '');
};

function initalGeoByUrl(path, rentals) {
    if (!path) {
        return;
    }
    var geo = { hasGeoInfo: false };
    var url = path.replace(/^\//, '');
    url = url.replace(/\/$/, '');
    if (url) {
        var segments = url.split('/');
        var city = /^([a-z\-]+)\-(\w{2})/i.exec(segments[0]);
        if (city) {
            geo.state = city[city.length - 1].toUpperCase();
            geo.stateCode = geo.state.toLowerCase();
            geo.cityCode = city[city.length - 2];
            geo.city = fomat.capitalize(geo.cityCode.replace(/\-/g, ' '));
            if (segments[1]) {
                var address = /^([a-z\-]+)\-(\d{5})/i.exec(segments[1]);
                if (address) {
                    geo.zipcode = address[city.length - 1];
                    geo.address = address[city.length - 2].replace(/\-/g, ' ');
                } else {
                    geo.zipcode = '';
                    geo.address = '';
                }
            } else {
                geo.zipcode = '';
                geo.address = '';
            }
        } else if (/[a-z]{2}/i.test(segments[0])) {
            geo.state = segments[0].toUpperCase();
            geo.stateCode = segments[0].toLowerCase();
            geo.city = '';
            geo.cityCode = '';
            geo.address = '';
            if (/\d{5}/.test(segments[1])) {
                geo.zipcode = segments[1].toLowerCase();
            }
        }
        geo.hasGeoInfo = !!geo.state;
    }
    return geo;
}

function getNeighbourhoodName(data) {
    var city = data.city && data.city.toLowerCase();
    if (data.neighborhood && data.neighborhood.toLowerCase() !== city) {
        return data.neighborhood;
    } else if (data.neighborhoodN && data.neighborhoodN.toLowerCase() !== city) {
        return data.neighborhoodN;
    } else if (data.neighborhoodS && data.neighborhoodS.toLowerCase() !== city) {
        return data.neighborhoodS;
    } else if (data.neighborhoodM && data.neighborhoodM.toLowerCase() !== city) {
        return data.neighborhoodM;
    } else if (data.neighborhoodName && data.neighborhoodName.toLowerCase() !== city) {
        return data.neighborhoodName;
    }
    return '';
}

function getFormatAddress(data) {
    var list = [];
    if (data.address) {
        list.push(data.address);
    }
    if (data.city) {
        list.push(data.city);
    }
    if (data.state) {
        list.push(data.state);
    }
    return list.join(', ') + (data.zipcode ? ' ' + data.zipcode : '');
}

export default function (geo, rentals) {
    var data = geo;
    if (!geo) {
        return {
            hasGeoInfo: false,
        };
    } else if (typeof geoInfo === 'string') {
        return initalGeoByUrl(geo);
    } else if (geo.type) {
        //if it soa geo data do mapping
        data = convertGeoInfo(geo);
    }

    var neighborhood = getNeighbourhoodName(data);
    //if neighborhood is same as city, then it is not a neighborhood, and dont show neighborhood url
    if (!neighborhood) {
        data.neighborhoodPath = data.sitemapNeighborhoodPageUrl = '';
    }

    var geoInfo = {
        hardcode: data.hardcode || false,
        hasGeoInfo: !!data.state,
        state: data.state ? data.state.toUpperCase() : '',
        stateCode: data.state ? data.state.toLowerCase() : '',
        stateId: data.stateId || '',
        city: data.city ? capitalize(data.city) : '',
        cityCode: data.city ? _formatText(data.city).toLowerCase() : '',
        cityId: data.cityId || '',
        county: data.county ? capitalize(data.county) : '',
        countyId: data.countyId || '',
        countyCode: data.county ? _formatText(data.county).toLowerCase() : '',
        neighborhood: neighborhood || '',
        neighborhoodN: data.neighborhoodN || '',
        neighborhoodS: data.neighborhoodS || '',
        neighborhoodM: data.neighborhoodM || '',
        neighborhoodId: data.neighborhoodId || data.neighborhoodNGeoId || data.neighborhoodSGeoId || data.neighborhoodMGeoId,
        neighborhoodPath: data.neighborhoodPath || data.sitemapNeighborhoodPageUrl || '',
        neighborhoodType: data.neighborhoodType || '',
        neighborhoodCode: neighborhood ? _formatText(neighborhood).toLowerCase() : '',
        zipcode: data.zipcode || '',
        zipcodeId: data.zipcodeId || '',
        address: data.address || '',
        subPremise: data.subPremise || '',
        lat: data.lat || 0,
        lng: data.lng || 0,
        stateFullName: states[data.state] || '',
        extraPath: data.extraPath || '',
    };
    geoInfo.formatAddress = getFormatAddress(geoInfo);
    geoInfo.geoType = getGeoType(geoInfo);
    geoInfo.geoId = getGeoId(geoInfo, geoInfo.geoType);
    geoInfo.geoName = getGeoName(geoInfo, geoInfo.geoType);
    geoInfo = Object.assign(geoInfo, urlMixin.methods.generateGeoPaths(geoInfo, { rentals }));
    //if data.neighborhoodPagePath is empty string, means no neighborhood page url
    //then reset geoInfo.neighborhoodPagePath to empty string
    if (data.neighborhoodPagePath === '' && geoInfo.neighborhoodPagePath) {
        geoInfo.neighborhoodPagePath = '';
    }
    return geoInfo;
}
