import validate from '@/common/utilities/validate';

const isSSR = typeof window === 'undefined';

export default (app) => {
    const errorMsg = {
        required: {
            name: 'Full Name required',
            phoneNumber: 'Mobile number required',
            email: 'Email required',
            comment: 'Message required',
            username: 'Email (Movoto ID) required',
            password: 'Password required',
            zipcode: 'ZIP Code Required',
            address: 'Address is required',
            reviewTitle: 'Review title is required',
            serviceProvided: 'Service provided is required',
            selectOption: 'Please Select an Option',
            yearPurchased: 'Year purchased is required',
            subject: 'Subject is required',
            rating: 'Rating is required',
            description: 'Description required',
            fullName: 'Full name is required',
            geo: 'Select a location',
            amount: 'Amount is required',
            date: 'Date is required',
            rate: 'Rate is required'
        },
        format: {
            password: 'Min of 5 char. Mix letters & numbers',
            email: 'Invalid email',
            phoneNumber: 'Invalid mobile number',
            zipcode: 'Invalid ZIP Code',
            fullName: 'Missing a space " "',
            geo: 'Invalid location',
            amount: 'Amount is required',
            rate: 'Rate is required',
            selectOption:'Please Select an Option'
        },
    };
    const skipValidate = {
        name: true,
        comment: true,
        reviewTitle: true,
        rating: true,
        address: true,
        serviceProvided: true,
        selectOption: true,
        yearPurchased: true,
        subject: true,
        description: true,
        geo: true,
        amount: true,
        date: true,
        rate: true
    };
    const changeEvent = !isSSR && new Event('change', { bubbles: false });

    function verify(required, rules, value, opt) {
        var assertion = { flag: true, msg: [] };
        if (!value) {
            if (required) {
                assertion.flag = false;
                assertion.msg.push(errorMsg.required[rules[0]] || 'Input Required');
            }
        } else if (rules && rules.length) {
            assertion = rules.reduce(function (prev, next) {
                if (validate[next]) {
                    if (!validate[next](value, opt)) {
                        assertion.flag = false;
                        assertion.msg.push(errorMsg.format[next]);
                    }
                } else if (!skipValidate[next]) {
                    assertion.flag = false;
                    assertion.msg.push(`${next} cannot pass`);
                }
                return assertion;
            }, assertion);
        }
        return assertion;
    }

    function update(el, assertion) {
        var parent;
        if (el.tagName === 'TEXTAREA') {
            parent = el.closest('.textarea');
        } else {
            parent = el.closest('.input');
        }
        if (parent) {
            if (assertion.flag) {
                parent.removeAttribute('error');
            } else {
                parent.setAttribute('error', assertion.msg);
            }
        }
        return assertion.flag;
    }

    function doLiveValidation(el, str, opts) {
        var assertion = { flag: true, msg: [] };
        for (var method in opts.rules) {
            opts.rules[method].status = validate[method](str, opts.rules[method].params);
            if (!opts.rules[method].status) {
                assertion.flag = false;
            }
        }
        if (!assertion.flag) {
            assertion.msg.push('Invalid input');
        }
        update(el, assertion);
    }

    app.directive(
        'submit',
        isSSR
            ? {}
            : {
                  beforeMount(el, binding) {
                      el.addEventListener('submit', function (e) {
                          const event = new Event('change');
                          const keyUpEvent = new Event('keyup');
                          el.querySelectorAll('[validation]').forEach(function (item) {
                              if ($(item).hasClass('live-validation')) {
                                item.dispatchEvent(keyUpEvent);
                              } else {
                                item.dispatchEvent(event);
                              }
                          });
                          if (el.querySelectorAll('[error]').length === 0) {
                              if (binding.value instanceof Function) {
                                  binding.value();
                              }
                          }
                          e.stopPropagation();
                          e.preventDefault();
                      });
                  },
              }
    );
    app.directive(
        'validate',
        isSSR
            ? {}
            : {
                  beforeMount(el, binding) {
                      if (binding.value) {
                        if (binding.arg && binding.arg.liveValidationOpt) {
                            el.addEventListener('keyup', function (e) {
                                let text = $(el).val() || '';
                                doLiveValidation(el, text, binding.arg.liveValidationOpt);
                            });
                        } else if ($(el).hasClass('input-geo')) {
                              el.addEventListener('change', function (e) {
                                  let text = e.target.dataset.text || '';
                                  let geoTypes = e.target.dataset.geotype ? e.target.dataset.geotype.split(',') : '';
                                  let geo;
                                  try {
                                      geo = e.target.dataset.geo ? JSON.parse(e.target.dataset.geo) : null;
                                  } catch (e) {
                                      geo = null;
                                  }
                                  let rules = binding.value.split('|');
                                  let isRequired = rules.indexOf('required') >= 0;
                                  if (isRequired) {
                                      rules.splice(rules.indexOf('required'), 1);
                                  }
                                  update(el, verify(isRequired, rules, text, { geo: geo, geoTypes: geoTypes }));
                              });
                          } else {
                              el.addEventListener('change', function (e) {
                                  let rules = binding.value.split('|');
                                  let isRequired = rules.indexOf('required') >= 0;
                                  // console.log('is requred', isRequired);
                                  let value;
                                  if (e.target.className == 'mvt-rating') {
                                      let arr = e.target.children[0].children;
                                      for (let i = 0; i < arr.length; i++) {
                                          if ($(arr[i]).hasClass('checked')) {
                                              value = arr[i].firstChild._value;
                                          }
                                      }
                                  } else if ($(el).hasClass('select')) {
                                      value = $(el).find('select').val();
                                  } else {
                                      value = e.target.value;
                                  }
                                  if (isRequired) {
                                      rules.splice(rules.indexOf('required'), 1);
                                  }
                                  update(el, verify(isRequired, rules, value));
                              });
                          }

                          el.setAttribute('validation', '');
                      }
                  },
              }
    );
    app.directive(
        'valueChange',
        isSSR
            ? {}
            : {
                  // detects change in value of form field element and triggers "change" event on the element
                  // useful for triggering "change" event if the "model/value" changes programmatically
                  updated: function (el, binding) {
                      if (binding.value !== binding.oldValue || binding.value === undefined) {
                          el.dispatchEvent(changeEvent);
                      }
                  },
              }
    );
};
