export default {
    throttle: function(func, waitTime, options) {
        var context,
            args,
            result,
            wait = waitTime || 200;
        var timeout = null;
        var previous = 0;
        if (!options)
            {options = {};}
        var later = function() {
            previous = options.leading === false
                ? 0
                : +new Date();
            timeout = null;
            result = func.apply(context, args);
            if (!timeout)
                {context = args = null;}
            };
        return function() {
            var now = +new Date();
            if (!previous && options.leading === false)
                {previous = now;}
            var remaining = wait - (now - previous);
            context = this;
            args = arguments;
            if (remaining <= 0 || remaining > wait) {
                if (timeout) {
                    clearTimeout(timeout);
                    timeout = null;
                }
                previous = now;
                result = func.apply(context, args);
                if (!timeout)
                    {context = args = null;}
                } else if (!timeout && options.trailing !== false) {
                timeout = setTimeout(later, remaining);
            }
            return result;
        };
    },
    debounce: function(func, waitTime, immediate) {
        var timeout,
            args,
            context,
            timestamp,
            result,
            wait = waitTime || 200;
        var later = function() {
            var last = +new Date() - timestamp;

            if (last < wait && last >= 0) {
                timeout = setTimeout(later, wait - last);
            } else {
                timeout = null;
                if (!immediate) {
                    result = func.apply(context, args);
                    if (!timeout)
                        {context = args = null;}
                    }
                }
        };
        return function() {
            context = this;
            args = arguments;
            timestamp = +new Date();
            var callNow = immediate && !timeout;
            if (!timeout)
                {timeout = setTimeout(later, wait);}
            if (callNow) {
                result = func.apply(context, args);
                context = args = null;
            }
            return result;
        };
    }
};
