import getGeo from '../glb/geo';

export default function () {
    return {
        geo: getGeo(),
        pageType: '',
        biggestCities: [],
        biggestCounties: [],
        nearByListings: [],
        allCounties: [],
        cityCountList: {},
        sitemapList: [],
        relatedArticles: [],
        listingCountByLetter: {},
        paragarphics: null,
        bannerUrl: null,
        bannerUrlWebp: null,
        stateInfo: null,
        pageTitle: '',
        zipcodeDisplayName: '',
        cityDisplayName: '',
        listingCount: 0,
        listings: [],
        pages: [],
        zipcodes: [],
        stateDisplayName: '',
        config: { isPR: true, isCountyNew: false },
        allStates: [],
        countyName: '',
        realEstateLinks: [],
        popularCityList: [],
    };
}
