import format from '../lib/format.js';
import { filterKeyWords, pageIndexFilter, locationFilter, isFilter } from './filterhelper.js';
import { getSegments, getPath } from './pathHelper.js';
import states from '../data/states.js';

function _getTempSearchText(searchtext) {
    return searchtext ? searchtext.replace(/\s*,\s*/g, ' ').replace(/,?\s?(united\s+states|usa?)\s*$/i, '') : '';
}

function _getGeoType(data) {
    if (data.address) {
        return 'ADDRESS';
    } else if (data.street) {
        return 'STREET';
    } else if (data.zipcode) {
        return 'ZIPCODE';
    } else if (data.neighborhood) {
        return 'NEIGHBORHOOD';
    } else if (data.city) {
        return 'CITY';
    } else if (data.county) {
        return 'COUNTY';
    } else if (data.state) {
        return 'STATE';
    }
    return 'GPS';
}

function _getGeoId(data, geoType) {
    if (geoType === 'ZIPCODE') {
        return data.zipcodeId;
    } else if (geoType === 'NEIGHBORHOOD') {
        return data.neighborhoodId;
    } else if (geoType === 'CITY') {
        return data.cityId;
    } else if (geoType === 'COUNTY') {
        return data.countyId;
    } else if (geoType === 'STATE') {
        return data.stateId;
    }
    return '';
}

function _getGeoName(data, geoType) {
    if (geoType === 'ZIPCODE') {
        return data.zipcode;
    } else if (geoType === 'NEIGHBORHOOD') {
        return data.neighborhood;
    } else if (geoType === 'CITY') {
        return data.city;
    } else if (geoType === 'COUNTY') {
        return data.county;
    } else if (geoType === 'STATE') {
        return data.state;
    }
    return '';
}

function _getFormatAddress(data) {
    let list = [];
    if (data.address) {
        list.push(data.address);
    } else if (data.zipcode && data.state) {
        return `${data.zipcode}, ${data.state}`;
    }
    if (data.city) {
        list.push(data.city);
    }
    if (data.state) {
        list.push(data.state);
    }
    return list.join(', ') + (data.zipcode ? ' ' + data.zipcode : '');
}
function _getPathByGeo(geo) {
    if (!geo.stateCode) {
        return '';
    }
    if (geo.address) {
        let prefix = geo.searchType === 'COMMUTE' ? 'commute' : 'address';
        let p = `${prefix}-${geo.address.replace(/\s/g, '-')}`.toLowerCase();
        if (geo.city && geo.stateCode) {
            p += `-${geo.cityCode}-${geo.stateCode}`;
        }
        if (geo.zipcode) {
            p += `-${geo.zipcode}`;
        }
        if (geo.lat && geo.lng) {
            p += `/@${geo.lat},${geo.lng}`;
        }
        return p + '/';
    } else if (geo.zipcode) {
        return `${geo.stateCode}/${geo.zipcode}/`;
    } else if (geo.neighborhoodCode) {
        return `${geo.cityCode}-${geo.stateCode}/${geo.neighborhoodCode}/`;
    } else if (geo.cityCode) {
        return `${geo.cityCode}-${geo.stateCode}/`;
    } else if (geo.countyCode) {
        return `${geo.countyCode}-${geo.stateCode}/`;
    } else if (geo.stateCode) {
        return `${geo.stateCode}/`;
    }
}
function _getSeoPath(seoPathes) {
    return seoPathes.zipCodePath || seoPathes.neighborhoodPath || seoPathes.cityPath || seoPathes.countyPath || seoPathes.statePath || seoPathes.gpsPath || '';
}

function _isDallas(stateCode, cityName) {
    return cityName && cityName.toUpperCase() === 'DALLAS' && stateCode && stateCode.toUpperCase() === 'TX';
}

function _isKeywords(segment) {
    return /^market-trends$/i.test(segment);
}

function _convertGeoInfo(data) {
    let geo = {};
    if (data.stateCode) {
        geo.state = data.stateCode;
    }
    if (data.location) {
        geo.lat = data.location.lat;
        geo.lng = data.location.lng;
    }
    if (data.log && data.log === 'use user ip city') {
        geo.geoType = 'GPS';
        if (geo.maxLat && geo.maxLng && geo.minLat && geo.minLng) {
            geo.lat = (geo.maxLat + geo.minLat) / 2;
            geo.lng = (geo.maxLng + geo.minLng) / 2;
        }
    } else if (data.type === 'STATE') {
        geo.state = data.displayName;
        geo.stateId = data.id;
    } else if (data.type === 'CITY') {
        geo.city = data.displayName;
        geo.cityId = data.id;
    } else if (data.type === 'COUNTY') {
        geo.county = data.displayName;
        geo.countyId = data.id;
    } else if (data.type === 'POSTAL_CODE') {
        geo.city = data.cityDisplayName;
        geo.zipcode = data.displayName;
        geo.zipcodeId = data.id;
    } else if (data.type === 'NEIGHBORHOOD') {
        geo.city = data.cityDisplayName;
        geo.neighborhood = data.displayName;
        geo.neighborhoodId = data.id;
    } else {
        return data;
    }
    if (data.searchType) {
        geo.searchType = data.searchType;
    }
    return geo;
}

function getNeighbourhoodName(data) {
    let city = data.city && data.city.toLowerCase();
    let neighborhood = data.neighborhood || data.neighborhoodName || data.neighborhoodN;
    if (neighborhood && neighborhood.toLowerCase() !== city) {
        return neighborhood;
    }
    return '';
}

export function hotfixCountyCity(url, state) {
    //if url end with -county/
    if (url && url.endsWith('-county/')) {
        let stateCode;
        if (!state) {
            //get first two letters state code from url
            let matched = url.match(/-([a-zA-Z]{2})-/);
            if (matched && matched.length > 1) {
                stateCode = matched[1];
            }
        } else {
            stateCode = state.toLowerCase();
        }
        //return substring before -statecode
        url = url.split(`-${stateCode}-`)[0] + `-${stateCode}/`;
    }
    return url;
}

export function getGeoByGoogleAddress(address) {
    const data = {
        address: '',
        city: '',
        state: '',
        zipcode: '',
        neighborhood: '',
        county: '',
        lat: '',
        lng: '',
    };

    if (address && address.address_components) {
        const componentTypes = new Set(address.address_components.map((comp) => comp.types[0]));

        const extractComponent = (type, key = 'long_name') => {
            const comp = address.address_components.find((comp) => comp.types.includes(type));
            return comp ? comp[key] : '';
        };

        if (componentTypes.has('street_number')) {
            data.address = `${extractComponent('street_number')} ${extractComponent('route', 'short_name')}`;
        } else if (extractComponent('route', 'short_name')) {
            data.address = extractComponent('route', 'short_name');
        }

        if (componentTypes.has('subpremise')) {
            data.address += ` #${extractComponent('subpremise')}`;
        }

        if (componentTypes.has('locality')) {
            data.city = extractComponent('locality');
        } else if (componentTypes.has('sublocality')) {
            data.city = extractComponent('sublocality');
        }

        data.state = extractComponent('administrative_area_level_1', 'short_name');
        data.county = extractComponent('administrative_area_level_2');
        data.neighborhood = extractComponent('neighborhood');
        data.zipcode = extractComponent('postal_code');

        if (address.lat && address.lng) {
            data.lat = address.lat;
            data.lng = address.lng;
        } else if (address.geometry && address.geometry.location) {
            data.lat = address.geometry.location.lat();
            data.lng = address.geometry.location.lng();
        }
    }
    if (address.searchType) {
        data.searchType = address.searchType;
    }
    return getGeoByData(data);
}
export function getSearchInputByGeo(geo) {
    let input = '';
    if (geo.input) {
        return geo.input;
    }
    if (!geo.hasGeoInfo) {
        input = geo.address || geo.street || '';
    } else if (geo.geoType === 'ZIPCODE') {
        input = `${geo.zipcode}, ${geo.state}`;
    } else if (geo.geoType === 'NEIGHBORHOOD') {
        input = `${geo.neighborhood}, ${geo.city}, ${geo.state}`;
    } else if (geo.geoType === 'CITY') {
        input = `${geo.city}, ${geo.state}`;
    } else if (geo.geoType === 'COUNTY') {
        input = `${geo.county}, ${geo.state}`;
    } else if (geo.geoType === 'STATE') {
        input = `${geo.state}`;
    } else {
        input = geo.formatAddress;
    }
    return input;
}
export function getGeoByData(data) {
    if (!data) {
        return {
            hasGeoInfo: false,
        };
    } else if (data.type) {
        //if it soa geo data do mapping
        data = _convertGeoInfo(data);
    }

    let city = data.city || data.cityName;
    let county = data.county || data.countyName;
    let state = data.state || data.stateCode;
    let zipCode = data.zipcode || data.zipCode;
    let neighborhood = getNeighbourhoodName(data);
    let display = data.inputDisplay || data.input;
    let input = _getTempSearchText(display);
    let address = data.addressInfo || data.address;
    let lat = data.lat || data.latitude;
    let lng = data.lng || data.longitude;
    let subPremise = data.subPremise || (address && address.includes('#') ? address.split('#')[1] : '');

    let geo = {
        geoId: '',
        input: input || '',
        display: display || '',
        hasGeoInfo: !!state,
        state: state ? state.toUpperCase() : '',
        stateCode: state ? state.toLowerCase() : '',
        stateId: data.stateId || '',
        city: city ? format.capAll(city) : '',
        cityCode: city ? getPath(city.toLowerCase()) : '',
        cityId: data.cityId || '',
        county: county ? format.capAll(county) : '',
        countyCode: county ? getPath(county.toLowerCase()) : '',
        countyId: data.countyId || '',
        neighborhood: neighborhood || '',
        neighborhoodCode: neighborhood ? getPath(neighborhood.toLowerCase()) : '',
        neighborhoodId: data.neighborhoodId || data.neighborhoodNGeoId || '',
        zipCode: zipCode || '',
        zipcode: zipCode || '',
        zipcodeId: data.zipcodeId || '',
        address: address,
        subPremise: subPremise || '',
        lat: lat || 0,
        lng: lng || 0,
        stateFullName: states[state] || '',
    };
    if (data.searchType) {
        geo.searchType = data.searchType;
    }
    geo.formatAddress = _getFormatAddress(geo);
    geo.geoType = _getGeoType(geo);
    geo.geoId = _getGeoId(geo, geo.geoType);
    geo.geoName = _getGeoName(geo, geo.geoType);
    geo.path = _getPathByGeo(geo);

    return geo;
}
export function isStandardGeoType(geoType) {
    return ['ZIPCODE', 'NEIGHBORHOOD', 'COUNTY', 'CITY', 'STATE'].indexOf(geoType) >= 0;
}
export function getSeoPathesByGeo(geo, rentals = false, sold = false) {
    let cityPath = '';
    if (geo.cityCode && geo.stateCode) {
        cityPath = `${geo.cityCode}-${geo.stateCode}`;
    }
    let glbFilter = rentals ? filterKeyWords.rentals : sold ? filterKeyWords.sold : '';
    let seoPathes = {
        neighborhoodPath: geo.neighborhoodCode && cityPath ? `${cityPath}/${geo.neighborhoodCode}/` : '',
        zipCodePath: geo.zipCode && geo.stateCode ? `${geo.stateCode}/${geo.zipCode}/` : '',
        cityPath: cityPath ? `${cityPath}/` : '',
        countyPath: geo.countyCode ? `${geo.countyCode}-${geo.stateCode}/` : '',
        statePath: geo.stateCode ? `${geo.stateCode}/` : '',
        schoolPath: cityPath ? `schools/${cityPath}/` : '',
        gpsPath: geo.lat && geo.lng ? `for-sale/@${geo.lat},${geo.lng}/` : '',
    };
    seoPathes.path = _getSeoPath(seoPathes);
    // seoPathes.marketTrendsPath =_getMarketTrendsPath(seoPathes);
    if (isStandardGeoType(geo.geoType)) {
        seoPathes.marketTrendsPath = `${seoPathes.path}market-trends/`;
    }

    if (glbFilter) {
        seoPathes.geoPath = `${seoPathes.path}${glbFilter}/`;
        seoPathes.gpsPath = geo.lat && geo.lng ? `${glbFilter}/@${geo.lat},${geo.lng}/` : '';
        seoPathes.neighborhoodPath && (seoPathes.neighborhoodPath += `${glbFilter}/`);
        seoPathes.zipCodePath && (seoPathes.zipCodePath += `${glbFilter}/`);
        seoPathes.cityPath && (seoPathes.cityPath += `${glbFilter}/`);
        seoPathes.countyPath && (seoPathes.countyPath += `${glbFilter}/`);
        seoPathes.newPath = cityPath ? `${cityPath}/${glbFilter}/${filterKeyWords.new}/` : '';
        seoPathes.openHousePath = cityPath ? `${cityPath}/${glbFilter}/${filterKeyWords.openHouse}/` : '';
        seoPathes.priceReducePath = cityPath ? `${cityPath}/${glbFilter}/${filterKeyWords.priceReduce}/` : '';
    } else {
        seoPathes.newPath = cityPath ? `${cityPath}/${filterKeyWords.new}/` : '';
        seoPathes.openHousePath = cityPath ? `${cityPath}/${filterKeyWords.openHouse}/` : '';
        seoPathes.priceReducePath = cityPath ? `${cityPath}/${filterKeyWords.priceReduce}/` : '';
        if (_isDallas(geo.stateCode, geo.city) && !geo.extraPath) {
            seoPathes.cityPath = 'homes-for-sale/' + seoPathes.cityPath;
        }
        seoPathes.geoPath = seoPathes.path || 'for-sale/';
    }
    return seoPathes;
}
export function getGeoByPath(path) {
    if (!path) {
        return;
    }
    let geo = { hasGeoInfo: false };
    let segments = getSegments(path);
    if (segments && segments.length) {
        let lastKey = segments[segments.length - 1];
        if (pageIndexFilter.exec(lastKey)) {
            lastKey = segments[segments.length - 2];
        }
        let location = locationFilter.exec(lastKey);
        if (location) {
            geo.lat = parseFloat(location[1]);
            geo.lng = parseFloat(location[2]);
        }
        if (/^(for-sale|rentals)/.test(path)) {
            geo.geoType = 'GPS';
            return geo;
        }

        const primaryKey = segments[0];
        const minorKey = segments[1];

        if (!geo.hasGeoInfo) {
            //to do check if we could merge the logic address search with comute search
            let address = /^(address|commute)-([\w-]+)?$/i.exec(primaryKey);
            if (address) {
                geo.hasGeoInfo = false;
                address = address[2];
                if (address) {
                    geo.address = format.capAddress(address.replace(/-/g, ' '));
                    geo.geoType = _getGeoType(geo);
                    if (primaryKey.indexOf('commute-') === 0) {
                        geo.searchType = 'COMMUTE';
                    }
                    geo.path = primaryKey;
                    return geo;
                }
            }
        }

        if (!geo.hasGeoInfo) {
            let street = /^(street)-([\w-]+)?$/i.exec(primaryKey);
            if (street) {
                geo.hasGeoInfo = false;
                street = street[2];
                if (street) {
                    geo.street = format.capAddress(street.replace(/-/g, ' '));
                    geo.geoType = _getGeoType(geo);
                    geo.path = primaryKey;
                    return geo;
                }
            }
        }

        if (!geo.hasGeoInfo) {
            let city = /^([a-z-]+)-(\w{2}$)/i.exec(primaryKey);
            if (city) {
                geo.hasGeoInfo = true;
                let code = city[1];
                if (/-county-(\w{2})$/.test(primaryKey)) {
                    geo.countyCode = code;
                } else {
                    geo.cityCode = code;
                }
                if (minorKey && !isFilter(minorKey) && !_isKeywords(minorKey)) {
                    geo.neighborhoodCode = minorKey;
                }
                geo.stateCode = city[2];
            }
        }

        if (!geo.hasGeoInfo) {
            if (/^[a-z]{2}$/i.test(primaryKey)) {
                geo.hasGeoInfo = true;
                geo.stateCode = primaryKey;
                if (/^\d{5}$/.test(minorKey)) {
                    geo.zipcode = minorKey;
                }
            }
        }

        if (geo.neighborhoodCode) {
            geo.neighborhood = format.capAll(geo.neighborhoodCode.replace(/-/g, ' '));
        }

        if (geo.cityCode) {
            geo.city = format.capAll(geo.cityCode.replace(/-/g, ' '));
        }

        if (geo.countyCode) {
            geo.county = format.capAll(geo.countyCode.replace(/-/g, ' '));
        }

        if (geo.stateCode) {
            geo.state = geo.stateCode.toUpperCase();
        }

        geo.formatAddress = _getFormatAddress(geo);
        geo.geoType = _getGeoType(geo);
        geo.path = _getPathByGeo(geo);
    }
    return geo;
}
