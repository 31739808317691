
function initialParse(context){
    if (context && typeof context.pageData === 'object') {
       context.pageData = unescapePageData(context.pageData)
    }
    return context;
}
export default initialParse;


export const escapePageData = (pageData) => {
    try {
        // let data = JSON.stringify(pageData);
        // if (data) {
            // pageData = JSON.parse(data.replace(/<\/script>/g,'&lt;/script&gt;'));
        // }
        if (pageData) {
            HTML_ESCAPED_FIELDS.forEach(field => {
                const rawData = getObjValueByKey(pageData, field);
                if (rawData) {
                    const transformed = JSON.stringify(rawData).replace(/<\/script>/g,'&lt;/script&gt;');
                    setObjValueByKey(pageData, field, JSON.parse(transformed));
                }
            });
            HTML_ESCAPED_COLLECTIONS.forEach(field => {
                let rawCollection = getObjValueByKey(pageData, field);
                if (rawCollection) {
                    if(rawCollection.length > 0) {
                        for (let i = 0; i < rawCollection.length; i++) {
                            rawCollection[i] = escapePageData(rawCollection[i])
                        }
                    }else if(typeof rawCollection === 'object') {
                        rawCollection = escapePageData(rawCollection);
                    }
                    setObjValueByKey(pageData, field, rawCollection);
                }
            });
        }
    } catch(e) {}
    return pageData;
};

const unescapePageData = (pageData) => {
    try {
        // let data = JSON.stringify(pageData);
        // data = data.replace(/&lt;\/script&gt;/g, '</script>');
        // pageData = JSON.parse(data);
        if(pageData) {
            HTML_ESCAPED_FIELDS.forEach(field => {
                const rawData = getObjValueByKey(pageData, field);
                if (rawData) {
                    const transformed = JSON.stringify(rawData).replace(/&lt;\/script&gt;/g, '</script>');
                    setObjValueByKey(pageData, field, JSON.parse(transformed));
                }
            })
            HTML_ESCAPED_COLLECTIONS.forEach(field => {
                let rawCollection = getObjValueByKey(pageData, field);
                if (rawCollection) {
                    if(rawCollection.length > 0) {
                        for (let i = 0; i < rawCollection.length; i++) {
                            rawCollection[i] = unescapePageData(rawCollection[i])
                        }
                    }else if(typeof rawCollection === 'object') {
                        rawCollection = unescapePageData(rawCollection);
                    }
                    setObjValueByKey(pageData, field, rawCollection);
                }
            });
        }
    } catch(e) {}

    return pageData;
};

const getObjValueByKey = (obj, field) => {
    let result = obj == null ? undefined : obj[field];
    return result === undefined ? undefined : result;
};

const setObjValueByKey = (obj, field, value) => {
    if(obj && field && value){
        obj[field] = value;
    }
};

const HTML_ESCAPED_FIELDS = [
    'features',
    'virtualTourLink',
    'virtualLink',
    'threeDTourLink'
];

const HTML_ESCAPED_COLLECTIONS = [
    'listings',
    'nearbyHomes',
    'lightDpp'
]