import registerRestrict from './_restrict.directive';
import registerInitial from './_inital.directive';
import registerFullscreen from './_fullscreen.directive';
import registerGtm from './_gtm.directive';
import registerThirdpartload from './_thirdpartload.directive';
import registerValidate from './_validate.directive';
import autoselect from './_autoselect.directive';
import inputformat from './_inputformat.directive';
import lazyload from './_lazyload.directive';
import scroll from './_scroll.directive';
import scrollspy from './_scrollspy.directive';
import tip from './_tip.directive';
import inputtrim from './_inputtrim.directive';
import registerNHS from './_nhs.directive';
import registerXlead from './_xlead.directive';
import registerSignal from './_signal.directive';

export default (app) => {
    registerRestrict(app);
    registerInitial(app);
    registerFullscreen(app);
    registerGtm(app);
    registerThirdpartload(app);
    registerValidate(app);
    registerNHS(app);
    registerXlead(app);
    registerSignal(app);
    autoselect(app);
    inputformat(app);
    inputtrim(app);
    lazyload(app);
    scroll(app);
    scrollspy(app);
    tip(app);
};
