import molang from '@/common/utilities/lang';

molang.install = function (app, store) {
    let molangDataStore = null;
    app.config.globalProperties.molang = function (key, opts) {
        const curmoLang = store.state.glb.language || 'en';
        if (!molangDataStore) {
            molangDataStore = molang.getDataStore(curmoLang);
        }
        return molang.getString(molangDataStore, key, opts);
    };
    app.config.globalProperties.injectMolangDataStore = function (options = {}) {
        const curmoLang = store.state.glb.language || 'en';
        !molangDataStore && (molangDataStore = molang.getDataStore(curmoLang));
        if (options.type) {
            molangDataStore[options.type] = options[curmoLang] || options.en;
        }
    };
};

export default molang;
