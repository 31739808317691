import format from '@/common/utilities/format';

const isSSR = typeof window === 'undefined';

const formatter = (value, type) => {
    let formatString = '';
    switch (type) {
        case 'phone':
            value = value.replace(/\D/g, '');
            if (value.length >= 4) {
                formatString += value.slice(0, 3) + '-';
                if (value.length >= 7) {
                    formatString += value.slice(3, 6) + '-';
                    if (value.length >= 11) {
                        formatString += value.slice(6, 10);
                    }
                    else {
                        formatString += value.slice(6, value.length);
                    }
                } else {
                    formatString += value.slice(3, value.length);
                }
            } else {
                formatString += value;
            }
            return {value: formatString, dispatchEvent: true};
        case 'price':
            let val = value.replace(/[$,]/g, '');
            val = format.formatNumberByComma(val);
            return {value: '$'+ val, dispatchEvent: true};
        default:
            return {value: value};
    }
};

const preserveCusorPosition = (cursorPosition, type, el, keyEvent) => {
    if (keyEvent.keyCode > 36 && keyEvent.keyCode < 41) {
        return;
    }
    switch (type) {
        case 'phone':
            let isNumber = /\d/.test(keyEvent.key);
            if (el.value.slice(cursorPosition-1, cursorPosition) === '-' || (!isNumber && keyEvent.keyCode !== 8)) {
                if (keyEvent.keyCode === 8 || !isNumber) {
                    cursorPosition--;
                } else {
                    cursorPosition++;
                }
            }
            el.selectionStart = cursorPosition;
            el.selectionEnd = cursorPosition;
            break;
        default:
            return;
    }
}

const inputDispatchEvent = el => {
    const event = new Event('input', {bubbles: true});
    el.dispatchEvent(event);
};

export default (app) => {
    app.directive('inputformat', isSSR ? {} : {
        beforeMount(el, binding) {
            const type = binding.arg;
            if (el.value) {
                const formatted = formatter(el.value, type);
                el.value = formatted.value;
                formatted.dispatchEvent && inputDispatchEvent(el);
            }
            el.addEventListener('keyup', (e) => {
                let cursorPosition = el.selectionStart;
                const formatted = formatter(el.value, type);
                el.value = formatted.value;
                preserveCusorPosition(cursorPosition, type, el, e);
                formatted.dispatchEvent && inputDispatchEvent(el);
            });
        }
    });
}