const poiType = ['shopping', 'restaurants', 'grocery', 'transits', 'parks', 'cafes'];
const poiTitle = ['Shopping', 'Restaurants', 'Groceries', 'Transits', 'Parks', 'Cafes'];
const poiIcon = {
    shopping: 'icon-poi-shopping',
    restaurants: 'icon-poi-eat',
    grocery: 'icon-poi-grocery',
    transits: 'icon-transit',
    parks: 'icon-park',
    cafes: 'icon-poi-coffee',
    gym: 'icon-basketball',
};
function getPoiIcon(poiType) {
    return poiIcon[poiType || ''] || '';
}

export default function (data) {
    if (data.availableCategories && data.availableCategories.length) {
        return data.availableCategories.map((item) => {
            let id = poiType.indexOf(item.type) + 1;
            let title = poiTitle[id - 1];
            return {
                list: data.list.filter((item) => item.poiTypeId === id),
                type: item.type,
                poiTypeId: id,
                icon: getPoiIcon(item.type),
                poiTypeName: title,
            };
        });
    }
    return [];
}
