import pako from 'pako';

const geo = function (state) {
    return state.geo;
};
const surveyMortgage = function (state) {
    return state;
};

const ctxString = function (state) {
    return state.ctxString;
}

const formData = function (state) {
    return state.formData;
    // if (state.formData) {
    //     return JSON.parse(pako.inflate(state.formData, { to: 'string' }));
    // }
    // return null;
};

export default {
    geo,
    surveyMortgage,
    formData,
    ctxString,
};
