var geocoder;
var geolocation;
var permissionState;
export default {
    getAutoComplete: (text) => {
        return new Promise((resolve) => {
            $.loadGMap().then(function () {
                const service = new window.google.maps.places.AutocompleteService();
                service.getPlacePredictions(
                    {
                        input: text,
                        componentRestrictions: {
                            country: 'US',
                        },
                    },
                    function (predictions, status) {
                        if (status !== window.google.maps.places.PlacesServiceStatus.OK) {
                            resolve(null);
                        } else {
                            let result = predictions.reduce((prev, item) => {
                                if (item && item.terms && item.terms.length > 4) {
                                    prev.push({
                                        text: item.description,
                                    });
                                }
                                return prev;
                            }, []);
                            resolve(result);
                        }
                    }
                );
            });
        });
    },
    geoServiceSearch: function (text) {
        return new Promise((resolve) => {
            $.loadGMap().then(function () {
                geocoder = geocoder || new window.google.maps.Geocoder();
                geocoder.geocode(
                    {
                        address: text,
                        componentRestrictions: {
                            country: 'US',
                        },
                    },
                    function (results, geostatus) {
                        if (geostatus !== window.google.maps.GeocoderStatus.OK) {
                            resolve(null);
                        } else {
                            resolve(results);
                        }
                    }
                );
            });
        });
    },
    geoServiceLocation: function () {
        return new Promise((resolve) => {
            if (geolocation) {
                resolve(geolocation);
            } else {
                window.navigator.geolocation.getCurrentPosition(
                    (position) => {
                        if (position && position.coords) {
                            geolocation = {
                                lat: position.coords.latitude,
                                lng: position.coords.longitude,
                            };
                            resolve(geolocation, position);
                        } else {
                            resolve(null);
                        }
                    },
                    (err) => {
                        resolve(null);
                    }
                );
            }
        });
    },
    getSharedGeoLocation: function () {
        var that = this;
        return new Promise((resolve) => {
            if (geolocation) {
                resolve(geolocation);
            } else if ((!permissionState || permissionState === 'prompt') && window.navigator && navigator.permissions && navigator.permissions.query) {
                navigator.permissions.query({ name: 'geolocation' }).then(
                    (res) => {
                        permissionState = res && res.state;
                        if (permissionState == 'granted') {
                            that.geoServiceLocation().then((geoLocation) => {
                                resolve(geoLocation);
                            });
                        } else {
                            resolve(null);
                        }
                    },
                    (err) => {
                        resolve(null);
                    }
                );
            } else {
                resolve(null);
            }
        });
    },
};
