import mapper from '../mapper/index.js';

function capitalize(word) {
    if(!word){
        return word;
    }
    var words = word.split(' ');
    return words.map(function(str){
        const lower = str.toLowerCase();
        return str.charAt(0).toUpperCase() + lower.slice(1);
    }).join(' ');
}

function locationToGeo(location, rentals){
    return mapper.geo({
        cityId: location.city.cityId,
        city: location.city.cityName,
        county: location.county.countyName,
        countyId: location.county.countyId,
        state: location.state.stateCode,
        zipcode: location.zipcode
    }, rentals);
}

function updateAgent(state, {data, glb}) {
    var agentInfo = {
        agentTeam: data.agentTeam || null,
        transactions: data.transactions || [],
        transactionSummary: data.transactionSummary || [],
        transactionCount: data.transactionCount || 0,
        agentAvatar: data.agentAvatar,
        workSince: data.agentSince,
        workYears: data.totalExperience,
        agentStatus: data.agentStatus,
        agentTitle: data.agentTitle,
        agentType: data.agentType,
        averageRating: Number.parseFloat(data.averageRating) || 0,
        brokerage: data.brokerage,
        claimInfo: data.claimInfo,
        agentImage: data.agentPhoto,
        fullName: data.fullName,
        inactiveUrls: data.inactiveUrls,
        updateDate: data.lastModifiedDateTime,
        licenses: data.licenses,
        license: data.licenseText,
        locations: data.locations,
        migrationSource: data.migrationSource,
        officeInfo: data.officeInfo,
        partnerInfo: data.partnerInfo,
        pastTransactionProcessed: data.pastTransactionProcessed,
        profileDisplayPermissions: data.profileDisplayPermissions,
        reviewCount: data.reviewCount,
        reviews: data.reviews,
        specialities: data.specialitiesArr,
        showDREName: data.showDREName,
        showMailingAddress: data.showMailingAddress,
        showProfilePage: data.showProfilePage,
        profileUrl: data.url ? `agents/${data.url}` : '',
        agentId: data.userId ? data.userId.id : '',
        _id: data._id
    };
    if(data.primaryLocation){
        state.primaryGeo = locationToGeo(data.primaryLocation, glb.rentals);
    }
    if(data.agentDetails){
        Object.assign(agentInfo, {
            description: data.agentDetails.agentBio,
            certifications: data.agentDetails.certifications,
            isFemale: data.agentDetails.gender,
            interests: data.agentDetails.interests,
            languages: data.agentDetails.languages,
            questionnaire: data.agentDetails.questionnaire,
            specialities: data.agentDetails.specialities,
            neighborhoodInfo: data.agentDetails.neighborhoodInfo
        });
    }
    if(data.contact){
        Object.assign(agentInfo, {
            firstName: capitalize(data.contact.firstName),
            lastName: capitalize(data.contact.lastName),
            phone: data.contact.phones ? data.contact.phones.displayPhone : '',
            cellPhone: data.contact.phones ? data.contact.phones.cellPhone : '',
            officePhone: data.contact.phones ? data.contact.phones.officePhone : '',
            email: data.contact.email,
            facebookProfile: data.contact.facebookProfile,
            linkedInProfile: data.contact.linkedInProfile,
            website: data.contact.website
        });
        if(data.contact.homeAddress){
            state.agentGeo = locationToGeo(data.contact.homeAddress, glb.rentals);
        }
    }
    if(data.neighborhoods && data.neighborhoods.length > 0) {
        agentInfo.neighborhoods = data.neighborhoods;
    }
    if(data.zipcodes && data.zipcodes.length > 0) {
        agentInfo.zipcodes = data.zipcodes;
    }
    if(data.cities && data.cities.length > 0) {
        agentInfo.cities = data.cities;
    }
    if(data.counties && data.counties.length > 0) {
        agentInfo.counties = data.counties;
    }
    Object.assign(state, agentInfo);
    state.geo = mapper.geo(data.geo, glb.rentals);
}
export default {
    updateAgent
};
