import getGeo from '../glb/geo';

export default function () {
    return {
        geo: getGeo(),
        reviews: [],
        agentTeam: [],
        transactionCount: 0,
    };
}
