const context = window.__INITIAL_STATE__;

export default {
    loadPerimeterX() {
        if (!context.enableThirdPart) {
            return false;
        }
        // Custom parameters
		// window._pxParam1 = "<param1>";
        const appId = context.perimeterxAppId;
        const scriptUrl = context.isDevelopment ? `//client.px-cloud.net/${appId}/main.min.js` : '/Pb7SA58F/init.js';
        try {
            var p = document.getElementsByTagName('script')[0],
                s = document.createElement('script');
                s.async = 1;
                s.src = scriptUrl;
                p.parentNode.insertBefore(s,p);
        } catch(e) {
            console.log('pX not loaded', e);
        }
        return true;
    }
}