var MovotoValidator = function(){
	var namespace = {
		isEmptyString:function(input){
			if(input && input.length > 0)
				return false;
			return true;
		},
		isValidString: function(text) {
	        if (text) {
	            text = text.replace(/[^a-zA-Z0-9]+/gi, '-').replace(/-*\/$/, '\/').replace(/-$/, '');
	        }
	        text = text ? encodeURIComponent(text).toLowerCase() : '';
	        return text !== '';
		},
		isValidEmail: function(input){
			if(namespace.isEmptyString(input))
				return false;
			var pattern = new RegExp(/^([\w-\.]+@([\w-]+\.)+[\w-]{2,4})?$/);
	        return pattern.test(input);
		},
		isValidatePhoneNO: function(phoneNO){
			var regu = /^[\+]?[(]?\d{3}[)]?[-\s\.]?\d{3}[-\s\.]?\d{4}$/im;
		    var re = new RegExp(regu);
		    var wholeNum = "0000000000,1111111111,2222222222,3333333333,4444444444,5555555555,6666666666,7777777777,8888888888,9999999999,1231231234,6503211133,8887668686";
		    if (phoneNO.search(re) != -1) {
		        if (wholeNum.indexOf(phoneNO) != -1 || phoneNO.substring(0, 3) == "911" || phoneNO.substring(0, 1) == "0" || phoneNO.indexOf("1111111", 3, 7) != -1) {
		            return false;
		        }
		        else
		            return true;
		    }
		    else
		        return false;
		},
        getCanadaAreaCodes() {
           return [204, 226, 236, 249, 250, 289, 306, 343, 365, 367, 403, 416, 418, 431, 437, 438, 450, 506, 514, 519, 548, 579, 581, 587, 604, 613, 639, 647, 705, 709, 778, 780, 782, 807, 819, 825, 867, 873, 902, 905];
        }
	};
	return namespace;
}();

export default MovotoValidator;