export default {
    cul_de_sac: 'Cul-de-sac',
    mountain_views: 'Mountain view',
    panoramic_views: 'Panoramic view',
    single_level: 'Single-level home',
    skyline_view: 'City view',
    stone_countertop: 'Stone countertops',
    tall_ceilings: 'High ceilings',
    tennis_courts: 'Tennis court',
    walk_in_closet: 'Walk-in closet',
    fireplace: 'Fireplace ',
    backyard: 'Backyard ',
    view: 'View ',
    corner_lot: 'Corner Lot ',
    natural_light: 'Natural Light ',
    porch: 'Porch ',
    rv_parking: 'RV Parking ',
    office: 'Office ',
    hardwood_floors: 'Hardwood Floors '
 };
