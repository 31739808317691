const isSSR = typeof window === 'undefined';

export default (app) => {
    app.directive('thirdpartload', isSSR ? {} : {
        beforeMount(el, binding) {
            if(binding.value){
                var hasTouch = 'ontouchstart' in window;
                var clickEvent = hasTouch ? 'touchstart.thirdparty' : 'mouseenter.thirdparty';
                $(document).ready(() => {
                    $(document).on(clickEvent, function (e) {
                        if ($(e.target).is('input') || $(e.target).closest('a').length > 0) {
                            return;
                        }
                        $(document).off(clickEvent);
                    });
                });
            }
        }
    });
};

