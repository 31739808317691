export const signup_trigger = {
    savedsearch: 'save_search',
    savedsearch_searchFilter: 'save_search_filter',
    savedsearch_mvtSearchFilter: 'save_search_filter',
    savedsearch_mvtAutocomplete: 'save_search_autocomplete',
    savedsearch_mvtWidgetAutocomplete: 'save_search_homeowner_search',
    savedsearch_mvtHeader: 'save_search_header',
    savedsearch_mvtFooter: 'save_search_footer',
    savedSearch_searchAds: 'save_search_ads',
    addfavorite: 'save_home',
    addclaimhome: 'claim_home',
    pageSurveyMortgage: 'lead_mortgage',
    mvtHotleadForm: 'lead',
    mainForce: 'main_force',
    mvtHeader: 'main_header',
    mvtFooter: 'main_footer',
    mvtPopSignin: 'main_pop_banner',
};
export function getGa4Trigger(trigger, requestName = '', defaultName = '') {
    let _trigger;
    if (trigger && requestName && signup_trigger[`${requestName}_${trigger}`]) {
        _trigger = signup_trigger[`${requestName}_${trigger}`];
    } else {
        _trigger = signup_trigger[requestName || trigger];
    }
    if (!_trigger) {
        if (defaultName) {
            _trigger = defaultName;
        } else if (trigger) {
            _trigger = trigger;
        } else {
            _trigger = 'unknown';
        }
    }
    return _trigger;
}

export function getItemByDpp(dpp, itemListId, itemListName, index) {
    let item = {
        item_id: dpp.propertyId,
        item_name: dpp.geo ? dpp.geo.formatAddress : '',
        price: dpp.price > 0 ? dpp.price : '',
        discount: dpp.priceChange < 0 || dpp.priceChange > 0 ? dpp.priceChange : '',
        location_id: dpp.path,
        dpp_thumbnail: dpp.tnImgPath || '',
        dpp_listingid: dpp.listingId || '',
        dpp_baths: dpp.bath >= 0 ? dpp.bath : '',
        dpp_beds: dpp.bed >= 0 ? dpp.bed : '',
        dpp_type: dpp.propertyType,
        dpp_listingstatus: dpp.houseRealStatus || '',
        dpp_sqft: dpp.sqftTotal >= 0 ? dpp.sqftTotal : '',
        dpp_lotsize: dpp.lotSize >= 0 ? dpp.lotSize : '',
        dpp_dom: dpp.daysOnMovoto >= 0 ? dpp.daysOnMovoto : '',
        dpp_mlsid: dpp.mlsId,
        dpp_photocount: dpp.photoCount >= 0 ? dpp.photoCount : '',
        dpp_state: dpp.geo ? dpp.geo.stateCode : '',
        dpp_city: dpp.geo ? dpp.geo.city : '',
        dpp_neighborhood: dpp.geo ? dpp.geo.neighborhood : '',
        dpp_zipcode: dpp.geo ? dpp.geo.zipcode : '',
    };
    if (itemListId) {
        item.item_list_id = itemListId;
    }
    if (itemListName) {
        item.item_list_name = itemListName;
    }
    if (index >= 0) {
        item.index = index;
    }
    return item;
}
export function getViewListItems(listId, listName, viewedItems, originalListings) {
    let viewListItems = {
        item_list_id: listId,
        item_list_name: listName,
    };
    if (viewedItems && viewedItems.length) {
        viewListItems.items = viewedItems.map((dpp) => {
            let index;
            if (originalListings && originalListings.indexOf(dpp.propertyId) > -1) {
                index = originalListings.indexOf('dpp');
            }
            return getItemByDpp(dpp, listId, listName, index);
        });
    } else {
        viewListItems.items = [];
    }
    return viewListItems;
}

export function getSelectItem(listId, listName, property, index) {
    return {
        item_list_id: listId,
        item_list_name: listName,
        items: [getItemByDpp(property, listId, listName, index)],
    };
}

export function getCustomDimensions(glb) {
    return {};
}

export function getPageDeimensions(glb) {
    return {};
}

export function eventViewItem(glb, dpp) {
    return {
        currency: 'USD',
        value: dpp.price,
        items: [getItemByDpp(dpp)],
    };
}

export function eventPurchase(glb, dpp, hotleadId) {
    return {
        currency: 'USD',
        value: dpp.price,
        transaction_id: hotleadId,
        items: [getItemByDpp(dpp)],
    };
}

export function eventGenerateLead(glb, value) {
    return {
        currency: 'USD',
        value: value,
    };
}

export function getItemMspPageData(data) {
    let id,
        name,
        pageData = {};
    const views = {
        'map-view': 'mapView',
        'grid-view': 'gridView',
        'slim-view': 'slimView',
    };
    const sortings = {
        'sortby-dom-asc': 'List Date',
        'sortby-price-desc': 'Price High',
        'sortby-price-asc': 'Price Low',
        'sortby-sqft-desc': 'Sqft Big',
        'sortby-sqft-asc': 'Sqft Small',
        'sortby-pricepersqft-asc': '$/Sqft',
        'sortby-closest-asc': 'Relevant',
        'sortby-updated-desc': 'Updated',
    };
    id = `msp_${data.searchType.toLowerCase()}`;
    name = `MSP ${data.searchType.toUpperCase()}`;
    pageData.msp_pagenumber = data.displayPageIndex;
    pageData.msp_type = data.searchType;
    pageData.msp_view = views[data.currentViewStatus || 'grid-view'];
    pageData.msp_state = data.geo ? data.geo.state : '';
    pageData.msp_term = data.searchInput;
    pageData.msp_sorttype = sortings[data.filter.sort];
    if (data.filter.rentals) {
        id += '_rental';
        name += ' RENTAL';
    } else if (data.filter.soldDateRange) {
        id += '_sold';
        name += ' SOLD';
    } else {
        id += '_for_sale';
        name += ' FOR SALE';
    }
    return { pageData, id, name };
};