import indexOf from 'lodash/indexOf';

export default {
    getTimezone: function () {
        return (0 - new Date().getTimezoneOffset() / 60).toFixed(1);
    },
    updateContext: function (flushList) {
        if (window.caches) {
            var cacheList = flushList || ['page'];
            for (var i = 0; i < cacheList.length; i++) {
                window.caches.delete(cacheList[i]).then(function (removed) {
                    return removed;
                });
            }
        }
    },
    deleteSpecificCacheEntry: function (cacheType, resourcePath) {
        window.caches &&
            window.caches.open(cacheType).then(function (cache) {
                return cache.delete(resourcePath).then((removedStatus) => removedStatus);
            });
    },
    parseQueryString: function () {
        const locationSearch = window.location.search && window.location.search.substring(1);
        let retObj = null;
        if (locationSearch && locationSearch.length) {
            retObj = {};
            locationSearch.split('&').forEach((k) => {
                if (k) {
                    const ev = k.split('=');
                    retObj[ev[0]] = decodeURIComponent(ev[1]);
                }
            });
        }
        return retObj;
    },
    shuffle: function (array) {
        let counter = array.length;
        // While there are elements in the array
        while (counter--) {
            // Pick a random index
            let index = (Math.random() * (counter + 1)) | 0;
            // And swap the last element with it
            let temp = array[counter];
            array[counter] = array[index];
            array[index] = temp;
        }
        return array;
    },
    isElementInViewport(element) {
        const rect = element.getBoundingClientRect();
        return (
            rect.top >= 0 && rect.left >= 0 && rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) && rect.right <= (window.innerWidth || document.documentElement.clientWidth)
        );
    },
    getIANAtimeZone: function () {
        let tz = '';
        try {
            tz = Intl.DateTimeFormat().resolvedOptions().timeZone;
        } catch (e) {}
        return tz;
    },
    getHashValue: function (key) {
        return window.hashTable[key] && window.hashTable[key].value;
    },
    // We need to reimplement this because the logic is depended on by vweb/common/components/mvtinputdate/datepick.js
    // https://api.jquery.com/jquery.inarray/
    inArray: function (val, arr) {
        return indexOf(arr, val);
    },
    trimEmojis: function (value) {
        if (!value) {
            return '';
        }
        const trimEmojis = /(?:[\u2700-\u27bf]|(?:\ud83c[\udde6-\uddff]){2}|[\ud800-\udbff][\udc00-\udfff]|[\u0023-\u0039]\ufe0f?\u20e3|\u3299|\u3297|\u303d|\u3030|\u24c2|\ud83c[\udd70-\udd71]|\ud83c[\udd7e-\udd7f]|\ud83c\udd8e|\ud83c[\udd91-\udd9a]|\ud83c[\udde6-\uddff]|[\ud83c\ude01-\ude02]|\ud83c\ude1a|\ud83c\ude2f|[\ud83c\ude32-\ude3a]|[\ud83c\ude50-\ude51]|\u203c|\u2049|[\u25aa-\u25ab]|\u25b6|\u25c0|[\u25fb-\u25fe]|\u00a9|\u00ae|\u2122|\u2139|\ud83c\udc04|[\u2600-\u26FF]|\u2b05|\u2b06|\u2b07|\u2b1b|\u2b1c|\u2b50|\u2b55|\u231a|\u231b|\u2328|\u23cf|[\u23e9-\u23f3]|[\u23f8-\u23fa]|\ud83c\udccf|\u2934|\u2935|\u0000|[\u2190-\u21ff])/g;
        return value.replace(trimEmojis, '');
    }
};
