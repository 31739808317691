import mapper from '../mapper/index.js';
function updateSellCity(state, { data, glb }) {
    state.geo = mapper.geo(data.geo, glb.rentals);
    state.pageImage = data.pageImage;
    state.snapshot = data.snapshot;
    if (data.neighborhoods && data.neighborhoods.length > 0) {
        state.neighborhoods = data.neighborhoods;
    }
    if (data.zipcodes && data.zipcodes.length > 0) {
        state.zipcodes = data.zipcodes;
    }
    if (data.cities && data.cities.length > 0) {
        state.cities = data.cities;
    }
    if (data.counties && data.counties.length > 0) {
        state.counties = data.counties;
    }
}

export default {
    updateSellCity,
};
