
const isSSR = typeof window === 'undefined';

export default (app) => {
    const context = !isSSR && window.__INITIAL_STATE__;
    const baseTrack = !isSSR &&  window.baseTrack;
    const jwt = context.signalJWT;
    const movotoUserId = baseTrack.user_id;
    const ojoId = baseTrack.user_ojoId;
    const deviceId = baseTrack.id;
    const ENABLEDPAGE = !isSSR &&  window.__INITIAL_STATE__.enableThirdPart;
    const ENABLED = !isSSR &&  window.__INITIAL_STATE__.enableThirdPart;
    const URL = !isSSR &&  window.__INITIAL_STATE__.signalAPIUrl;
    if (isSSR || !ENABLED || !jwt){
        app.directive('signal', {
            unmounted(){},
            beforeMount() {}
        });
        return
    }

    const generateOpts = (movotoUserId, deviceId, ojoId) => {
        let subject = `movoto_anonymoususer:${deviceId}`;
        if (!!ojoId) {
            subject = `ojo_profileid:${ojoId}`
        } else if (!!movotoUserId) {
            subject = `movoto_userid:${movotoUserId}`
        }
        return {
            'xSubject': subject
        };
    }
    const generateUserIdentifier = (movotoUserId, deviceId) => {
        if (!! ojoId) {
            return {
                ojoProfileId: ojoId
            }
        } else if (!!movotoUserId) {
            return {
                movotoUser: movotoUserId
            };
        } else if (deviceId) {
            return {
                movotoAnonymousUser: deviceId
            };
        } else return null;
    }

    const opts = generateOpts(movotoUserId, deviceId, ojoId);
    let signalAPIClient, defaultAPI, eventAPI;
    const loadedSignalAPIClient = function(callback) {
        if (eventAPI) {
            return callback();
        }
        import(/* webpackChunkName: "signal-api" */'@ojolabs/signal-api-client').then((client) => {
            signalAPIClient = client;
            const defaultClient = new signalAPIClient.ApiClient(URL);
            defaultClient.authentications['JWTAuth'].accessToken = jwt;

            defaultAPI = new signalAPIClient.DefaultApi(defaultClient);
            eventAPI = new signalAPIClient.EventApi(defaultClient);
            callback();
        });
    };

    const getMetadata = function(key){
        let metadata = {
            source: 'movoto-website',
            path: baseTrack.path,
            project: 'consumer/consumer-app-vue',
            query: baseTrack.search,
            userAgent: window.navigator ? window.navigator.userAgent : '',
            version: baseTrack.version,
            deviceId: baseTrack.id,
            movotoUser: baseTrack.user_id,
        };
        if(key === 'movoto-app-opened' || key === 'movoto-app-logged-in'){
            metadata.movotoAppType = 'web';
            metadata.sessionID = baseTrack.session_id;
            metadata.origin = baseTrack.origin;
        }
        return metadata;
    }
    function sendError(e){
        if(e && e.error){
            window && window.sendNodeLog('signalApiError', window.location.href, 0, e.error);
        }
    }
    const send = (key, track) => {
        let eventData = getEventData(key, track);
        let metadata = getMetadata(key);
        let eventBehavior = getEventBehavior(key);
        try{
            loadedSignalAPIClient(() => {
                eventAPI
                    .createEventAsync(
                        signalAPIClient.CreateEventRequest.constructFromObject({
                            eventType: key,
                            eventBehavior: eventBehavior,
                            userIdentifier: {
                                ...generateUserIdentifier(movotoUserId, deviceId),
                            },
                            eventData: eventData,
                            eventMetadata: metadata,
                        })
                    )
                    .catch((e) => {
                        sendError(e);
                    });
            });
        } catch(e){
            sendError(e);
        }
    }
    const statusMap = {
        ACTIVE: 'ACTIVE',
        PENDING: 'PENDING',
        SOLD: 'SOLD',
        OFF_MARKET: 'OFF_MARKET',
        FOR_RENT: 'FOR_RENT',
        REMOVED: 'REMOVED',
    };

    const typeMap = {
        SINGLE_FAMILY_HOUSE: 'SINGLE_FAMILY',
         MULTI_FAMILY: 'MULTI_FAMILY',
         LAND: 'LAND',
         MOBILE: 'MOBILE_HOME',
         CONDO: 'CONDO_TOWNHOUSE',
         OTHER: 'OTHER',
         COMMERCIA: 'COMMERCIAL',
    };

    const getEventBehavior = function(key){
        if(key === 'listing-details-viewed'){
            return {
                hot: false
            }
        }
    }

    const getEventData =function(key,track){
        var req = track.req;

        if(key === 'listing-details-viewed'){
            return {
                address:{
                    city: track.city,
                    country: 'USA',
                    stateOrProvince: track.state,
                    postalCode: track.zipCode,
                    street1: track.address,
                },
                listingId: track.dpp_id ,
                bathrooms: track.dpp_bath || 0,
                bedrooms: track.dpp_bed || 0,
                price: {
                    amount: track.dpp_price || 0,
                    currency: 'USD',
                },
                propertyType: typeMap[track.dpp_type],
                propertyStatus: statusMap[track.dpp_status],
                propertyLotSize: parseInt(track.dpp_lotSize) || 0,
                propertySqftTotal: parseInt(track.dpp_sqftTotal) || 0,
            }
        }
        if(key === 'listing-favorited'){
            return {
                listingId: req.data.propertyId,
            }
        }
        if(key === 'listing-shared'){
            return {
                listingId: req.data.propertyId,
                shareType: 'email'
            }
        }
        return null;
    }

    const mapping = {
        'request':{
            'addfavorite':'listing-favorited',
            'shareToFriend':'listing-shared',
        },
        'login':{
            '*': 'movoto-app-logged-in'
        },
        'landing':{
            '*': 'movoto-app-opened'
        },
        'load-page':{
            'vdpp': 'listing-details-viewed'
        }
        // home-claimed  listing-favorited listing-shared lead-captured search-saved
    }
    function sendTrack(e, track){
        if(!ENABLEDPAGE && track.action === 'load-page'){
            return;
        }
        if(mapping[track.action]){
            var key;
            if(mapping[track.action]['*']){
                key = mapping[track.action]['*'];
            }else{
                key = mapping[track.action][track.category];
            }
            if(key){
                send(key, track);
            }
        }
    }

    app.directive('signal', {
        unmounted(el, binding){
            $(document).off('dom.track');
        },
        beforeMount(el, binding) {
            $(document).on('dom.track', sendTrack);
        }
    });
};
