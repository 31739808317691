import getGeo from '../glb/geo';

export default function () {
    return {
        geo: getGeo(),
        stateList: [],
        popularCityList: [],
        homeImgWebp: '',
        homeImgWebpSM: '',
        homeImgWebpMD: '',
        homeImgWebpLG: '',
        homeImgWebpXL: '',
        homeImgSrcset: '',
        seoNewListings: null,
        seoReducedListings: null,
        seoOpenListings: null,
    };
}
