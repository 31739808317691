export function getPurePath(path) {
    let purePath;
    //remove query string
    purePath = path.split('?')[0];
    //remove useless secment
    purePath = purePath.replace(/^\/?homes-for-sale/i, '');
    //remove /en /es /zh or / at begin
    purePath = purePath.replace(/^\/?(en|es|zh)?\//i, '');
    //remove / at end
    purePath = purePath.replace(/\/$/, '').toLowerCase();
    return purePath;
}
export function getSegments(path) {
    let purePath = getPurePath(path);
    let segments = purePath.split('/');
    return segments;
}
export function getPath(text) {
    return text
        .replace(/[^a-zA-Z0-9]+/gi, '-')
        .replace(/-*\/$/, '/')
        .replace(/-$/, '');
}
export function absolutePath(path) {
    if (path[0] !== '/') {
        return '/' + path;
    }
    return path;
}
